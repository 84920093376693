import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
    createFormData: {},
    loading:false
    
}

export const DetailSlice = createSlice({
    name: 'rfqdetails',
    initialState: initialState,
    reducers: { 
        setFormData: (state, payload)=>{
            state.createFormData = payload.payload;
            state.loading =true
        },
        setProfile: (state, payload)=>{
            state.createFormData = payload.payload;
            state.loading =true
        },
     },
    extraReducers: (builder) => {
       
    },
})
export const { setFormData,setProfile  } = DetailSlice.actions

export default DetailSlice.reducer