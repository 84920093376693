import React from 'react'
import InnerDashboardPage from '../../pages/DashboardPages/InnerDashboardPage'

function InnerDashboard() {
  return (
    <div>
        <InnerDashboardPage />
    </div>
  )
}

export default InnerDashboard