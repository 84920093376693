import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from 'yup';
import { setFormData, setProfile } from '../../Redux/slice/detail.slice'
import { setIsloggedIn ,setIsloggedOut,setUserType} from '../../Redux/slice/auth.slice'
import { useDispatch } from 'react-redux';
import { useAppDispatch } from "../../Redux/store";
import { setGlobalState } from '../../globalState';
import { LoginUser, registerUser, creatOrgProfile,creatAgencyProfile ,creatResourcesProfile, addMasterAgreement, LogOutUser,} from "../../services/apiUrl";


const schema = Yup.object().shape({
    firstName: Yup.string()
        .min(4, 'Too Short!')
        .max(20, 'Too Long!')
        .required('Required'),
    lastName: Yup.string()
        .min(4, 'Too Short!')
        .max(20, 'Too Long!')
        .required('Required'),
    email: Yup.string()
        .email('Invalid email address')
        .required('Required'),
    password1: Yup.string()
        .min(8, 'Password should be of minimum 8 characters length')
        .required('Password is required'),
    password2: Yup.string()
        .oneOf([Yup.ref('password1'), null], 'Passwords must match')

});

const schemaAddMasterAgreement = Yup.object().shape({
    msaType: Yup.string()        
    .required('Required'), 
    msaNumber: Yup.string()        
    .required('Required'),
    name: Yup.string()        
    .required('Required'),     
    unpseCode: Yup.string()        
    .required('Required'),
    businessUnit: Yup.string()        
    .required('Required'),
    glAccount: Yup.string()        
    .required('Required'),
    budget: Yup.string()        
    .required('Required'),
    supplier: Yup.string()        
    .required('Required'),
    startDate: Yup.string()        
    .required('Required'),
    endDate: Yup.string()        
    .required('Required'),
    deliver: Yup.string()        
    .required('Required'),
    taxService: Yup.string()        
    .required('Required'),
    taxGrp: Yup.string()        
    .required('Required'),
    savingPercentage: Yup.string()        
    .required('Required'),
    comments: Yup.string()        
    .required('Required')         
  });
/**************************************************************************** **********************
 *                                useLoginUserHooks
*************************************************************************************************/
export const useLoginUserHooks = () => {
    let navigate = useNavigate();
    const dispatch = useAppDispatch();
    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        onSubmit: async (values) => {
            let payload = {
                email: values.email,
                password: values.password,
            }
            const result = await LoginUser(payload);
            if (result) {
                console.log('result onSubmit login', result)
                dispatch(setIsloggedIn(result))
                dispatch(setUserType(result))
                alert('Login Successfull ')
                navigate(`/innerdashboard`)
            }
        },
    });
    return { formik }
}
/**************************************************************************** **********************
 *                                useLogOutUserHooks
*************************************************************************************************/
export const useLogOutUserHooks = () => {
    let navigate = useNavigate();
    const dispatch = useAppDispatch();
    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        onSubmit: async (values) => {
            const result = await LogOutUser();
            if (result) {
                console.log('result onSubmit', result)
                dispatch(setIsloggedOut(result))
                alert('Log Out Successfull ')
                navigate(`/`)
            }
        },
    });
    return { formik }
}

/**************************************************************************** **********************
 *                                useRegisterAsAnOrgHooks
*************************************************************************************************/

export const useRegisterAsAnOrgHooks = () => {
    let navigate = useNavigate();
    const dispatch = useAppDispatch();
    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            password1: '',
            password2: '',
            user_type: 'ORGANISATION_USER'
        },
        validationSchema: schema,

        onSubmit: async (values) => {
            let payload = {
                first_name: values.firstName,
                last_name: values.lastName,
                email: values.email,
                password1: values.password1,
                password2: values.password2,
                user_type: 'ORGANISATION_USER'

            }
            console.log('onsubmit values', payload);
            const result = await registerUser(payload);
            if (result) {
                console.log('result onSubmit', result)
                dispatch(setFormData(payload))
                dispatch(setUserType(result))
                navigate(`/verifyOrg`)
            }
        },
    });
    return { formik }
}

/**************************************************************************** **********************
 *                                useRegisterAsAnAgncyPageHooks
*************************************************************************************************/

export const useRegisterAsAnAgncyPageHooks = () => {
    let navigate = useNavigate();
    const dispatch = useAppDispatch();
    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            password1: '',
            password2: '',
            user_type: 'AGENCY_USER'
        },
        validationSchema: schema,

        onSubmit: async (values) => {
            let payload = {
                first_name: values.firstName,
                last_name: values.lastName,
                email: values.email,
                password1: values.password1,
                password2: values.password2,
                user_type: 'AGENCY_USER'
            }
            console.log('onsubmit values', payload);
            const result = await registerUser(payload);
            if (result) {
                console.log('result onSubmit', result)
                dispatch(setFormData(payload))
                dispatch(setUserType(result))
                navigate(`/verifyAgn`)
            }
        },
    });
    return { formik }
}
/**************************************************************************** **********************
 *                                useRegisterResourcePageHooks
*************************************************************************************************/
export const useRegisterResourcePageHooks = () => {
    let navigate = useNavigate();
    const dispatch = useAppDispatch();
    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            password1: '',
            password2: '',
            user_type: 'RESOURCE_USER'
        },
        validationSchema: schema,

        onSubmit: async (values) => {
            let payload = {
                first_name: values.firstName,
                last_name: values.lastName,
                email: values.email,
                password1: values.password1,
                password2: values.password2,
                user_type: 'RESOURCE_USER'
            }
            console.log('onsubmit values', payload);
            const result = await registerUser(payload);
            if (result) {
                console.log('result onSubmit', result)
                dispatch(setFormData(payload))
                dispatch(setUserType(result))
                navigate(`/verifyresource`)
            }
        },
    });
    return { formik }
}
/**************************************************************************** **********************
 *                                useOrgProfileHooks
*************************************************************************************************/
export const useOrgProfileHooks = () => {
    const dispatch = useAppDispatch();
    const formik = useFormik({
        initialValues: {
            logo: '',
            business_name: '',
            tax_number: '',
            contact_number: '',
            address: '',
            country: '',
            district: '',
            state: '',
            country_id: '',
            state_id: '',
            pin_code: '',
            currency: '',
            disable: true,
        },
        // validationSchema: schema,

        onSubmit: async (values) => {

            const myForm = new FormData();
            myForm.append("logo", values.logo)
            myForm.set("business_name", values.business_name);
            myForm.set("contact_number", values.contact_number);
            myForm.set("tax_number", values.tax_number);
            myForm.set("address", values.address);
            myForm.set("country", values.country_id);
            myForm.set("state", values.state_id);
            myForm.set("district", values.district);
            myForm.set("pin_code", values.pin_code);
            myForm.set("currency", values.currency);

            const result = await creatOrgProfile(myForm);
            if (result) {
                formik.setFieldValue("disable", false)
                dispatch(setProfile(myForm))
                console.log('result onSubmit', result.data.data.user[0])

                // dispatch(setFormData(payload))
                // navigate(`/verifyresource`)
            }
        },
    });
    return { formik }
}
/**************************************************************************** **********************
 *                                useAgencyProfileHooks
*************************************************************************************************/
export const useAgencyProfileHooks = () => {
    const dispatch = useAppDispatch();
    const formik = useFormik({
        initialValues: {
            logo: '',
            business_name: '',
            tax_number: '',
            contact_number: '',
            address: '',
            country: '',
            district: '',
            country_id: '',
            state_id: '',
            state: '',
            pin_code: '',
            currency: '',
            disable: true,
        },
        // validationSchema: schema,

        onSubmit: async (values) => {
            const myForm = new FormData();
            myForm.append("logo", values.logo)
            myForm.set("business_name", values.business_name);
            myForm.set("contact_number", values.contact_number);
            myForm.set("tax_number", values.tax_number);
            myForm.set("address", values.address);
            myForm.set("country", values.country_id);
            myForm.set("state", values.state_id);
            myForm.set("district", values.district);
            myForm.set("pin_code", values.pin_code);
            myForm.set("currency", values.currency);

            const result = await creatAgencyProfile(myForm);
            if (result) {
                formik.setFieldValue("disable", false)
                dispatch(setProfile(myForm))
                console.log('result onSubmit', result.data.data.user[0])

                // dispatch(setFormData(payload))
                // navigate(`/verifyresource`)
            }
        },
    });
    return { formik }
}

/**************************************************************************** **********************
 *                                useResourceProfileHooks
*************************************************************************************************/
export const useResourceProfileHooks = () => {
    const dispatch = useAppDispatch();
    const formik = useFormik({
        initialValues: {
            profile_pic: '',
            resources_type: '',
            insurances_type: '',
            abn_number: '',
            company_name: '',
            tfn_number: '',
            pli: '',
            pii: '',
            wci: '',
            skill_set: '',
            address: '',
            country: '',
            district: '',
            country_id: '',
            state_id: '',
            state: '',
            currency: '',
            pin_code: '',
            contact_number: '',
            need_assistance: '',
            disable: true,
        },
        // validationSchema: schema,
        onSubmit: async (values) => {
            const myForm = new FormData();
            if (values.resources_type === "ABN") {

                myForm.append("profile_pic", values.profile_pic)
                myForm.set("resources_type", values.resources_type);
                myForm.set("insurances_type", values.insurances_type);
                myForm.set("abn_number", values.abn_number);
                myForm.set("company_name", values.company_name);
                myForm.set("skill_set", values.skill_set);
                myForm.set("address", values.address);
                myForm.set("country", values.country_id);
                myForm.set("district", values.district);
                myForm.set("state", values.state_id);
                myForm.set("currency", values.currency);
                myForm.set("pin_code", values.pin_code);
                myForm.set("contact_number", values.contact_number);
                myForm.set("need_assistance", values.need_assistance);

            }
            if (values.resources_type === "TFN") {

                myForm.append("profile_pic", values.profile_pic)
                myForm.set("resources_type", values.resources_type);
                myForm.set("tfn_number", values.tfn_number);
                myForm.set("skill_set", values.skill_set);
                myForm.set("address", values.address);
                myForm.set("country", values.country_id);
                myForm.set("district", values.district);
                myForm.set("state", values.state_id);
                myForm.set("currency", values.currency);
                myForm.set("pin_code", values.pin_code);
                myForm.set("contact_number", values.contact_number);
                myForm.set("need_assistance", values.need_assistance);

            }






            const result = await creatResourcesProfile(myForm);
            if (result) {
                formik.setFieldValue("disable", false)
                dispatch(setProfile(myForm))
                console.log('result onSubmit', result)
                // dispatch(setFormData(payload))
                // navigate(`/verifyresource`)
            }
        },
    });
    return { formik }
}


/**************************************************************************** **********************
 *                                addMasterAgreementHooks
*************************************************************************************************/
export const useAddMasterAgreementHooks = () => {
    const dispatch = useAppDispatch();
    const formik = useFormik({
        initialValues: {
            msaType:"",  
            msaNumber:"",
            name:"",
            unpseCode:"",
            businessUnit:"",
            glAccount:"",
            budget:"",
            supplier:"",
            startDate:"",
            endDate:"",
            deliver:"",
            msaResources:"",
            msaAgency: "",
            taxService: "",
            taxGrp:"",
            savingPercentage: "",
            comments:"",            
        },   
        validationSchema: schemaAddMasterAgreement,
        onSubmit: async (values) => {                      
            let payload = {
                msa_type:values.msaType,  
                msa_number:values.msaNumber,
                name:values.name,
                unpsc_code:values.unpseCode,
                business_unit:values.businessUnit,
                gl_account:values.glAccount,
                budget:values.budget,
                resource: null,
                agency: values.supplier,                
                start_date:values.startDate,
                end_date:values.endDate,
                deliverables:values.deliver,
                tax_service_type: values.taxService,
                tax_group:values.taxGrp,
                savings_percentage: values.savingPercentage,
                comments:values.comments
            }
            const result = await addMasterAgreement(payload);
            console.log("clicked");
            if (result) {
                console.log("clicked result");
                formik.setFieldValue("disable",false)
                setGlobalState("showAddNewAgree",false);
                dispatch(setFormData(payload))
                console.log('result onSubmit', result.data.data.user[0])                           
            }
        },
    });
    return { formik }
}
