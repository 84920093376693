import React from "react";
//import 'bootstrap/dist/css/bootstrap.min.css';
//import "../css/css_dashboard.css";
//import "../css/responsive_dash.css";
import Sidebar from './sidebar/Sidebar';

const Layout=()=>{
    return (
        <React.Fragment>
          <Sidebar/>          
        </React.Fragment>

        
    );
}

export default Layout;