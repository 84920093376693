import React from 'react'
import SearchResourcesModalPage from '../../pages/ModalPages/SearchResourcesModalPage'

function SearchResourcesModal(props) {
  return (
    <div>
        <SearchResourcesModalPage show={props.show}/>
    </div>
  )
}

export default SearchResourcesModal