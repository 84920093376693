import React from 'react';
import Group164 from "../../images/Group 164.png";
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';

function SignUpPage() {

	const navigate = useNavigate();

	const loginHandler = () => {
		navigate("/login")
	}

	return (
		<>
			<header class="floating_header">

				<nav class="navbar navbar-expand-lg navbar-light ">
					<div class="container-fluid">
						<Link to={`/`} class="navbar-brand sign_dash_logo">
								<img src={Group164} />
						</Link>
						<ul class="navbar-nav ms-auto mb-2 mb-lg-0">
							<li class="already_logedBox">
								<p>Already have an account? <Link to={`/login`}>Log in</Link></p>
							</li>
						</ul>

					</div>
				</nav>

			</header>

			<section class="signup_tabBox_sec">
				<div class="container-fluid">
					<div class="row">
						<div class="dash_bannerSec">
							<div class="dash_ban_bg">
								<div class="dash_sign_left_head">
									<h1>Meet the new standard in Global Payroll</h1>
								</div>
							</div>

						</div>
						<div class="signup_agreement_frmBox">

							<div class="sign_middleWare">

								<div class="frm_welcome_head">
									<h1>Welcome to Agreement Paper</h1>
									<p>Create an account to start building your global team!</p>
								</div>

								<div class="fill_up_taskBox" >
									<div class="tell_usBox">
										<h4>Tell us what describes you best</h4>
									</div>
									<ul class="nav nav-tabs" >

										<li class="nav-item" >
											<button class="nav-link active" id="orgnise-tab" data-bs-toggle="tab" data-bs-target="#orgnise-tab-pane" type="button" role="tab" aria-controls="orgnise-tab-pane" aria-selected="true"><span class="checkd_btn"></span> <span class="check_opt"> I’m an Organisation Looking for Agency or Resource</span></button>
										</li>
										<li class="nav-item" role="presentation">
											<button class="nav-link" id="agency-tab" data-bs-toggle="tab" data-bs-target="#agency-tab-pane" type="button" role="tab" aria-controls="agency-tab-pane" aria-selected="false"><span class="checkd_btn"></span> <span class="check_opt"> I’m an Agency Looking for Organisation Tie ups </span></button>
										</li>
										<li class="nav-item" role="presentation">
											<button class="nav-link" id="resource-tab" data-bs-toggle="tab" data-bs-target="#resource-tab-pane" type="button" role="tab" aria-controls="resource-tab-pane" aria-selected="false"><span class="checkd_btn"></span> <span class="check_opt">I’m an Resource Looking for Good work Opportunity</span></button>
										</li>

									</ul>

								</div>

								<div>
									<div class="tab-content" id="myTabContent">

										<div class="tab-pane  show active" id="orgnise-tab-pane" role="tabpanel" aria-labelledby="orgnise-tab" tabIndex="0">

											{/* <a href="">Register as Organisation</a> */}
											<Link to={`organisation`}>Register as Organisation</Link>

										</div>
										<div class="tab-pane " id="agency-tab-pane" role="tabpanel" aria-labelledby="agency-tab" tabIndex="0">

											{/* <a href="agn_signup.html">Register as Agency</a> */}
											<Link to={`agency`}>Register as Agency</Link>

										</div>
										<div class="tab-pane " id="resource-tab-pane" role="tabpanel" aria-labelledby="resource-tab" tabIndex="0">

											{/* <a href="res_signup.html">Register as Resource</a> */}
											<Link to={`resource`}>Register as Resource</Link>

										</div>

									</div>
								</div>

								<div class="already_logedBox _onMob">
									<p>Already have an account? <a href="" onClick={loginHandler}>Log in</a></p>
								</div>

							</div>

						</div>
					</div>
				</div>
			</section>

		</>
	)
}

export default SignUpPage;