import React from 'react'
import RegisterResourcePage from '../../pages/LoginPages/resourcePage'

function RegisterAsAnResource() {
  return (
    <>
    <RegisterResourcePage />
    </>
  )
}

export default RegisterAsAnResource