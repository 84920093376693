import React, { useState } from 'react'
import Layout from '../../layout';
import "../../css/css_dashboard.css";
import "../../css/responsive_dash.css";
import Header from '../../layout/header/Header';
import OrgModal from '../../component/Modal/OrgModal';
import AgencyModal from '../../component/Modal/AgencyModal';
import ResourceModal from '../../component/Modal/ResourceModal';
import { useAppSelector } from '../../Redux/store';
import { useEffect } from 'react';
import AddMasterAgreementModal from '../../component/Modal/AddMasterAgreementModal';
import { useGlobalState,setGlobalState } from '../../globalState';

function InnerDashboardPage() {
    const storeData = useAppSelector(state =>  state.user.userType.user.user_type )
    
    const [usertype, setUsertype] = useState('')
    useEffect(() => {
        setUsertype(storeData)
        console.log('usertype',storeData)
    }, [storeData])

    const [useGlobal] = useGlobalState("showAddNewAgree");

    const addNewAgreement =(e)=>{
         e.preventDefault();                
         setGlobalState("showAddNewAgree",true);
         setGlobalState("opacity",0);  
         
    }

    return (
        <div id="flexNav">

            <Layout />
            <section className="inner_dash_sideSec">
                <Header />
                <div className="tab-content" id="myTabContent">
                    {
                        (usertype === "ORGANISATION_USER" && <OrgModal />) || (usertype === "AGENCY_USER" && <AgencyModal />) || (usertype === "RESOURCE_USER" && <ResourceModal />)
                    }
                    
                    <div className="tab-pane tab_pane1 fade show active " id="dashboard" role="tabpanel" aria-labelledby="dashboard-tab">
                    {/* <DashBoard /> */}
                        <div className="col-md-12">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="wel_comHead">
                                            <h1>Welcome Nishant Swastik</h1>
                                        </div>
                                    </div>

                                    <div className="col-sm-6 col-md-6 col-lg-4">

                                        <div className="msa_flow_Box">

                                            <div className="msa_flow_Box_innerFirst">

                                                <div className="msa_flow_fImg_box">
                                                    <img src={require('../../images/Rating.png')} alt='img'/>
                                                </div>

                                                <div className="msa_flow_fImg_head">
                                                    <h4>Master Service Agreement</h4>
                                                </div>

                                            </div>

                                            <div className="msa_flow_Box_divider"></div>

                                            <div className="msa_flow_Box_innerSecond">

                                                <ul className="nav nav-tabs" id="my_counterTab" role="tablist">
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link  active" id="in_progress-tab" data-bs-toggle="tab" data-bs-target="#in_progress" type="button" role="tab" aria-controls="in_progress" aria-selected="true">
                                                            <div className="count_nums">100</div><div className="conut_para">In Progress</div>
                                                        </button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link" id="upcoming-tab" data-bs-toggle="tab" data-bs-target="#upcoming" type="button" role="tab" aria-controls="upcoming" aria-selected="false">
                                                            <div className="count_nums">32</div><div className="conut_para">Upcoming</div>
                                                        </button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link" id="completed-tab" data-bs-toggle="tab" data-bs-target="#completed" type="button" role="tab" aria-controls="completed" aria-selected="false">
                                                            <div className="count_nums">300</div><div className="conut_para">Completed</div>
                                                        </button>
                                                    </li>
                                                </ul>
                                                {/*<div className="tab-content" id="myTabContent">
                                                                            <div className="tab-pane fade show active" id="in_progress" role="tabpanel" aria-labelledby="in_progress-tab">...</div>
                                                                            <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">...</div>
                                                                            <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">...</div>
                    </div>*/}

                                            </div>

                                        </div>

                                    </div>

                                    <div className="col-sm-6 col-md-6 col-lg-4">

                                        <div className="msa_flow_Box">

                                            <div className="msa_flow_Box_innerFirst">

                                                <div className="msa_flow_fImg_box">
                                                    <img src={require('../../images/Group 150.png')} alt='img'/>
                                                </div>

                                                <div className="msa_flow_fImg_head">
                                                    <h4>Statement of Works</h4>
                                                </div>

                                            </div>

                                            <div className="msa_flow_Box_divider"></div>

                                            <div className="msa_flow_Box_innerSecond">

                                                <ul className="nav nav-tabs" id="my_counterTab" role="tablist">
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link  active" id="in_progress-tab" data-bs-toggle="tab" data-bs-target="#in_progress" type="button" role="tab" aria-controls="in_progress" aria-selected="true">
                                                            <div className="count_nums">100</div><div className="conut_para">In Progress</div>
                                                        </button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link" id="upcoming-tab" data-bs-toggle="tab" data-bs-target="#upcoming" type="button" role="tab" aria-controls="upcoming" aria-selected="false">
                                                            <div className="count_nums">32</div><div className="conut_para">Upcoming</div>
                                                        </button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link" id="completed-tab" data-bs-toggle="tab" data-bs-target="#completed" type="button" role="tab" aria-controls="completed" aria-selected="false">
                                                            <div className="count_nums">300</div><div className="conut_para">Completed</div>
                                                        </button>
                                                    </li>
                                                </ul>
                                                {/*<div className="tab-content" id="myTabContent">
                                                                            <div className="tab-pane fade show active" id="in_progress" role="tabpanel" aria-labelledby="in_progress-tab">...</div>
                                                                            <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">...</div>
                                                                            <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">...</div>
                </div>*/}

                                            </div>

                                        </div>

                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-4">

                                        <div className="msa_flow_Box">

                                            <div className="msa_flow_Box_innerFirst">

                                                <div className="msa_flow_fImg_box">
                                                    <img src={require('../../images/Group 156.png')} alt='img'/>
                                                </div>

                                                <div className="msa_flow_fImg_head">
                                                    <h4>Timesheet</h4>
                                                </div>

                                            </div>

                                            <div className="msa_flow_Box_divider"></div>

                                            <div className="msa_flow_Box_innerSecond">

                                                <ul className="nav nav-tabs" id="my_counterTab" role="tablist">
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link  active" id="in_progress-tab" data-bs-toggle="tab" data-bs-target="#in_progress" type="button" role="tab" aria-controls="in_progress" aria-selected="true">
                                                            <div className="count_nums">100</div><div className="conut_para">In Progress</div>
                                                        </button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link" id="upcoming-tab" data-bs-toggle="tab" data-bs-target="#upcoming" type="button" role="tab" aria-controls="upcoming" aria-selected="false">
                                                            <div className="count_nums">32</div><div className="conut_para">Upcoming</div>
                                                        </button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link" id="completed-tab" data-bs-toggle="tab" data-bs-target="#completed" type="button" role="tab" aria-controls="completed" aria-selected="false">
                                                            <div className="count_nums">300</div><div className="conut_para">Completed</div>
                                                        </button>
                                                    </li>
                                                </ul>
                                                {/*<div className="tab-content" id="myTabContent">
                                                                            <div className="tab-pane fade show active" id="in_progress" role="tabpanel" aria-labelledby="in_progress-tab">...</div>
                                                                            <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">...</div>
                                                                            <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">...</div>
                                                                        </div> */}

                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="tab-pane tab_pane2 fade" id="master" role="tabpanel" aria-labelledby="master-tab">
                        {/* <MAS /> */}
                        <div className="sow_Box">
                            <div className="sow_head">
                                <h2>Master Agreements</h2>
                                <a className="modal_openBtn" onClick={addNewAgreement}>Add New<i className="fa-solid fa-plus"></i></a>
                            </div>

                            <div className="">

                                <ul className="nav nav-tabs" id="my_sowTab" role="tablist">

                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link active" id="all-tab" data-bs-toggle="tab" data-bs-target="#all" type="button" role="tab" aria-controls="all" aria-selected="true">All MSA</button>
                                    </li>

                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="progress_sow-tab" data-bs-toggle="tab" data-bs-target="#progress_sow" type="button" role="tab" aria-controls="progress_sow" aria-selected="false">In Progress</button>
                                    </li>

                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="rejected-tab" data-bs-toggle="tab" data-bs-target="#rejected" type="button" role="tab" aria-controls="rejected" aria-selected="false">Rejected</button>
                                    </li>

                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="pending_approval-tab" data-bs-toggle="tab" data-bs-target="#pending_approval" type="button" role="tab" aria-controls="pending_approval" aria-selected="false">Pending Approval</button>
                                    </li>

                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="completed_sow-tab" data-bs-toggle="tab" data-bs-target="#completed_sow" type="button" role="tab" aria-controls="completed_sow" aria-selected="false">Completed</button>
                                    </li>

                                </ul>
                                <div className="tab-content" id="myTabContent">

                                    <div className="tab-pane fade show active" id="all" role="tabpanel" aria-labelledby="all-tab">

                                        <div className="main_sow_wrapper">

                                            <div className="container-fluid row_marg">

                                                <div className="row">

                                                    <div className="col-md-2">
                                                        <input className="form-control" id="datepicker" placeholder="Created Date Range" />
                                                    </div>

                                                    <div className="col-md-2">
                                                        <select className="form-control">
                                                            <option selected disabled value="">Status</option>
                                                            <option value="">Status 1</option>
                                                            <option value="">Status 2</option>
                                                            <option value="">Status 3</option>
                                                        </select>
                                                    </div>

                                                    <div className="col-md-2">
                                                        <input className="form-control" placeholder="Search" />
                                                    </div>

                                                </div>

                                            </div>

                                            <div className="container-fluid mob_flow">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <table className="table table-striped ">
                                                            <thead>
                                                                <tr className="_first_tr">
                                                                    <th scope="col">Number</th>
                                                                    <th scope="col">Branch Name</th>
                                                                    <th scope="col">Agency/ Resources</th>
                                                                    <th scope="col">SOW TITLE</th>
                                                                    <th scope="col">SOWS</th>
                                                                    <th scope="col">Created on</th>
                                                                    <th scope="col">Start Date</th>
                                                                    <th scope="col">End Date</th>
                                                                    <th scope="col">Budget</th>
                                                                    <th scope="col">Status</th>
                                                                    <th scope="col">Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="sow_tb_tbody">
                                                                <tr>
                                                                    <td scope="row">MP65346</td>
                                                                    <td >Dummy Description</td>
                                                                    <td >Volkswagen Account</td>
                                                                    <td >Master Service agreement</td>
                                                                    <td >Agreement</td>
                                                                    <td >Mar 6, 2021 </td>
                                                                    <td >Jul 20, 2020</td>
                                                                    <td >Jul 21, 2021</td>
                                                                    <td >$ 100000.00</td>
                                                                    <td >Approved</td>
                                                                    <td ><img src={require('../../images/drag_indicator.png')} /></td>

                                                                </tr>
                                                                <tr>
                                                                    <td scope="row">MP65346</td>
                                                                    <td >Dummy Description</td>
                                                                    <td >Volkswagen Account</td>
                                                                    <td >Master Service agreement</td>
                                                                    <td >Agreement</td>
                                                                    <td >Mar 6, 2021 </td>
                                                                    <td >Jul 20, 2020</td>
                                                                    <td >Jul 21, 2021</td>
                                                                    <td >$ 100000.00</td>
                                                                    <td >Approved</td>
                                                                    <td ><img src={require('../../images/drag_indicator.png')} /></td>

                                                                </tr>

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                    <div className="tab-pane fade" id="progress_sow" role="tabpanel" aria-labelledby="progress_sow-tab">
                                        <div className="main_sow_wrapper">

                                            <div className="container-fluid row_marg">

                                                <div className="row">

                                                    <div className="col-md-2">
                                                        <input className="form-control" id="datepicker" placeholder="Created Date Range" />
                                                    </div>

                                                    <div className="col-md-2">
                                                        <select className="form-control">
                                                            <option selected disabled value="">Status</option>
                                                            <option value="">Status 1</option>
                                                            <option value="">Status 2</option>
                                                            <option value="">Status 3</option>
                                                        </select>
                                                    </div>

                                                    <div className="col-md-2">
                                                        <input className="form-control" placeholder="Search" />
                                                    </div>

                                                </div>

                                            </div>

                                            <div className="container-fluid mob_flow">
                                                <table className="table table-striped ">
                                                    <thead>
                                                        <tr className="_first_tr">
                                                            <th scope="col">Number</th>
                                                            <th scope="col">Branch Name</th>
                                                            <th scope="col">Agency/ Resources</th>
                                                            <th scope="col">SOW TITLE</th>
                                                            <th scope="col">SOWS</th>
                                                            <th scope="col">Created on</th>
                                                            <th scope="col">Start Date</th>
                                                            <th scope="col">End Date</th>
                                                            <th scope="col">Budget</th>
                                                            <th scope="col">Status</th>
                                                            <th scope="col">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="sow_tb_tbody">
                                                        <tr>
                                                            <td scope="row">MP65346</td>
                                                            <td >Dummy Description</td>
                                                            <td >Volkswagen Account</td>
                                                            <td >Master Service agreement</td>
                                                            <td >Agreement</td>
                                                            <td >Mar 6, 2021 </td>
                                                            <td >Jul 20, 2020</td>
                                                            <td >Jul 21, 2021</td>
                                                            <td >$ 100000.00</td>
                                                            <td ><span><img src={require('../../images/check_circle.png')} /></span>Approved</td>
                                                            <td ><img src={require('../../images/drag_indicator.png')} /></td>

                                                        </tr>
                                                        <tr>
                                                            <td scope="row">MP65346</td>
                                                            <td >Dummy Description</td>
                                                            <td >Volkswagen Account</td>
                                                            <td >Master Service agreement</td>
                                                            <td >Agreement</td>
                                                            <td >Mar 6, 2021 </td>
                                                            <td >Jul 20, 2020</td>
                                                            <td >Jul 21, 2021</td>
                                                            <td >$ 100000.00</td>
                                                            <td ><span><img src={require('../../images/check_circle.png')} /></span>Approved</td>
                                                            <td ><img src={require('../../images/drag_indicator.png')} /></td>

                                                        </tr>

                                                        <tr>
                                                            <td scope="row">MP65346</td>
                                                            <td >Dummy Description</td>
                                                            <td >Volkswagen Account</td>
                                                            <td >Master Service agreement</td>
                                                            <td >Agreement</td>
                                                            <td >Mar 6, 2021 </td>
                                                            <td >Jul 20, 2020</td>
                                                            <td >Jul 21, 2021</td>
                                                            <td >$ 100000.00</td>
                                                            <td ><span><img src={require('../../images/check_circle.png')} /></span>Approved</td>
                                                            <td ><img src={require('../../images/drag_indicator.png')} /></td>

                                                        </tr>
                                                        <tr>
                                                            <td scope="row">MP65346</td>
                                                            <td >Dummy Description</td>
                                                            <td >Volkswagen Account</td>
                                                            <td >Master Service agreement</td>
                                                            <td >Agreement</td>
                                                            <td >Mar 6, 2021 </td>
                                                            <td >Jul 20, 2020</td>
                                                            <td >Jul 21, 2021</td>
                                                            <td >$ 100000.00</td>
                                                            <td ><span><img src={require('../../images/check_circle.png')} /></span>Approved</td>
                                                            <td ><img src={require('../../images/drag_indicator.png')} /></td>

                                                        </tr>
                                                        <tr>
                                                            <td scope="row">MP65346</td>
                                                            <td >Dummy Description</td>
                                                            <td >Volkswagen Account</td>
                                                            <td >Master Service agreement</td>
                                                            <td >Agreement</td>
                                                            <td >Mar 6, 2021 </td>
                                                            <td >Jul 20, 2020</td>
                                                            <td >Jul 21, 2021</td>
                                                            <td >$ 100000.00</td>
                                                            <td ><span><img src={require('../../images/check_circle.png')} /></span>Approved</td>
                                                            <td ><img src={require('../../images/drag_indicator.png')} /></td>

                                                        </tr>
                                                        <tr>
                                                            <td scope="row">MP65346</td>
                                                            <td >Dummy Description</td>
                                                            <td >Volkswagen Account</td>
                                                            <td >Master Service agreement</td>
                                                            <td >Agreement</td>
                                                            <td >Mar 6, 2021 </td>
                                                            <td >Jul 20, 2020</td>
                                                            <td >Jul 21, 2021</td>
                                                            <td >$ 100000.00</td>
                                                            <td ><span><img src={require('../../images/check_circle.png')} /></span>Approved</td>
                                                            <td ><img src={require('../../images/drag_indicator.png')} /></td>

                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="tab-pane fade" id="rejected" role="tabpanel" aria-labelledby="rejected-tab">
                                        <div className="main_sow_wrapper">

                                            <div className="container-fluid row_marg">

                                                <div className="row">

                                                    <div className="col-md-2">
                                                        <input className="form-control" id="datepicker" placeholder="Created Date Range" />
                                                    </div>

                                                    <div className="col-md-2">
                                                        <select className="form-control">
                                                            <option selected disabled value="">Status</option>
                                                            <option value="">Status 1</option>
                                                            <option value="">Status 2</option>
                                                            <option value="">Status 3</option>
                                                        </select>
                                                    </div>

                                                    <div className="col-md-2">
                                                        <input className="form-control" placeholder="Search" />
                                                    </div>

                                                </div>

                                            </div>

                                            <div className="container-fluid mob_flow">
                                                <table className="table table-striped ">
                                                    <thead>
                                                        <tr className="_first_tr">
                                                            <th scope="col">Number</th>
                                                            <th scope="col">Branch Name</th>
                                                            <th scope="col">Agency/ Resources</th>
                                                            <th scope="col">SOW TITLE</th>
                                                            <th scope="col">SOWS</th>
                                                            <th scope="col">Created on</th>
                                                            <th scope="col">Start Date</th>
                                                            <th scope="col">End Date</th>
                                                            <th scope="col">Budget</th>
                                                            <th scope="col">Status</th>
                                                            <th scope="col">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="sow_tb_tbody">
                                                        <tr>
                                                            <td scope="row">MP65346</td>
                                                            <td >Dummy Description</td>
                                                            <td >Volkswagen Account</td>
                                                            <td >Master Service agreement</td>
                                                            <td >Agreement</td>
                                                            <td >Mar 6, 2021 </td>
                                                            <td >Jul 20, 2020</td>
                                                            <td >Jul 21, 2021</td>
                                                            <td >$ 100000.00</td>
                                                            <td ><span><img src={require('../../images/check_circle.png')} /></span>Approved</td>
                                                            <td ><img src={require('../../images/drag_indicator.png')} /></td>

                                                        </tr>
                                                        <tr>
                                                            <td scope="row">MP65346</td>
                                                            <td >Dummy Description</td>
                                                            <td >Volkswagen Account</td>
                                                            <td >Master Service agreement</td>
                                                            <td >Agreement</td>
                                                            <td >Mar 6, 2021 </td>
                                                            <td >Jul 20, 2020</td>
                                                            <td >Jul 21, 2021</td>
                                                            <td >$ 100000.00</td>
                                                            <td ><span><img src={require('../../images/check_circle.png')} /></span>Approved</td>
                                                            <td ><img src={require('../../images/drag_indicator.png')} /></td>

                                                        </tr>

                                                        <tr>
                                                            <td scope="row">MP65346</td>
                                                            <td >Dummy Description</td>
                                                            <td >Volkswagen Account</td>
                                                            <td >Master Service agreement</td>
                                                            <td >Agreement</td>
                                                            <td >Mar 6, 2021 </td>
                                                            <td >Jul 20, 2020</td>
                                                            <td >Jul 21, 2021</td>
                                                            <td >$ 100000.00</td>
                                                            <td ><span><img src={require('../../images/check_circle.png')} /></span>Approved</td>
                                                            <td ><img src={require('../../images/drag_indicator.png')} /></td>

                                                        </tr>
                                                        <tr>
                                                            <td scope="row">MP65346</td>
                                                            <td >Dummy Description</td>
                                                            <td >Volkswagen Account</td>
                                                            <td >Master Service agreement</td>
                                                            <td >Agreement</td>
                                                            <td >Mar 6, 2021 </td>
                                                            <td >Jul 20, 2020</td>
                                                            <td >Jul 21, 2021</td>
                                                            <td >$ 100000.00</td>
                                                            <td ><span><img src={require('../../images/check_circle.png')} /></span>Approved</td>
                                                            <td ><img src={require('../../images/drag_indicator.png')} /></td>

                                                        </tr>


                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="tab-pane fade" id="pending_approval" role="tabpanel" aria-labelledby="pending_approval-tab">
                                        <div className="main_sow_wrapper">

                                            <div className="container-fluid row_marg">

                                                <div className="row">

                                                    <div className="col-md-2">
                                                        <input className="form-control" id="datepicker" placeholder="Created Date Range" />
                                                    </div>

                                                    <div className="col-md-2">
                                                        <select className="form-control">
                                                            <option selected disabled value="">Status</option>
                                                            <option value="">Status 1</option>
                                                            <option value="">Status 2</option>
                                                            <option value="">Status 3</option>
                                                        </select>
                                                    </div>

                                                    <div className="col-md-2">
                                                        <input className="form-control" placeholder="Search" />
                                                    </div>

                                                </div>

                                            </div>

                                            <div className="container-fluid mob_flow">
                                                <table className="table table-striped ">
                                                    <thead>
                                                        <tr className="_first_tr">
                                                            <th scope="col">Number</th>
                                                            <th scope="col">Branch Name</th>
                                                            <th scope="col">Agency/ Resources</th>
                                                            <th scope="col">SOW TITLE</th>
                                                            <th scope="col">SOWS</th>
                                                            <th scope="col">Created on</th>
                                                            <th scope="col">Start Date</th>
                                                            <th scope="col">End Date</th>
                                                            <th scope="col">Budget</th>
                                                            <th scope="col">Status</th>
                                                            <th scope="col">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="sow_tb_tbody">
                                                        <tr>
                                                            <td scope="row">MP65346</td>
                                                            <td >Dummy Description</td>
                                                            <td >Volkswagen Account</td>
                                                            <td >Master Service agreement</td>
                                                            <td >Agreement</td>
                                                            <td >Mar 6, 2021 </td>
                                                            <td >Jul 20, 2020</td>
                                                            <td >Jul 21, 2021</td>
                                                            <td >$ 100000.00</td>
                                                            <td ><span><img src={require('../../images/check_circle.png')} /></span>Approved</td>
                                                            <td ><img src={require('../../images/drag_indicator.png')} /></td>

                                                        </tr>
                                                        <tr>
                                                            <td scope="row">MP65346</td>
                                                            <td >Dummy Description</td>
                                                            <td >Volkswagen Account</td>
                                                            <td >Master Service agreement</td>
                                                            <td >Agreement</td>
                                                            <td >Mar 6, 2021 </td>
                                                            <td >Jul 20, 2020</td>
                                                            <td >Jul 21, 2021</td>
                                                            <td >$ 100000.00</td>
                                                            <td ><span><img src={require('../../images/check_circle.png')} /></span>Approved</td>
                                                            <td ><img src={require('../../images/drag_indicator.png')} /></td>

                                                        </tr>

                                                        <tr>
                                                            <td scope="row">MP65346</td>
                                                            <td >Dummy Description</td>
                                                            <td >Volkswagen Account</td>
                                                            <td >Master Service agreement</td>
                                                            <td >Agreement</td>
                                                            <td >Mar 6, 2021 </td>
                                                            <td >Jul 20, 2020</td>
                                                            <td >Jul 21, 2021</td>
                                                            <td >$ 100000.00</td>
                                                            <td ><span><img src={require('../../images/check_circle.png')} /></span>Approved</td>
                                                            <td ><img src={require('../../images/drag_indicator.png')} /></td>

                                                        </tr>
                                                        <tr>
                                                            <td scope="row">MP65346</td>
                                                            <td >Dummy Description</td>
                                                            <td >Volkswagen Account</td>
                                                            <td >Master Service agreement</td>
                                                            <td >Agreement</td>
                                                            <td >Mar 6, 2021 </td>
                                                            <td >Jul 20, 2020</td>
                                                            <td >Jul 21, 2021</td>
                                                            <td >$ 100000.00</td>
                                                            <td ><span><img src={require('../../images/check_circle.png')} /></span>Approved</td>
                                                            <td ><img src={require('../../images/drag_indicator.png')} /></td>

                                                        </tr>
                                                        <tr>
                                                            <td scope="row">MP65346</td>
                                                            <td >Dummy Description</td>
                                                            <td >Volkswagen Account</td>
                                                            <td >Master Service agreement</td>
                                                            <td >Agreement</td>
                                                            <td >Mar 6, 2021 </td>
                                                            <td >Jul 20, 2020</td>
                                                            <td >Jul 21, 2021</td>
                                                            <td >$ 100000.00</td>
                                                            <td ><span><img src={require('../../images/check_circle.png')} /></span>Approved</td>
                                                            <td ><img src={require('../../images/drag_indicator.png')} /></td>

                                                        </tr>


                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="tab-pane fade" id="completed_sow" role="tabpanel" aria-labelledby="completed_sow-tab">
                                        <div className="main_sow_wrapper">

                                            <div className="container-fluid row_marg">

                                                <div className="row">

                                                    <div className="col-md-2">
                                                        <input className="form-control" id="datepicker" placeholder="Created Date Range" />
                                                    </div>

                                                    <div className="col-md-2">
                                                        <select className="form-control">
                                                            <option selected disabled value="">Status</option>
                                                            <option value="">Status 1</option>
                                                            <option value="">Status 2</option>
                                                            <option value="">Status 3</option>
                                                        </select>
                                                    </div>

                                                    <div className="col-md-2">
                                                        <input className="form-control" placeholder="Search" />
                                                    </div>

                                                </div>

                                            </div>

                                            <div className="container-fluid mob_flow">
                                                <table className="table table-striped ">
                                                    <thead>
                                                        <tr className="_first_tr">
                                                            <th scope="col">Number</th>
                                                            <th scope="col">Branch Name</th>
                                                            <th scope="col">Agency/ Resources</th>
                                                            <th scope="col">SOW TITLE</th>
                                                            <th scope="col">SOWS</th>
                                                            <th scope="col">Created on</th>
                                                            <th scope="col">Start Date</th>
                                                            <th scope="col">End Date</th>
                                                            <th scope="col">Budget</th>
                                                            <th scope="col">Status</th>
                                                            <th scope="col">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="sow_tb_tbody">
                                                        <tr>
                                                            <td scope="row">MP65346</td>
                                                            <td >Dummy Description</td>
                                                            <td >Volkswagen Account</td>
                                                            <td >Master Service agreement</td>
                                                            <td >Agreement</td>
                                                            <td >Mar 6, 2021 </td>
                                                            <td >Jul 20, 2020</td>
                                                            <td >Jul 21, 2021</td>
                                                            <td >$ 100000.00</td>
                                                            <td ><span><img src={require('../../images/check_circle.png')} /></span>Approved</td>
                                                            <td ><img src={require('../../images/drag_indicator.png')} /></td>

                                                        </tr>
                                                        <tr>
                                                            <td scope="row">MP65346</td>
                                                            <td >Dummy Description</td>
                                                            <td >Volkswagen Account</td>
                                                            <td >Master Service agreement</td>
                                                            <td >Agreement</td>
                                                            <td >Mar 6, 2021 </td>
                                                            <td >Jul 20, 2020</td>
                                                            <td >Jul 21, 2021</td>
                                                            <td >$ 100000.00</td>
                                                            <td ><span><img src={require('../../images/check_circle.png')} /></span>Approved</td>
                                                            <td ><img src={require('../../images/drag_indicator.png')} /></td>

                                                        </tr>



                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
       

                    <div className="tab-pane tab_pane3 fade" id="statement" role="tabpanel" aria-labelledby="statement-tab">

                        <div className="sow_Box">
                            <div className="sow_head">
                                <h2>SOW</h2>
                                <a className="modal_openBtn" href="inner_dashboard_modal2.html" >Add New<i className="fa-solid fa-plus"></i></a>
                            </div>

                            <div className="">

                                <ul className="nav nav-tabs" id="my_sowTab" role="tablist">

                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link active" id="all-tab" data-bs-toggle="tab" data-bs-target="#all" type="button" role="tab" aria-controls="all" aria-selected="true">All SOW</button>
                                    </li>

                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="progress_sow-tab" data-bs-toggle="tab" data-bs-target="#progress_sow" type="button" role="tab" aria-controls="progress_sow" aria-selected="false">In Progress</button>
                                    </li>

                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="rejected-tab" data-bs-toggle="tab" data-bs-target="#rejected" type="button" role="tab" aria-controls="rejected" aria-selected="false">Rejected</button>
                                    </li>

                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="pending_approval-tab" data-bs-toggle="tab" data-bs-target="#pending_approval" type="button" role="tab" aria-controls="pending_approval" aria-selected="false">Pending Approval</button>
                                    </li>

                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="completed_sow-tab" data-bs-toggle="tab" data-bs-target="#completed_sow" type="button" role="tab" aria-controls="completed_sow" aria-selected="false">Completed</button>
                                    </li>

                                </ul>
                                <div className="tab-content" id="myTabContent">

                                    <div className="tab-pane fade show active" id="all" role="tabpanel" aria-labelledby="all-tab">

                                        <div className="main_sow_wrapper">

                                            <div className="container-fluid row_marg">

                                                <div className="row">

                                                    <div className="col-md-2">
                                                        <input className="form-control" id="datepicker" placeholder="Created Date Range" />
                                                    </div>

                                                    <div className="col-md-2">
                                                        <select className="form-control">
                                                            <option selected disabled value="">Status</option>
                                                            <option value="">Status 1</option>
                                                            <option value="">Status 2</option>
                                                            <option value="">Status 3</option>
                                                        </select>
                                                    </div>

                                                    <div className="col-md-2">
                                                        <input className="form-control" placeholder="Search" />
                                                    </div>

                                                </div>

                                            </div>

                                            <div className="container-fluid mob_flow">
                                                <table className="table table-striped ">
                                                    <thead>
                                                        <tr className="_first_tr">
                                                            <th scope="col">Number</th>
                                                            <th scope="col">Branch Name</th>
                                                            <th scope="col">Agency/ Resources</th>
                                                            <th scope="col">SOW TITLE</th>
                                                            <th scope="col">SOWS</th>
                                                            <th scope="col">Created on</th>
                                                            <th scope="col">Start Date</th>
                                                            <th scope="col">End Date</th>
                                                            <th scope="col">Budget</th>
                                                            <th scope="col">Status</th>
                                                            <th scope="col">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="sow_tb_tbody">
                                                        <tr>
                                                            <td scope="row">MP65346</td>
                                                            <td >Dummy Description</td>
                                                            <td >Volkswagen Account</td>
                                                            <td >Master Service agreement</td>
                                                            <td >Agreement</td>
                                                            <td >Mar 6, 2021 </td>
                                                            <td >Jul 20, 2020</td>
                                                            <td >Jul 21, 2021</td>
                                                            <td >$ 100000.00</td>
                                                            <td >Approved</td>
                                                            <td ><img src={require('../../images/drag_indicator.png')} /></td>

                                                        </tr>
                                                        <tr>
                                                            <td scope="row">MP65346</td>
                                                            <td >Dummy Description</td>
                                                            <td >Volkswagen Account</td>
                                                            <td >Master Service agreement</td>
                                                            <td >Agreement</td>
                                                            <td >Mar 6, 2021 </td>
                                                            <td >Jul 20, 2020</td>
                                                            <td >Jul 21, 2021</td>
                                                            <td >$ 100000.00</td>
                                                            <td >Approved</td>
                                                            <td ><img src={require('../../images/drag_indicator.png')} /></td>

                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>

                                    </div>

                                    <div className="tab-pane fade" id="progress_sow" role="tabpanel" aria-labelledby="progress_sow-tab">
                                        <div className="main_sow_wrapper">

                                            <div className="container-fluid row_marg">

                                                <div className="row">

                                                    <div className="col-md-2">
                                                        <input className="form-control" id="datepicker" placeholder="Created Date Range" />
                                                    </div>

                                                    <div className="col-md-2">
                                                        <select className="form-control">
                                                            <option selected disabled value="">Status</option>
                                                            <option value="">Status 1</option>
                                                            <option value="">Status 2</option>
                                                            <option value="">Status 3</option>
                                                        </select>
                                                    </div>

                                                    <div className="col-md-2">
                                                        <input className="form-control" placeholder="Search" />
                                                    </div>

                                                </div>

                                            </div>

                                            <div className="container-fluid mob_flow">
                                                <table className="table table-striped ">
                                                    <thead>
                                                        <tr className="_first_tr">
                                                            <th scope="col">Number</th>
                                                            <th scope="col">Branch Name</th>
                                                            <th scope="col">Agency/ Resources</th>
                                                            <th scope="col">SOW TITLE</th>
                                                            <th scope="col">SOWS</th>
                                                            <th scope="col">Created on</th>
                                                            <th scope="col">Start Date</th>
                                                            <th scope="col">End Date</th>
                                                            <th scope="col">Budget</th>
                                                            <th scope="col">Status</th>
                                                            <th scope="col">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="sow_tb_tbody">
                                                        <tr>
                                                            <td scope="row">MP65346</td>
                                                            <td >Dummy Description</td>
                                                            <td >Volkswagen Account</td>
                                                            <td >Master Service agreement</td>
                                                            <td >Agreement</td>
                                                            <td >Mar 6, 2021 </td>
                                                            <td >Jul 20, 2020</td>
                                                            <td >Jul 21, 2021</td>
                                                            <td >$ 100000.00</td>
                                                            <td ><span><img src={require('../../images/check_circle.png')} /></span>Approved</td>
                                                            <td ><img src={require('../../images/drag_indicator.png')} /></td>

                                                        </tr>
                                                        <tr>
                                                            <td scope="row">MP65346</td>
                                                            <td >Dummy Description</td>
                                                            <td >Volkswagen Account</td>
                                                            <td >Master Service agreement</td>
                                                            <td >Agreement</td>
                                                            <td >Mar 6, 2021 </td>
                                                            <td >Jul 20, 2020</td>
                                                            <td >Jul 21, 2021</td>
                                                            <td >$ 100000.00</td>
                                                            <td ><span><img src={require('../../images/check_circle.png')} /></span>Approved</td>
                                                            <td ><img src={require('../../images/drag_indicator.png')} /></td>

                                                        </tr>

                                                        <tr>
                                                            <td scope="row">MP65346</td>
                                                            <td >Dummy Description</td>
                                                            <td >Volkswagen Account</td>
                                                            <td >Master Service agreement</td>
                                                            <td >Agreement</td>
                                                            <td >Mar 6, 2021 </td>
                                                            <td >Jul 20, 2020</td>
                                                            <td >Jul 21, 2021</td>
                                                            <td >$ 100000.00</td>
                                                            <td ><span><img src={require('../../images/check_circle.png')} /></span>Approved</td>
                                                            <td ><img src={require('../../images/drag_indicator.png')} /></td>

                                                        </tr>
                                                        <tr>
                                                            <td scope="row">MP65346</td>
                                                            <td >Dummy Description</td>
                                                            <td >Volkswagen Account</td>
                                                            <td >Master Service agreement</td>
                                                            <td >Agreement</td>
                                                            <td >Mar 6, 2021 </td>
                                                            <td >Jul 20, 2020</td>
                                                            <td >Jul 21, 2021</td>
                                                            <td >$ 100000.00</td>
                                                            <td ><span><img src={require('../../images/check_circle.png')} /></span>Approved</td>
                                                            <td ><img src={require('../../images/drag_indicator.png')} /></td>

                                                        </tr>
                                                        <tr>
                                                            <td scope="row">MP65346</td>
                                                            <td >Dummy Description</td>
                                                            <td >Volkswagen Account</td>
                                                            <td >Master Service agreement</td>
                                                            <td >Agreement</td>
                                                            <td >Mar 6, 2021 </td>
                                                            <td >Jul 20, 2020</td>
                                                            <td >Jul 21, 2021</td>
                                                            <td >$ 100000.00</td>
                                                            <td ><span><img src={require('../../images/check_circle.png')} /></span>Approved</td>
                                                            <td ><img src={require('../../images/drag_indicator.png')} /></td>

                                                        </tr>
                                                        <tr>
                                                            <td scope="row">MP65346</td>
                                                            <td >Dummy Description</td>
                                                            <td >Volkswagen Account</td>
                                                            <td >Master Service agreement</td>
                                                            <td >Agreement</td>
                                                            <td >Mar 6, 2021 </td>
                                                            <td >Jul 20, 2020</td>
                                                            <td >Jul 21, 2021</td>
                                                            <td >$ 100000.00</td>
                                                            <td ><span><img src={require('../../images/check_circle.png')} /></span>Approved</td>
                                                            <td ><img src={require('../../images/drag_indicator.png')} /></td>

                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="tab-pane fade" id="rejected" role="tabpanel" aria-labelledby="rejected-tab">
                                        <div className="main_sow_wrapper">

                                            <div className="container-fluid row_marg">

                                                <div className="row">

                                                    <div className="col-md-2">
                                                        <input className="form-control" id="datepicker" placeholder="Created Date Range" />
                                                    </div>

                                                    <div className="col-md-2">
                                                        <select className="form-control">
                                                            <option selected disabled value="">Status</option>
                                                            <option value="">Status 1</option>
                                                            <option value="">Status 2</option>
                                                            <option value="">Status 3</option>
                                                        </select>
                                                    </div>

                                                    <div className="col-md-2">
                                                        <input className="form-control" placeholder="Search" />
                                                    </div>

                                                </div>

                                            </div>

                                            <div className="container-fluid mob_flow">
                                                <table className="table table-striped ">
                                                    <thead>
                                                        <tr className="_first_tr">
                                                            <th scope="col">Number</th>
                                                            <th scope="col">Branch Name</th>
                                                            <th scope="col">Agency/ Resources</th>
                                                            <th scope="col">SOW TITLE</th>
                                                            <th scope="col">SOWS</th>
                                                            <th scope="col">Created on</th>
                                                            <th scope="col">Start Date</th>
                                                            <th scope="col">End Date</th>
                                                            <th scope="col">Budget</th>
                                                            <th scope="col">Status</th>
                                                            <th scope="col">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="sow_tb_tbody">
                                                        <tr>
                                                            <td scope="row">MP65346</td>
                                                            <td >Dummy Description</td>
                                                            <td >Volkswagen Account</td>
                                                            <td >Master Service agreement</td>
                                                            <td >Agreement</td>
                                                            <td >Mar 6, 2021 </td>
                                                            <td >Jul 20, 2020</td>
                                                            <td >Jul 21, 2021</td>
                                                            <td >$ 100000.00</td>
                                                            <td ><span><img src={require('../../images/check_circle.png')} /></span>Approved</td>
                                                            <td ><img src={require('../../images/drag_indicator.png')} /></td>

                                                        </tr>
                                                        <tr>
                                                            <td scope="row">MP65346</td>
                                                            <td >Dummy Description</td>
                                                            <td >Volkswagen Account</td>
                                                            <td >Master Service agreement</td>
                                                            <td >Agreement</td>
                                                            <td >Mar 6, 2021 </td>
                                                            <td >Jul 20, 2020</td>
                                                            <td >Jul 21, 2021</td>
                                                            <td >$ 100000.00</td>
                                                            <td ><span><img src={require('../../images/check_circle.png')} /></span>Approved</td>
                                                            <td ><img src={require('../../images/drag_indicator.png')} /></td>

                                                        </tr>

                                                        <tr>
                                                            <td scope="row">MP65346</td>
                                                            <td >Dummy Description</td>
                                                            <td >Volkswagen Account</td>
                                                            <td >Master Service agreement</td>
                                                            <td >Agreement</td>
                                                            <td >Mar 6, 2021 </td>
                                                            <td >Jul 20, 2020</td>
                                                            <td >Jul 21, 2021</td>
                                                            <td >$ 100000.00</td>
                                                            <td ><span><img src={require('../../images/check_circle.png')} /></span>Approved</td>
                                                            <td ><img src={require('../../images/drag_indicator.png')} /></td>

                                                        </tr>
                                                        <tr>
                                                            <td scope="row">MP65346</td>
                                                            <td >Dummy Description</td>
                                                            <td >Volkswagen Account</td>
                                                            <td >Master Service agreement</td>
                                                            <td >Agreement</td>
                                                            <td >Mar 6, 2021 </td>
                                                            <td >Jul 20, 2020</td>
                                                            <td >Jul 21, 2021</td>
                                                            <td >$ 100000.00</td>
                                                            <td ><span><img src={require('../../images/check_circle.png')} /></span>Approved</td>
                                                            <td ><img src={require('../../images/drag_indicator.png')} /></td>

                                                        </tr>


                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="tab-pane fade" id="pending_approval" role="tabpanel" aria-labelledby="pending_approval-tab">
                                        <div className="main_sow_wrapper">

                                            <div className="container-fluid row_marg">

                                                <div className="row">

                                                    <div className="col-md-2">
                                                        <input className="form-control" id="datepicker" placeholder="Created Date Range" />
                                                    </div>

                                                    <div className="col-md-2">
                                                        <select className="form-control">
                                                            <option selected disabled value="">Status</option>
                                                            <option value="">Status 1</option>
                                                            <option value="">Status 2</option>
                                                            <option value="">Status 3</option>
                                                        </select>
                                                    </div>

                                                    <div className="col-md-2">
                                                        <input className="form-control" placeholder="Search" />
                                                    </div>

                                                </div>

                                            </div>

                                            <div className="container-fluid mob_flow">
                                                <table className="table table-striped ">
                                                    <thead>
                                                        <tr className="_first_tr">
                                                            <th scope="col">Number</th>
                                                            <th scope="col">Branch Name</th>
                                                            <th scope="col">Agency/ Resources</th>
                                                            <th scope="col">SOW TITLE</th>
                                                            <th scope="col">SOWS</th>
                                                            <th scope="col">Created on</th>
                                                            <th scope="col">Start Date</th>
                                                            <th scope="col">End Date</th>
                                                            <th scope="col">Budget</th>
                                                            <th scope="col">Status</th>
                                                            <th scope="col">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="sow_tb_tbody">
                                                        <tr>
                                                            <td scope="row">MP65346</td>
                                                            <td >Dummy Description</td>
                                                            <td >Volkswagen Account</td>
                                                            <td >Master Service agreement</td>
                                                            <td >Agreement</td>
                                                            <td >Mar 6, 2021 </td>
                                                            <td >Jul 20, 2020</td>
                                                            <td >Jul 21, 2021</td>
                                                            <td >$ 100000.00</td>
                                                            <td ><span><img src={require('../../images/check_circle.png')} /></span>Approved</td>
                                                            <td ><img src={require('../../images/drag_indicator.png')} /></td>

                                                        </tr>
                                                        <tr>
                                                            <td scope="row">MP65346</td>
                                                            <td >Dummy Description</td>
                                                            <td >Volkswagen Account</td>
                                                            <td >Master Service agreement</td>
                                                            <td >Agreement</td>
                                                            <td >Mar 6, 2021 </td>
                                                            <td >Jul 20, 2020</td>
                                                            <td >Jul 21, 2021</td>
                                                            <td >$ 100000.00</td>
                                                            <td ><span><img src={require('../../images/check_circle.png')} /></span>Approved</td>
                                                            <td ><img src={require('../../images/drag_indicator.png')} /></td>

                                                        </tr>

                                                        <tr>
                                                            <td scope="row">MP65346</td>
                                                            <td >Dummy Description</td>
                                                            <td >Volkswagen Account</td>
                                                            <td >Master Service agreement</td>
                                                            <td >Agreement</td>
                                                            <td >Mar 6, 2021 </td>
                                                            <td >Jul 20, 2020</td>
                                                            <td >Jul 21, 2021</td>
                                                            <td >$ 100000.00</td>
                                                            <td ><span><img src={require('../../images/check_circle.png')} /></span>Approved</td>
                                                            <td ><img src={require('../../images/drag_indicator.png')} /></td>

                                                        </tr>
                                                        <tr>
                                                            <td scope="row">MP65346</td>
                                                            <td >Dummy Description</td>
                                                            <td >Volkswagen Account</td>
                                                            <td >Master Service agreement</td>
                                                            <td >Agreement</td>
                                                            <td >Mar 6, 2021 </td>
                                                            <td >Jul 20, 2020</td>
                                                            <td >Jul 21, 2021</td>
                                                            <td >$ 100000.00</td>
                                                            <td ><span><img src={require('../../images/check_circle.png')} /></span>Approved</td>
                                                            <td ><img src={require('../../images/drag_indicator.png')} /></td>

                                                        </tr>
                                                        <tr>
                                                            <td scope="row">MP65346</td>
                                                            <td >Dummy Description</td>
                                                            <td >Volkswagen Account</td>
                                                            <td >Master Service agreement</td>
                                                            <td >Agreement</td>
                                                            <td >Mar 6, 2021 </td>
                                                            <td >Jul 20, 2020</td>
                                                            <td >Jul 21, 2021</td>
                                                            <td >$ 100000.00</td>
                                                            <td ><span><img src={require('../../images/check_circle.png')} /></span>Approved</td>
                                                            <td ><img src={require('../../images/drag_indicator.png')} /></td>

                                                        </tr>


                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="tab-pane fade" id="completed_sow" role="tabpanel" aria-labelledby="completed_sow-tab">
                                        <div className="main_sow_wrapper">

                                            <div className="container-fluid row_marg">

                                                <div className="row">

                                                    <div className="col-md-2">
                                                        <input className="form-control" id="datepicker" placeholder="Created Date Range" />
                                                    </div>

                                                    <div className="col-md-2">
                                                        <select className="form-control">
                                                            <option selected disabled value="">Status</option>
                                                            <option value="">Status 1</option>
                                                            <option value="">Status 2</option>
                                                            <option value="">Status 3</option>
                                                        </select>
                                                    </div>

                                                    <div className="col-md-2">
                                                        <input className="form-control" placeholder="Search" />
                                                    </div>

                                                </div>

                                            </div>

                                            <div className="container-fluid mob_flow">
                                                <table className="table table-striped ">
                                                    <thead>
                                                        <tr className="_first_tr">
                                                            <th scope="col">Number</th>
                                                            <th scope="col">Branch Name</th>
                                                            <th scope="col">Agency/ Resources</th>
                                                            <th scope="col">SOW TITLE</th>
                                                            <th scope="col">SOWS</th>
                                                            <th scope="col">Created on</th>
                                                            <th scope="col">Start Date</th>
                                                            <th scope="col">End Date</th>
                                                            <th scope="col">Budget</th>
                                                            <th scope="col">Status</th>
                                                            <th scope="col">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="sow_tb_tbody">
                                                        <tr>
                                                            <td scope="row">MP65346</td>
                                                            <td >Dummy Description</td>
                                                            <td >Volkswagen Account</td>
                                                            <td >Master Service agreement</td>
                                                            <td >Agreement</td>
                                                            <td >Mar 6, 2021 </td>
                                                            <td >Jul 20, 2020</td>
                                                            <td >Jul 21, 2021</td>
                                                            <td >$ 100000.00</td>
                                                            <td ><span><img src={require('../../images/check_circle.png')} /></span>Approved</td>
                                                            <td ><img src={require('../../images/drag_indicator.png')} /></td>

                                                        </tr>
                                                        <tr>
                                                            <td scope="row">MP65346</td>
                                                            <td >Dummy Description</td>
                                                            <td >Volkswagen Account</td>
                                                            <td >Master Service agreement</td>
                                                            <td >Agreement</td>
                                                            <td >Mar 6, 2021 </td>
                                                            <td >Jul 20, 2020</td>
                                                            <td >Jul 21, 2021</td>
                                                            <td >$ 100000.00</td>
                                                            <td ><span><img src={require('../../images/check_circle.png')} /></span>Approved</td>
                                                            <td ><img src={require('../../images/drag_indicator.png')} /></td>

                                                        </tr>



                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="tab-pane fade" id="settings" role="tabpanel" aria-labelledby="settings-tab">Settings</div>
                    <div className="tab-pane fade" id="approval" role="tabpanel" aria-labelledby="approval-tab">
                        <div className="sow_Box">
                            <div className="sow_head">
                                <h2>Approvals</h2>
                            </div>

                            <div className="">

                                <ul className="nav nav-tabs" id="my_sowTab" role="tablist">

                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link active" id="msa-tab" data-bs-toggle="tab" data-bs-target="#msa" type="button" role="tab" aria-controls="msa" aria-selected="true">MSA</button>
                                    </li>

                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="sow_sow-tab" data-bs-toggle="tab" data-bs-target="#sow_sow" type="button" role="tab" aria-controls="sow_sow" aria-selected="false">SOW</button>
                                    </li>

                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="time_sow-tab" data-bs-toggle="tab" data-bs-target="#time_sow" type="button" role="tab" aria-controls="time_sow" aria-selected="false">Timesheet</button>
                                    </li>

                                </ul>

                                <div className="tab-content" id="myTabContent">

                                    <div className="tab-pane fade show active" id="msa" role="tabpanel" aria-labelledby="msa-tab">

                                        <div className="main_sow_wrapper mt-3">

                                            <div className="container-fluid mob_flow">
                                                <table className="table table-striped ">
                                                    <thead>
                                                        <tr className="_first_tr">
                                                            <th scope="col">Number</th>
                                                            <th scope="col">Branch Name</th>
                                                            <th scope="col">Agency/ Resources</th>
                                                            <th scope="col">SOW TITLE</th>
                                                            <th scope="col">SOWS</th>
                                                            <th scope="col">Created on</th>
                                                            <th scope="col">Start Date</th>
                                                            <th scope="col">End Date</th>
                                                            <th scope="col">Budget</th>
                                                            <th scope="col">Status</th>
                                                            <th scope="col">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="sow_tb_tbody">
                                                        <tr>
                                                            <td scope="row">MP65346</td>
                                                            <td >Dummy Description</td>
                                                            <td >Volkswagen Account</td>
                                                            <td >Master Service agreement</td>
                                                            <td >Agreement</td>
                                                            <td >Mar 6, 2021 </td>
                                                            <td >Jul 20, 2020</td>
                                                            <td >Jul 21, 2021</td>
                                                            <td >$ 100000.00</td>
                                                            <td ><span><img src={require('../../images/error.png')} /></span>Pending</td>
                                                            <td ><img src={require('../../images/drag_indicator.png')} /></td>

                                                        </tr>
                                                        <tr>
                                                            <td scope="row">MP65346</td>
                                                            <td >Dummy Description</td>
                                                            <td >Volkswagen Account</td>
                                                            <td >Master Service agreement</td>
                                                            <td >Agreement</td>
                                                            <td >Mar 6, 2021 </td>
                                                            <td >Jul 20, 2020</td>
                                                            <td >Jul 21, 2021</td>
                                                            <td >$ 100000.00</td>
                                                            <td ><span><img src={require('../../images/error.png')} /></span>Pending</td>
                                                            <td ><img src={require('../../images/drag_indicator.png')} /></td>

                                                        </tr>
                                                        <tr>
                                                            <td scope="row">MP65346</td>
                                                            <td >Dummy Description</td>
                                                            <td >Volkswagen Account</td>
                                                            <td >Master Service agreement</td>
                                                            <td >Agreement</td>
                                                            <td >Mar 6, 2021 </td>
                                                            <td >Jul 20, 2020</td>
                                                            <td >Jul 21, 2021</td>
                                                            <td >$ 100000.00</td>
                                                            <td ><span><img src={require('../../images/error.png')} /></span>Pending</td>
                                                            <td ><img src={require('../../images/drag_indicator.png')} /></td>

                                                        </tr>
                                                        <tr>
                                                            <td scope="row">MP65346</td>
                                                            <td >Dummy Description</td>
                                                            <td >Volkswagen Account</td>
                                                            <td >Master Service agreement</td>
                                                            <td >Agreement</td>
                                                            <td >Mar 6, 2021 </td>
                                                            <td >Jul 20, 2020</td>
                                                            <td >Jul 21, 2021</td>
                                                            <td >$ 100000.00</td>
                                                            <td ><span><img src={require('../../images/error.png')} /></span>Pending</td>
                                                            <td ><img src={require('../../images/drag_indicator.png')} /></td>

                                                        </tr>
                                                        <tr>
                                                            <td scope="row">MP65346</td>
                                                            <td >Dummy Description</td>
                                                            <td >Volkswagen Account</td>
                                                            <td >Master Service agreement</td>
                                                            <td >Agreement</td>
                                                            <td >Mar 6, 2021 </td>
                                                            <td >Jul 20, 2020</td>
                                                            <td >Jul 21, 2021</td>
                                                            <td >$ 100000.00</td>
                                                            <td ><span><img src={require('../../images/error.png')} /></span>Pending</td>
                                                            <td ><img src={require('../../images/drag_indicator.png')} /></td>

                                                        </tr>
                                                        <tr>
                                                            <td scope="row">MP65346</td>
                                                            <td >Dummy Description</td>
                                                            <td >Volkswagen Account</td>
                                                            <td >Master Service agreement</td>
                                                            <td >Agreement</td>
                                                            <td >Mar 6, 2021 </td>
                                                            <td >Jul 20, 2020</td>
                                                            <td >Jul 21, 2021</td>
                                                            <td >$ 100000.00</td>
                                                            <td ><span><img src={require('../../images/error.png')} /></span>Pending</td>
                                                            <td ><img src={require('../../images/drag_indicator.png')} /></td>

                                                        </tr>
                                                        <tr>
                                                            <td scope="row">MP65346</td>
                                                            <td >Dummy Description</td>
                                                            <td >Volkswagen Account</td>
                                                            <td >Master Service agreement</td>
                                                            <td >Agreement</td>
                                                            <td >Mar 6, 2021 </td>
                                                            <td >Jul 20, 2020</td>
                                                            <td >Jul 21, 2021</td>
                                                            <td >$ 100000.00</td>
                                                            <td ><span><img src={require('../../images/error.png')} /></span>Pending</td>
                                                            <td ><img src={require('../../images/drag_indicator.png')} /></td>

                                                        </tr>
                                                        <tr>
                                                            <td scope="row">MP65346</td>
                                                            <td >Dummy Description</td>
                                                            <td >Volkswagen Account</td>
                                                            <td >Master Service agreement</td>
                                                            <td >Agreement</td>
                                                            <td >Mar 6, 2021 </td>
                                                            <td >Jul 20, 2020</td>
                                                            <td >Jul 21, 2021</td>
                                                            <td >$ 100000.00</td>
                                                            <td ><span><img src={require('../../images/error.png')} /></span>Pending</td>
                                                            <td ><img src={require('../../images/drag_indicator.png')} /></td>

                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>

                                    </div>

                                    <div className="tab-pane fade" id="sow_sow" role="tabpanel" aria-labelledby="sow_sow-tab">
                                        <div className="main_sow_wrapper mt-3">

                                            <div className="container-fluid mob_flow">
                                                <table className="table table-striped ">
                                                    <thead>
                                                        <tr className="_first_tr">
                                                            <th scope="col">Number</th>
                                                            <th scope="col">Branch Name</th>
                                                            <th scope="col">Agency/ Resources</th>
                                                            <th scope="col">SOW TITLE</th>
                                                            <th scope="col">SOWS</th>
                                                            <th scope="col">Created on</th>
                                                            <th scope="col">Start Date</th>
                                                            <th scope="col">End Date</th>
                                                            <th scope="col">Budget</th>
                                                            <th scope="col">Status</th>
                                                            <th scope="col">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="sow_tb_tbody">
                                                        <tr>
                                                            <td scope="row">MP65346</td>
                                                            <td >Dummy Description</td>
                                                            <td >Volkswagen Account</td>
                                                            <td >Master Service agreement</td>
                                                            <td >Agreement</td>
                                                            <td >Mar 6, 2021 </td>
                                                            <td >Jul 20, 2020</td>
                                                            <td >Jul 21, 2021</td>
                                                            <td >$ 100000.00</td>
                                                            <td ><span><img src={require('../../images/error.png')} /></span>Pending</td>
                                                            <td ><img src={require('../../images/drag_indicator.png')} /></td>

                                                        </tr>
                                                        <tr>
                                                            <td scope="row">MP65346</td>
                                                            <td >Dummy Description</td>
                                                            <td >Volkswagen Account</td>
                                                            <td >Master Service agreement</td>
                                                            <td >Agreement</td>
                                                            <td >Mar 6, 2021 </td>
                                                            <td >Jul 20, 2020</td>
                                                            <td >Jul 21, 2021</td>
                                                            <td >$ 100000.00</td>
                                                            <td ><span><img src={require('../../images/error.png')} /></span>Pending</td>
                                                            <td ><img src={require('../../images/drag_indicator.png')} /></td>

                                                        </tr>


                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="tab-pane fade" id="time_sow" role="tabpanel" aria-labelledby="time_sow-tab">
                                        <div className="main_sow_wrapper mt-3">

                                            <div className="container-fluid mob_flow">
                                                <table className="table table-striped ">
                                                    <thead>
                                                        <tr className="_first_tr">
                                                            <th scope="col">Number</th>
                                                            <th scope="col">Branch Name</th>
                                                            <th scope="col">Agency/ Resources</th>
                                                            <th scope="col">SOW TITLE</th>
                                                            <th scope="col">SOWS</th>
                                                            <th scope="col">Created on</th>
                                                            <th scope="col">Start Date</th>
                                                            <th scope="col">End Date</th>
                                                            <th scope="col">Budget</th>
                                                            <th scope="col">Status</th>
                                                            <th scope="col">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="sow_tb_tbody">
                                                        <tr>
                                                            <td scope="row">MP65346</td>
                                                            <td >Dummy Description</td>
                                                            <td >Volkswagen Account</td>
                                                            <td >Master Service agreement</td>
                                                            <td >Agreement</td>
                                                            <td >Mar 6, 2021 </td>
                                                            <td >Jul 20, 2020</td>
                                                            <td >Jul 21, 2021</td>
                                                            <td >$ 100000.00</td>
                                                            <td ><span><img src={require('../../images/error.png')} /></span>Pending</td>
                                                            <td ><img src={require('../../images/drag_indicator.png')} /></td>

                                                        </tr>



                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="tab-pane fade" id="agencies" role="tabpanel" aria-labelledby="agencies-tab">

                        <div className="agenc_hvBox">
                            <div className="container-fluid">
                                <div className="serch_contaibBox">
                                    <input type="text" name="search" placeholder="Search Agency" />
                                    <span><img src={require('../../images/icon _search_.png')} /></span>
                                </div>
                            </div>
                        </div>

                        <div className="filter_btns_area">
                            <div className="inner_filterBtns_box">
                                <div className="fill_selectBtn">
                                    <select type="text" id="techno_sel" name="techno_sel" className=" filter_input_area">

                                        <option selected disabled value="">Technology</option>
                                        <option value="tax_grp1">All Technology</option>
                                        <option value="tax_grp2">Angular</option>
                                        <option value="tax_grp3">React</option>
                                        <option value="tax_grp4">PHP</option>

                                    </select>
                                </div>

                                <div className="fill_selectBtn">
                                    <select type="text" id="techno_sel" name="techno_sel" className=" filter_input_area">

                                        <option selected disabled value="">Experience</option>
                                        <option value="tax_grp1">4 yrs</option>
                                        <option value="tax_grp2">3 yrs</option>
                                        <option value="tax_grp3">2 yrs</option>
                                        <option value="tax_grp4">Fresher</option>

                                    </select>
                                </div>

                                <div className="fill_selectBtn">
                                    <select type="text" id="techno_sel" name="techno_sel" className=" filter_input_area">

                                        <option selected disabled value="">Location</option>
                                        <option value="tax_grp1">Indore</option>
                                        <option value="tax_grp2">Ratlam</option>
                                        <option value="tax_grp3">Ujjain</option>
                                        <option value="tax_grp4">Devas</option>

                                    </select>
                                </div>

                                <div className="fill_selectBtn">
                                    <select type="text" id="techno_sel" name="techno_sel" className=" filter_input_area">

                                        <option selected disabled value="">Ratings</option>
                                        <option value="tax_grp1">5</option>
                                        <option value="tax_grp2">4</option>
                                        <option value="tax_grp3">3</option>
                                        <option value="tax_grp4">2</option>

                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="profil_describ_sec">

                            <div className="_innerProfile_box">

                                <div className="inner_pro1">

                                    <div className="proImg_box">
                                        <img className="inner_proImg" src={require('../../images/Rectangle 54.png')} />
                                    </div>
                                    <div className="pro_com_describ">
                                        <h6>Urja 360</h6>
                                        <p>UI UX Designer | India</p>
                                    </div>
                                    <div className="available_nNot">
                                        <span></span><p>Available</p>
                                    </div>

                                    <div>
                                        <p>5</p>
                                    </div>

                                </div>

                                <div className="inner_pro2">
                                    <a href="view_profile.html" className="pro_viewBtn">View Profile</a>
                                </div>

                            </div>

                            <div className="_innerProfile_box">

                                <div className="inner_pro1">

                                    <div className="proImg_box">
                                        <img className="inner_proImg" src={require('../../images/Rectangle 54.png')} />
                                    </div>
                                    <div className="pro_com_describ">
                                        <h6>Urja 360</h6>
                                        <p>UI UX Designer | India</p>
                                    </div>
                                    <div className="available_nNot">
                                        <span></span><p>Available</p>
                                    </div>

                                    <div>
                                        <p>5</p>
                                    </div>

                                </div>

                                <div className="inner_pro2">
                                    <a href="view_profile.html" className="pro_viewBtn">View Profile</a>
                                </div>

                            </div>

                            <div className="_innerProfile_box">

                                <div className="inner_pro1">

                                    <div className="proImg_box">
                                        <img className="inner_proImg" src={require('../../images/Rectangle 54.png')} />
                                    </div>
                                    <div className="pro_com_describ">
                                        <h6>Urja 360</h6>
                                        <p>UI UX Designer | India</p>
                                    </div>
                                    <div className="available_nNot">
                                        <span></span><p>Available</p>
                                    </div>

                                    <div>
                                        <p>5</p>
                                    </div>

                                </div>

                                <div className="inner_pro2">
                                    <a href="view_profile.html" className="pro_viewBtn">View Profile</a>
                                </div>

                            </div>

                            <div className="_innerProfile_box">

                                <div className="inner_pro1">

                                    <div className="proImg_box">
                                        <img className="inner_proImg" src={require('../../images/Rectangle 54.png')} />
                                    </div>
                                    <div className="pro_com_describ">
                                        <h6>Urja 360</h6>
                                        <p>UI UX Designer | India</p>
                                    </div>
                                    <div className="available_nNot">
                                        <span></span><p>Available</p>
                                    </div>

                                    <div>
                                        <p>5</p>
                                    </div>

                                </div>

                                <div className="inner_pro2">
                                    <a href="view_profile.html" className="pro_viewBtn">View Profile</a>
                                </div>

                            </div>

                            <div className="_innerProfile_box">

                                <div className="inner_pro1">

                                    <div className="proImg_box">
                                        <img className="inner_proImg" src={require('../../images/Rectangle 54.png')} />
                                    </div>
                                    <div className="pro_com_describ">
                                        <h6>Urja 360</h6>
                                        <p>UI UX Designer | India</p>
                                    </div>
                                    <div className="available_nNot">
                                        <span></span><p>Available</p>
                                    </div>

                                    <div>
                                        <p>5</p>
                                    </div>

                                </div>

                                <div className="inner_pro2">
                                    <a href="view_profile.html" className="pro_viewBtn">View Profile</a>
                                </div>

                            </div>

                            <div className="_innerProfile_box">

                                <div className="inner_pro1">

                                    <div className="proImg_box">
                                        <img className="inner_proImg" src={require('../../images/Rectangle 54.png')} />
                                    </div>
                                    <div className="pro_com_describ">
                                        <h6>Urja 360</h6>
                                        <p>UI UX Designer | India</p>
                                    </div>
                                    <div className="available_nNot">
                                        <span></span><p>Available</p>
                                    </div>

                                    <div>
                                        <p>5</p>
                                    </div>

                                </div>

                                <div className="inner_pro2">
                                    <a href="view_profile.html" className="pro_viewBtn">View Profile</a>
                                </div>

                            </div>

                            <div className="_innerProfile_box">

                                <div className="inner_pro1">

                                    <div className="proImg_box">
                                        <img className="inner_proImg" src={require('../../images/Rectangle 54.png')} />
                                    </div>
                                    <div className="pro_com_describ">
                                        <h6>Urja 360</h6>
                                        <p>UI UX Designer | India</p>
                                    </div>
                                    <div className="available_nNot">
                                        <span></span><p>Available</p>
                                    </div>

                                    <div>
                                        <p>5</p>
                                    </div>

                                </div>

                                <div className="inner_pro2">
                                    <a href="view_profile.html" className="pro_viewBtn">View Profile</a>
                                </div>

                            </div>

                            <div className="_innerProfile_box">

                                <div className="inner_pro1">

                                    <div className="proImg_box">
                                        <img className="inner_proImg" src={require('../../images/Rectangle 54.png')} />
                                    </div>
                                    <div className="pro_com_describ">
                                        <h6>Urja 360</h6>
                                        <p>UI UX Designer | India</p>
                                    </div>
                                    <div className="available_nNot">
                                        <span></span><p>Available</p>
                                    </div>

                                    <div>
                                        <p>5</p>
                                    </div>

                                </div>

                                <div className="inner_pro2">
                                    <a href="view_profile.html" className="pro_viewBtn">View Profile</a>
                                </div>

                            </div>

                            <div className="_innerProfile_box">

                                <div className="inner_pro1">

                                    <div className="proImg_box">
                                        <img className="inner_proImg" src={require('../../images/Rectangle 54.png')} />
                                    </div>
                                    <div className="pro_com_describ">
                                        <h6>Urja 360</h6>
                                        <p>UI UX Designer | India</p>
                                    </div>
                                    <div className="available_nNot">
                                        <span></span><p>Available</p>
                                    </div>

                                    <div>
                                        <p>5</p>
                                    </div>

                                </div>

                                <div className="inner_pro2">
                                    <a href="view_profile.html" className="pro_viewBtn">View Profile</a>
                                </div>

                            </div>

                            <div className="_innerProfile_box">

                                <div className="inner_pro1">

                                    <div className="proImg_box">
                                        <img className="inner_proImg" src={require('../../images/Rectangle 54.png')} />
                                    </div>
                                    <div className="pro_com_describ">
                                        <h6>Urja 360</h6>
                                        <p>UI UX Designer | India</p>
                                    </div>
                                    <div className="available_nNot">
                                        <span></span><p>Available</p>
                                    </div>

                                    <div>
                                        <p>5</p>
                                    </div>

                                </div>

                                <div className="inner_pro2">
                                    <a href="view_profile.html" className="pro_viewBtn">View Profile</a>
                                </div>

                            </div>

                            <div className="page_inationSec">
                                <div>
                                    <p>Showing 1 to 10 of 430 enteries</p>
                                </div>
                                <div className="pagInation_box">
                                    <nav aria-label="Page navigation example">
                                        <ul className="pagination">
                                            <li className="page-item">
                                                <a className="page-link" href="#" aria-label="Previous">
                                                    <span aria-hidden="true">&laquo;</span>
                                                </a>
                                            </li>
                                            <li className="page-item active"><a className="page-link" href="#">1</a></li>
                                            <li className="page-item"><a className="page-link" href="#">2</a></li>
                                            <li className="page-item"><a className="page-link" href="#">3</a></li>
                                            <li className="page-item">
                                                <a className="page-link" href="#" aria-label="Next">
                                                    <span aria-hidden="true">&raquo;</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>

                        </div>

                    </div>
                    <div className="tab-pane fade" id="resource" role="tabpanel" aria-labelledby="resource-tab">

                        <div className="agenc_hvBox">
                            <div className="container-fluid">
                                <div className="serch_contaibBox">
                                    <input type="text" name="search" placeholder="Search Resources" />
                                    <span><img src={require('../../images/icon _search_.png')} /></span>
                                </div>
                            </div>
                        </div>

                        <div className="filter_btns_area">
                            <div className="inner_filterBtns_box">
                                <div className="fill_selectBtn">
                                    <select type="text" id="techno_sel" name="techno_sel" className=" filter_input_area">

                                        <option selected disabled value="">Technology</option>
                                        <option value="tax_grp1">All Technology</option>
                                        <option value="tax_grp2">Angular</option>
                                        <option value="tax_grp3">React</option>
                                        <option value="tax_grp4">PHP</option>

                                    </select>
                                </div>

                                <div className="fill_selectBtn">
                                    <select type="text" id="techno_sel" name="techno_sel" className=" filter_input_area">

                                        <option selected disabled value="">Experience</option>
                                        <option value="tax_grp1">4 yrs</option>
                                        <option value="tax_grp2">3 yrs</option>
                                        <option value="tax_grp3">2 yrs</option>
                                        <option value="tax_grp4">Fresher</option>

                                    </select>
                                </div>

                                <div className="fill_selectBtn">
                                    <select type="text" id="techno_sel" name="techno_sel" className=" filter_input_area">

                                        <option selected disabled value="">Location</option>
                                        <option value="tax_grp1">Indore</option>
                                        <option value="tax_grp2">Ratlam</option>
                                        <option value="tax_grp3">Ujjain</option>
                                        <option value="tax_grp4">Devas</option>

                                    </select>
                                </div>

                                <div className="fill_selectBtn">
                                    <select type="text" id="techno_sel" name="techno_sel" className=" filter_input_area">

                                        <option selected disabled value="">Ratings</option>
                                        <option value="tax_grp1">5</option>
                                        <option value="tax_grp2">4</option>
                                        <option value="tax_grp3">3</option>
                                        <option value="tax_grp4">2</option>

                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="profil_describ_sec">

                            <div className="_innerProfile_box">

                                <div className="inner_pro1">

                                    <div className="proImg_box">
                                        <img className="inner_proImg" src={require('../../images/Rectangle 54.png')} />
                                    </div>
                                    <div className="pro_com_describ">
                                        <h6>Urja 360</h6>
                                        <p>UI UX Designer | India</p>
                                    </div>
                                    <div className="available_nNot">
                                        <span></span><p>Available</p>
                                    </div>

                                    <div>
                                        <p>5</p>
                                    </div>

                                </div>

                                <div className="inner_pro2">
                                    <a href="view_profile2.html" className="pro_viewBtn">View Profile</a>
                                </div>

                            </div>

                            <div className="_innerProfile_box">

                                <div className="inner_pro1">

                                    <div className="proImg_box">
                                        <img className="inner_proImg" src={require('../../images/Rectangle 54.png')} />
                                    </div>
                                    <div className="pro_com_describ">
                                        <h6>Urja 360</h6>
                                        <p>UI UX Designer | India</p>
                                    </div>
                                    <div className="available_nNot">
                                        <span></span><p>Available</p>
                                    </div>

                                    <div>
                                        <p>5</p>
                                    </div>

                                </div>

                                <div className="inner_pro2">
                                    <a href="view_profile2.html" className="pro_viewBtn">View Profile</a>
                                </div>

                            </div>

                            <div className="_innerProfile_box">

                                <div className="inner_pro1">

                                    <div className="proImg_box">
                                        <img className="inner_proImg" src={require('../../images/Rectangle 54.png')} />
                                    </div>
                                    <div className="pro_com_describ">
                                        <h6>Urja 360</h6>
                                        <p>UI UX Designer | India</p>
                                    </div>
                                    <div className="available_nNot">
                                        <span></span><p>Available</p>
                                    </div>

                                    <div>
                                        <p>5</p>
                                    </div>

                                </div>

                                <div className="inner_pro2">
                                    <a href="view_profile2.html" className="pro_viewBtn">View Profile</a>
                                </div>

                            </div>

                            <div className="_innerProfile_box">

                                <div className="inner_pro1">

                                    <div className="proImg_box">
                                        <img className="inner_proImg" src={require('../../images/Rectangle 54.png')} />
                                    </div>
                                    <div className="pro_com_describ">
                                        <h6>Urja 360</h6>
                                        <p>UI UX Designer | India</p>
                                    </div>
                                    <div className="available_nNot">
                                        <span></span><p>Available</p>
                                    </div>

                                    <div>
                                        <p>5</p>
                                    </div>

                                </div>

                                <div className="inner_pro2">
                                    <a href="view_profile2.html" className="pro_viewBtn">View Profile</a>
                                </div>

                            </div>

                            <div className="_innerProfile_box">

                                <div className="inner_pro1">

                                    <div className="proImg_box">
                                        <img className="inner_proImg" src={require('../../images/Rectangle 54.png')} />
                                    </div>
                                    <div className="pro_com_describ">
                                        <h6>Urja 360</h6>
                                        <p>UI UX Designer | India</p>
                                    </div>
                                    <div className="available_nNot">
                                        <span></span><p>Available</p>
                                    </div>

                                    <div>
                                        <p>5</p>
                                    </div>

                                </div>

                                <div className="inner_pro2">
                                    <a href="view_profile2.html" className="pro_viewBtn">View Profile</a>
                                </div>

                            </div>

                            <div className="_innerProfile_box">

                                <div className="inner_pro1">

                                    <div className="proImg_box">
                                        <img className="inner_proImg" src={require('../../images/Rectangle 54.png')} />
                                    </div>
                                    <div className="pro_com_describ">
                                        <h6>Urja 360</h6>
                                        <p>UI UX Designer | India</p>
                                    </div>
                                    <div className="available_nNot">
                                        <span></span><p>Available</p>
                                    </div>

                                    <div>
                                        <p>5</p>
                                    </div>

                                </div>

                                <div className="inner_pro2">
                                    <a href="view_profile2.html" className="pro_viewBtn">View Profile</a>
                                </div>

                            </div>

                            <div className="_innerProfile_box">

                                <div className="inner_pro1">

                                    <div className="proImg_box">
                                        <img className="inner_proImg" src={require('../../images/Rectangle 54.png')} />
                                    </div>
                                    <div className="pro_com_describ">
                                        <h6>Urja 360</h6>
                                        <p>UI UX Designer | India</p>
                                    </div>
                                    <div className="available_nNot">
                                        <span></span><p>Available</p>
                                    </div>

                                    <div>
                                        <p>5</p>
                                    </div>

                                </div>

                                <div className="inner_pro2">
                                    <a href="view_profile2.html" className="pro_viewBtn">View Profile</a>
                                </div>

                            </div>

                            <div className="_innerProfile_box">

                                <div className="inner_pro1">

                                    <div className="proImg_box">
                                        <img className="inner_proImg" src={require('../../images/Rectangle 54.png')} />
                                    </div>
                                    <div className="pro_com_describ">
                                        <h6>Urja 360</h6>
                                        <p>UI UX Designer | India</p>
                                    </div>
                                    <div className="available_nNot">
                                        <span></span><p>Available</p>
                                    </div>

                                    <div>
                                        <p>5</p>
                                    </div>

                                </div>

                                <div className="inner_pro2">
                                    <a href="view_profile2.html" className="pro_viewBtn">View Profile</a>
                                </div>

                            </div>

                            <div className="_innerProfile_box">

                                <div className="inner_pro1">

                                    <div className="proImg_box">
                                        <img className="inner_proImg" src={require('../../images/Rectangle 54.png')} />
                                    </div>
                                    <div className="pro_com_describ">
                                        <h6>Urja 360</h6>
                                        <p>UI UX Designer | India</p>
                                    </div>
                                    <div className="available_nNot">
                                        <span></span><p>Available</p>
                                    </div>

                                    <div>
                                        <p>5</p>
                                    </div>

                                </div>

                                <div className="inner_pro2">
                                    <a href="view_profile2.html" className="pro_viewBtn">View Profile</a>
                                </div>

                            </div>

                            <div className="_innerProfile_box">

                                <div className="inner_pro1">

                                    <div className="proImg_box">
                                        <img className="inner_proImg" src={require('../../images/Rectangle 54.png')} />
                                    </div>
                                    <div className="pro_com_describ">
                                        <h6>Urja 360</h6>
                                        <p>UI UX Designer | India</p>
                                    </div>
                                    <div className="available_nNot">
                                        <span></span><p>Available</p>
                                    </div>

                                    <div>
                                        <p>5</p>
                                    </div>

                                </div>

                                <div className="inner_pro2">
                                    <a href="view_profile2.html" className="pro_viewBtn">View Profile</a>
                                </div>

                            </div>

                            <div className="page_inationSec">
                                <div>
                                    <p>Showing 1 to 10 of 430 enteries</p>
                                </div>
                                <div className="pagInation_box">
                                    <nav aria-label="Page navigation example">
                                        <ul className="pagination">
                                            <li className="page-item">
                                                <a className="page-link" href="#" aria-label="Previous">
                                                    <span aria-hidden="true">&laquo;</span>
                                                </a>
                                            </li>
                                            <li className="page-item active"><a className="page-link" href="#">1</a></li>
                                            <li className="page-item"><a className="page-link" href="#">2</a></li>
                                            <li className="page-item"><a className="page-link" href="#">3</a></li>
                                            <li className="page-item">
                                                <a className="page-link" href="#" aria-label="Next">
                                                    <span aria-hidden="true">&raquo;</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>

                        </div>

                    </div>

                    <div className="tab-pane fade" id="timesheet" role="tabpanel" aria-labelledby="timesheet-tab">
                        <div className="sow_Box">
                            <div className="sow_head">
                                <h2>Timesheet</h2>
                                {/*<a className="modal_openBtn" href="inner_dashboard_modal2.html" >Add New<i className="fa-solid fa-plus"></i></a> */}
                            </div>

                            <div className="">

                                <ul className="nav nav-tabs" id="my_sowTab" role="tablist">

                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link active" id="app_req-tab" data-bs-toggle="tab" data-bs-target="#app_req" type="button" role="tab" aria-controls="app_req" aria-selected="true">Approval Request </button>
                                    </li>

                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="time_app-tab" data-bs-toggle="tab" data-bs-target="#time_app" type="button" role="tab" aria-controls="time_app" aria-selected="false">Approved</button>
                                    </li>

                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="tm_rejected-tab" data-bs-toggle="tab" data-bs-target="#tm_rejected" type="button" role="tab" aria-controls="tm_rejected" aria-selected="false">Rejected</button>
                                    </li>

                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="tp_approval-tab" data-bs-toggle="tab" data-bs-target="#tp_approval" type="button" role="tab" aria-controls="tp_approval" aria-selected="false">Payment Done</button>
                                    </li>



                                </ul>
                                <div className="tab-content" id="myTabContent">

                                    <div className="tab-pane fade show active" id="app_req" role="tabpanel" aria-labelledby="app_req-tab">

                                        <div className="main_sow_wrapper">

                                            <div className="container-fluid row_marg">

                                                <div className="row">

                                                    <div className="col-md-2">
                                                        <div className="frm_grp">
                                                            <label className="form-label" htmlFor="tax_grp" style={{ width: "40%" }}>Month</label>
                                                            <select type="text" id="tax_grp" name="tax_grp" className=" msa_input_area ex_inpPadd">

                                                                <option selected disabled value="">Month</option>
                                                                <option value="">Aug 2022</option>
                                                                <option value="tax_grp1">Sep 2022</option>
                                                                <option value="tax_grp2">Oct 2022</option>
                                                                <option value="tax_grp3">Nov 2022</option>
                                                                <option value="tax_grp4">Dec 2022</option>

                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-2">
                                                        <div className="frm_grp">
                                                            <label className="form-label" htmlFor="tax_grp" style={{ width: "40%" }}>SOW</label>
                                                            <select type="text" id="tax_grp" name="tax_grp" className=" msa_input_area ex_inpPadd">

                                                                <option selected disabled value="">SOW</option>
                                                                <option value="">SOW 1</option>
                                                                <option value="tax_grp1">SOW 2</option>
                                                                <option value="tax_grp2">SOW 3</option>
                                                                <option value="tax_grp3">SOW 4</option>
                                                                <option value="tax_grp4">SOW 5</option>

                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-2">
                                                        <div className="frm_grp">
                                                            <label className="form-label" htmlFor="tax_grp" style={{ width: "auto" }}>Resources</label>
                                                            <select type="text" id="tax_grp" name="tax_grp" className=" msa_input_area ex_inpPadd">

                                                                <option selected disabled value="">Resources</option>
                                                                <option value="">Resources 1</option>
                                                                <option value="tax_grp1">Resources 2</option>
                                                                <option value="tax_grp2">Resources 3</option>
                                                                <option value="tax_grp3">Resources 4</option>
                                                                <option value="tax_grp4">Resources 5</option>

                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-2">
                                                        <div className="frm_grp">
                                                            <label className="form-label" htmlFor="tax_grp" style={{ width: "30%" }}>Agency</label>
                                                            <select type="text" id="tax_grp" name="tax_grp" className=" msa_input_area ex_inpPadd">

                                                                <option selected disabled value="">Agency</option>
                                                                <option value="">Agency 1</option>
                                                                <option value="tax_grp1">Agency 2</option>
                                                                <option value="tax_grp2">Agency 3</option>
                                                                <option value="tax_grp3">Agency 4</option>
                                                                <option value="tax_grp4">Agency 5</option>

                                                            </select>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>

                                            <div className="container-fluid">
                                                <table className="table  ">
                                                    <thead>
                                                        <tr className="_first_tr2">

                                                            <th align="right" scope="col">Week</th>
                                                            <th scope="col" >SOW Line</th>
                                                            <th scope="col">Resources Name</th>
                                                            <th scope="col" >Amount</th>
                                                            <th scope="col">Status</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody className="sow_tb_tbody2">
                                                        <tr>

                                                            <td scope="row">1 Aug- 7Aug 2022</td>
                                                            <td className="_inUi">Agreement Paper- UI Designing</td>
                                                            <td >Rohit Ghorawat</td>
                                                            <td >AUD 2450</td>
                                                            <td className="un_approw"><span></span>Under Approval</td>

                                                        </tr>
                                                        <tr>

                                                            <td scope="row">1 Aug- 7Aug 2022</td>
                                                            <td className="_inUi">Agreement Paper- UI Designing</td>
                                                            <td >Rohit Ghorawat</td>
                                                            <td >AUD 2450</td>
                                                            <td className="un_approw"><span></span>Under Approval</td>

                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>

                                    </div>

                                    <div className="tab-pane fade" id="time_app" role="tabpanel" aria-labelledby="time_app-tab">
                                        <div className="main_sow_wrapper">

                                            <div className="container-fluid row_marg">

                                                <div className="row">

                                                    <div className="col-md-2">
                                                        <div className="frm_grp">
                                                            <label className="form-label" htmlFor="tax_grp" style={{ width: "40%" }}>Month</label>
                                                            <select type="text" id="tax_grp" name="tax_grp" className=" msa_input_area ex_inpPadd">

                                                                <option selected disabled value="">Month</option>
                                                                <option value="">Aug 2022</option>
                                                                <option value="tax_grp1">Sep 2022</option>
                                                                <option value="tax_grp2">Oct 2022</option>
                                                                <option value="tax_grp3">Nov 2022</option>
                                                                <option value="tax_grp4">Dec 2022</option>

                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-2">
                                                        <div className="frm_grp">
                                                            <label className="form-label" htmlFor="tax_grp" style={{ width: "40%" }}>SOW</label>
                                                            <select type="text" id="tax_grp" name="tax_grp" className=" msa_input_area ex_inpPadd">

                                                                <option selected disabled value="">SOW</option>
                                                                <option value="">SOW 1</option>
                                                                <option value="tax_grp1">SOW 2</option>
                                                                <option value="tax_grp2">SOW 3</option>
                                                                <option value="tax_grp3">SOW 4</option>
                                                                <option value="tax_grp4">SOW 5</option>

                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-2">
                                                        <div className="frm_grp">
                                                            <label className="form-label" htmlFor="tax_grp" style={{ width: "auto" }}>Resources</label>
                                                            <select type="text" id="tax_grp" name="tax_grp" className=" msa_input_area ex_inpPadd">

                                                                <option selected disabled value="">Resources</option>
                                                                <option value="">Resources 1</option>
                                                                <option value="tax_grp1">Resources 2</option>
                                                                <option value="tax_grp2">Resources 3</option>
                                                                <option value="tax_grp3">Resources 4</option>
                                                                <option value="tax_grp4">Resources 5</option>

                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-2">
                                                        <div className="frm_grp">
                                                            <label className="form-label" htmlFor="tax_grp" style={{ width: "30%" }}>Agency</label>
                                                            <select type="text" id="tax_grp" name="tax_grp" className=" msa_input_area ex_inpPadd">

                                                                <option selected disabled value="">Agency</option>
                                                                <option value="">Agency 1</option>
                                                                <option value="tax_grp1">Agency 2</option>
                                                                <option value="tax_grp2">Agency 3</option>
                                                                <option value="tax_grp3">Agency 4</option>
                                                                <option value="tax_grp4">Agency 5</option>

                                                            </select>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>

                                            <div className="container-fluid mob_flow">
                                                <table className="table  ">
                                                    <thead>
                                                        <tr className="_first_tr2">

                                                            <th align="right" scope="col">Week</th>
                                                            <th scope="col">SOW Line</th>
                                                            <th scope="col">Resources Name</th>
                                                            <th scope="col">Amount</th>
                                                            <th scope="col">Status</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody className="sow_tb_tbody2">
                                                        <tr>

                                                            <td scope="row">1 Aug- 7Aug 2022</td>
                                                            <td className="_inUi">Agreement Paper- UI Designing</td>
                                                            <td >Rohit Ghorawat</td>
                                                            <td >AUD 2450</td>
                                                            <td className="un_approw"><span></span>Under Approval</td>

                                                        </tr>
                                                        <tr>

                                                            <td scope="row">1 Aug- 7Aug 2022</td>
                                                            <td className="_inUi">Agreement Paper- UI Designing</td>
                                                            <td >Rohit Ghorawat</td>
                                                            <td >AUD 2450</td>
                                                            <td className="un_approw"><span></span>Under Approval</td>

                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="tab-pane fade" id="tm_rejected" role="tabpanel" aria-labelledby="tm_rejected-tab">
                                        <div className="main_sow_wrapper">

                                            <div className="container-fluid row_marg">

                                                <div className="row">

                                                    <div className="col-md-2">
                                                        <div className="frm_grp">
                                                            <label className="form-label" htmlFor="tax_grp" style={{ width: "40%" }}>Month</label>
                                                            <select type="text" id="tax_grp" name="tax_grp" className=" msa_input_area ex_inpPadd">

                                                                <option selected disabled value="">Month</option>
                                                                <option value="">Aug 2022</option>
                                                                <option value="tax_grp1">Sep 2022</option>
                                                                <option value="tax_grp2">Oct 2022</option>
                                                                <option value="tax_grp3">Nov 2022</option>
                                                                <option value="tax_grp4">Dec 2022</option>

                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-2">
                                                        <div className="frm_grp">
                                                            <label className="form-label" htmlFor="tax_grp" style={{ width: "40%" }}>SOW</label>
                                                            <select type="text" id="tax_grp" name="tax_grp" className=" msa_input_area ex_inpPadd">

                                                                <option selected disabled value="">SOW</option>
                                                                <option value="">SOW 1</option>
                                                                <option value="tax_grp1">SOW 2</option>
                                                                <option value="tax_grp2">SOW 3</option>
                                                                <option value="tax_grp3">SOW 4</option>
                                                                <option value="tax_grp4">SOW 5</option>

                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-2">
                                                        <div className="frm_grp">
                                                            <label className="form-label" htmlFor="tax_grp" style={{ width: "auto" }}>Resources</label>
                                                            <select type="text" id="tax_grp" name="tax_grp" className=" msa_input_area ex_inpPadd">

                                                                <option selected disabled value="">Resources</option>
                                                                <option value="">Resources 1</option>
                                                                <option value="tax_grp1">Resources 2</option>
                                                                <option value="tax_grp2">Resources 3</option>
                                                                <option value="tax_grp3">Resources 4</option>
                                                                <option value="tax_grp4">Resources 5</option>

                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-2">
                                                        <div className="frm_grp">
                                                            <label className="form-label" htmlFor="tax_grp" style={{ width: "30%" }}>Agency</label>
                                                            <select type="text" id="tax_grp" name="tax_grp" className=" msa_input_area ex_inpPadd">

                                                                <option selected disabled value="">Agency</option>
                                                                <option value="">Agency 1</option>
                                                                <option value="tax_grp1">Agency 2</option>
                                                                <option value="tax_grp2">Agency 3</option>
                                                                <option value="tax_grp3">Agency 4</option>
                                                                <option value="tax_grp4">Agency 5</option>

                                                            </select>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>

                                            <div className="container-fluid mob_flow">
                                                <table className="table  ">
                                                    <thead>
                                                        <tr className="_first_tr2">

                                                            <th align="right" scope="col">Week</th>
                                                            <th scope="col">SOW Line</th>
                                                            <th scope="col">Resources Name</th>
                                                            <th scope="col">Amount</th>
                                                            <th scope="col">Status</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody className="sow_tb_tbody2">
                                                        <tr>

                                                            <td scope="row">1 Aug- 7Aug 2022</td>
                                                            <td className="_inUi">Agreement Paper- UI Designing</td>
                                                            <td >Rohit Ghorawat</td>
                                                            <td >AUD 2450</td>
                                                            <td className="un_approw"><span></span>Under Approval</td>

                                                        </tr>
                                                        <tr>

                                                            <td scope="row">1 Aug- 7Aug 2022</td>
                                                            <td className="_inUi">Agreement Paper- UI Designing</td>
                                                            <td >Rohit Ghorawat</td>
                                                            <td >AUD 2450</td>
                                                            <td className="un_approw"><span></span>Under Approval</td>

                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="tab-pane fade" id="tp_approval" role="tabpanel" aria-labelledby="tp_approval-tab">
                                        <div className="main_sow_wrapper">

                                            <div className="container-fluid row_marg">

                                                <div className="row">

                                                    <div className="col-md-2">
                                                        <div className="frm_grp">
                                                            <label className="form-label" htmlFor="tax_grp" style={{ width: "40%" }}>Month</label>
                                                            <select type="text" id="tax_grp" name="tax_grp" className=" msa_input_area ex_inpPadd">

                                                                <option selected disabled value="">Month</option>
                                                                <option value="">Aug 2022</option>
                                                                <option value="tax_grp1">Sep 2022</option>
                                                                <option value="tax_grp2">Oct 2022</option>
                                                                <option value="tax_grp3">Nov 2022</option>
                                                                <option value="tax_grp4">Dec 2022</option>

                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-2">
                                                        <div className="frm_grp">
                                                            <label className="form-label" htmlFor="tax_grp" style={{ width: "40%" }}>SOW</label>
                                                            <select type="text" id="tax_grp" name="tax_grp" className=" msa_input_area ex_inpPadd">

                                                                <option selected disabled value="">SOW</option>
                                                                <option value="">SOW 1</option>
                                                                <option value="tax_grp1">SOW 2</option>
                                                                <option value="tax_grp2">SOW 3</option>
                                                                <option value="tax_grp3">SOW 4</option>
                                                                <option value="tax_grp4">SOW 5</option>

                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-2">
                                                        <div className="frm_grp">
                                                            <label className="form-label" htmlFor="tax_grp" style={{ width: "auto" }}>Resources</label>
                                                            <select type="text" id="tax_grp" name="tax_grp" className=" msa_input_area ex_inpPadd">

                                                                <option selected disabled value="">Resources</option>
                                                                <option value="">Resources 1</option>
                                                                <option value="tax_grp1">Resources 2</option>
                                                                <option value="tax_grp2">Resources 3</option>
                                                                <option value="tax_grp3">Resources 4</option>
                                                                <option value="tax_grp4">Resources 5</option>

                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-2">
                                                        <div className="frm_grp">
                                                            <label className="form-label" htmlFor="tax_grp" style={{ width: "30%" }}>Agency</label>
                                                            <select type="text" id="tax_grp" name="tax_grp" className=" msa_input_area ex_inpPadd">

                                                                <option selected disabled value="">Agency</option>
                                                                <option value="">Agency 1</option>
                                                                <option value="tax_grp1">Agency 2</option>
                                                                <option value="tax_grp2">Agency 3</option>
                                                                <option value="tax_grp3">Agency 4</option>
                                                                <option value="tax_grp4">Agency 5</option>

                                                            </select>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>

                                            <div className="container-fluid mob_flow">
                                                <table className="table  ">
                                                    <thead>
                                                        <tr className="_first_tr2">

                                                            <th align="right" scope="col">Week</th>
                                                            <th scope="col">SOW Line</th>
                                                            <th scope="col">Resources Name</th>
                                                            <th scope="col">Amount</th>
                                                            <th scope="col">Status</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody className="sow_tb_tbody2">
                                                        <tr>

                                                            <td scope="row">1 Aug- 7Aug 2022</td>
                                                            <td className="_inUi">Agreement Paper- UI Designing</td>
                                                            <td >Rohit Ghorawat</td>
                                                            <td >AUD 2450</td>
                                                            <td className="un_approw"><span></span>Under Approval</td>

                                                        </tr>
                                                        <tr>

                                                            <td scope="row">1 Aug- 7Aug 2022</td>
                                                            <td className="_inUi">Agreement Paper- UI Designing</td>
                                                            <td >Rohit Ghorawat</td>
                                                            <td >AUD 2450</td>
                                                            <td className="un_approw"><span></span>Under Approval</td>

                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="tab-pane fade" id="invoice_management" role="tabpanel" aria-labelledby="invoice_management-tab">
                        <div className="sow_Box">
                            <div className="sow_head">
                                <h2>Invoice</h2>
                                <a className="modal_openBtn in_crTimesheet" href="" >Create Timesheet</a>
                            </div>

                            <div className="">

                                <ul className="nav nav-tabs" id="my_sowTab" role="tablist">

                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link active" id="invo_all-tab" data-bs-toggle="tab" data-bs-target="#invo_all" type="button" role="tab" aria-controls="invo_all" aria-selected="true">All </button>
                                    </li>

                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="invo_pen-tab" data-bs-toggle="tab" data-bs-target="#invo_pen" type="button" role="tab" aria-controls="invo_pen" aria-selected="false">Pending</button>
                                    </li>

                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="invo_com-tab" data-bs-toggle="tab" data-bs-target="#invo_com" type="button" role="tab" aria-controls="invo_com" aria-selected="false">Completed</button>
                                    </li>

                                </ul>
                                <div className="tab-content" id="myTabContent">

                                    <div className="tab-pane fade show active" id="invo_all" role="tabpanel" aria-labelledby="invo_all-tab">

                                        <div className="main_sow_wrapper">

                                            <div className="container-fluid row_marg">

                                                <div className="row">

                                                    <div className="col-md-2">
                                                        <div className="frm_grp">
                                                            <label className="form-label" htmlFor="tax_grp" style={{ width: "40%" }}>Month</label>
                                                            <select type="text" id="tax_grp" name="tax_grp" className=" msa_input_area ex_inpPadd">

                                                                <option selected disabled value="">Month</option>
                                                                <option value="">Aug 2022</option>
                                                                <option value="tax_grp1">Sep 2022</option>
                                                                <option value="tax_grp2">Oct 2022</option>
                                                                <option value="tax_grp3">Nov 2022</option>
                                                                <option value="tax_grp4">Dec 2022</option>

                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-2">
                                                        <div className="frm_grp">
                                                            <label className="form-label" htmlFor="tax_grp" style={{ width: "40%" }}>Company</label>
                                                            <select type="text" id="tax_grp" name="tax_grp" className=" msa_input_area ex_inpPadd">

                                                                <option selected disabled value="">Company</option>
                                                                <option value="">Urja360</option>
                                                                <option value="tax_grp1">Eminence Infotech</option>


                                                            </select>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>

                                            <div className="container-fluid mob_flow">
                                                <table className="table  ">
                                                    <thead>
                                                        <tr className="_first_tr3">

                                                            <th align="right" scope="col">Date</th>
                                                            <th scope="col" >Invoice Detail</th>
                                                            <th scope="col">Company</th>
                                                            <th scope="col" >Amount</th>
                                                            <th scope="col">Status</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody className="sow_tb_tbody3">
                                                        <tr>

                                                            <td scope="row">7 Aug 2022</td>
                                                            <td className="_inUi">Agreement Paper- UI Designing</td>
                                                            <td className="_inUi">Urja360</td>
                                                            <td >AUD 2450</td>
                                                            <td className="un_approw"><span></span>Under Approval</td>

                                                        </tr>
                                                        <tr>

                                                            <td scope="row">7 Aug 2022</td>
                                                            <td className="_inUi"> Agreement Paper- UI Designing</td>
                                                            <td className="_inUi">Eminence Infotech</td>
                                                            <td >AUD 2450</td>
                                                            <td className="cpl_approw"><span></span> Completed</td>

                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>

                                    </div>

                                    <div className="tab-pane fade" id="invo_pen" role="tabpanel" aria-labelledby="invo_pen-tab">
                                        <div className="main_sow_wrapper">

                                            <div className="container-fluid row_marg">

                                                <div className="row">

                                                    <div className="col-md-2">
                                                        <div className="frm_grp">
                                                            <label className="form-label" htmlFor="tax_grp" style={{ width: "40%" }}>Month</label>
                                                            <select type="text" id="tax_grp" name="tax_grp" className=" msa_input_area ex_inpPadd">

                                                                <option selected disabled value="">Month</option>
                                                                <option value="">Aug 2022</option>
                                                                <option value="tax_grp1">Sep 2022</option>
                                                                <option value="tax_grp2">Oct 2022</option>
                                                                <option value="tax_grp3">Nov 2022</option>
                                                                <option value="tax_grp4">Dec 2022</option>

                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-2">
                                                        <div className="frm_grp">
                                                            <label className="form-label" htmlFor="tax_grp" style={{ width: "40%" }}>SOW</label>
                                                            <select type="text" id="tax_grp" name="tax_grp" className=" msa_input_area ex_inpPadd">

                                                                <option selected disabled value="">SOW</option>
                                                                <option value="">SOW 1</option>
                                                                <option value="tax_grp1">SOW 2</option>
                                                                <option value="tax_grp2">SOW 3</option>
                                                                <option value="tax_grp3">SOW 4</option>
                                                                <option value="tax_grp4">SOW 5</option>

                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-2">
                                                        <div className="frm_grp">
                                                            <label className="form-label" htmlFor="tax_grp" style={{ width: "auto" }}>Resources</label>
                                                            <select type="text" id="tax_grp" name="tax_grp" className=" msa_input_area ex_inpPadd">

                                                                <option selected disabled value="">Resources</option>
                                                                <option value="">Resources 1</option>
                                                                <option value="tax_grp1">Resources 2</option>
                                                                <option value="tax_grp2">Resources 3</option>
                                                                <option value="tax_grp3">Resources 4</option>
                                                                <option value="tax_grp4">Resources 5</option>

                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-2">
                                                        <div className="frm_grp">
                                                            <label className="form-label" htmlFor="tax_grp" style={{ width: "30%" }}>Agency</label>
                                                            <select type="text" id="tax_grp" name="tax_grp" className=" msa_input_area ex_inpPadd">

                                                                <option selected disabled value="">Agency</option>
                                                                <option value="">Agency 1</option>
                                                                <option value="tax_grp1">Agency 2</option>
                                                                <option value="tax_grp2">Agency 3</option>
                                                                <option value="tax_grp3">Agency 4</option>
                                                                <option value="tax_grp4">Agency 5</option>

                                                            </select>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>

                                            <div className="container-fluid">
                                                <table className="table  mob_flow">
                                                    <thead>
                                                        <tr className="_first_tr2">

                                                            <th align="right" scope="col">Week</th>
                                                            <th scope="col">SOW Line</th>
                                                            <th scope="col">Resources Name</th>
                                                            <th scope="col">Amount</th>
                                                            <th scope="col">Status</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody className="sow_tb_tbody2">
                                                        <tr>

                                                            <td scope="row">1 Aug- 7Aug 2022</td>
                                                            <td className="_inUi">Agreement Paper- UI Designing</td>
                                                            <td >Rohit Ghorawat</td>
                                                            <td >AUD 2450</td>
                                                            <td className="un_approw"><span></span>Under Approval</td>

                                                        </tr>
                                                        <tr>

                                                            <td scope="row">1 Aug- 7Aug 2022</td>
                                                            <td className="_inUi">Agreement Paper- UI Designing</td>
                                                            <td >Rohit Ghorawat</td>
                                                            <td >AUD 2450</td>
                                                            <td className="un_approw"><span></span>Under Approval</td>

                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="tab-pane fade" id="invo_com" role="tabpanel" aria-labelledby="invo_com-tab">
                                        <div className="main_sow_wrapper">

                                            <div className="container-fluid row_marg">

                                                <div className="row">

                                                    <div className="col-md-2">
                                                        <div className="frm_grp">
                                                            <label className="form-label" htmlFor="tax_grp" style={{ width: "40%" }}>Month</label>
                                                            <select type="text" id="tax_grp" name="tax_grp" className=" msa_input_area ex_inpPadd">

                                                                <option selected disabled value="">Month</option>
                                                                <option value="">Aug 2022</option>
                                                                <option value="tax_grp1">Sep 2022</option>
                                                                <option value="tax_grp2">Oct 2022</option>
                                                                <option value="tax_grp3">Nov 2022</option>
                                                                <option value="tax_grp4">Dec 2022</option>

                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-2">
                                                        <div className="frm_grp">
                                                            <label className="form-label" htmlFor="tax_grp" style={{ width: "40%" }}>SOW</label>
                                                            <select type="text" id="tax_grp" name="tax_grp" className=" msa_input_area ex_inpPadd">

                                                                <option selected disabled value="">SOW</option>
                                                                <option value="">SOW 1</option>
                                                                <option value="tax_grp1">SOW 2</option>
                                                                <option value="tax_grp2">SOW 3</option>
                                                                <option value="tax_grp3">SOW 4</option>
                                                                <option value="tax_grp4">SOW 5</option>

                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-2">
                                                        <div className="frm_grp">
                                                            <label className="form-label" htmlFor="tax_grp" style={{ width: "auto" }}>Resources</label>
                                                            <select type="text" id="tax_grp" name="tax_grp" className=" msa_input_area ex_inpPadd">

                                                                <option selected disabled value="">Resources</option>
                                                                <option value="">Resources 1</option>
                                                                <option value="tax_grp1">Resources 2</option>
                                                                <option value="tax_grp2">Resources 3</option>
                                                                <option value="tax_grp3">Resources 4</option>
                                                                <option value="tax_grp4">Resources 5</option>

                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-2">
                                                        <div className="frm_grp">
                                                            <label className="form-label" htmlFor="tax_grp" style={{ width: "30%" }}>Agency</label>
                                                            <select type="text" id="tax_grp" name="tax_grp" className=" msa_input_area ex_inpPadd">

                                                                <option selected disabled value="">Agency</option>
                                                                <option value="">Agency 1</option>
                                                                <option value="tax_grp1">Agency 2</option>
                                                                <option value="tax_grp2">Agency 3</option>
                                                                <option value="tax_grp3">Agency 4</option>
                                                                <option value="tax_grp4">Agency 5</option>

                                                            </select>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>

                                            <div className="container-fluid mob_flow">
                                                <table className="table  ">
                                                    <thead>
                                                        <tr className="_first_tr2">

                                                            <th align="right" scope="col">Week</th>
                                                            <th scope="col">SOW Line</th>
                                                            <th scope="col">Resources Name</th>
                                                            <th scope="col">Amount</th>
                                                            <th scope="col">Status</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody className="sow_tb_tbody2">
                                                        <tr>

                                                            <td scope="row">1 Aug- 7Aug 2022</td>
                                                            <td className="_inUi">Agreement Paper- UI Designing</td>
                                                            <td >Rohit Ghorawat</td>
                                                            <td >AUD 2450</td>
                                                            <td className="un_approw"><span></span>Under Approval</td>

                                                        </tr>
                                                        <tr>

                                                            <td scope="row">1 Aug- 7Aug 2022</td>
                                                            <td className="_inUi">Agreement Paper- UI Designing</td>
                                                            <td >Rohit Ghorawat</td>
                                                            <td >AUD 2450</td>
                                                            <td className="un_approw"><span></span>Under Approval</td>

                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                    </div>



                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="tab-pane fade" id="payment" role="tabpanel" aria-labelledby="payment-tab">Payment Management</div>

                </div>
            </section>
            {useGlobal ? <AddMasterAgreementModal/> : null}
        </div>

    )
}

export default InnerDashboardPage;
