import React from 'react'
import ResourceModalPage from '../../pages/ModalPages/ResourceModalPage'
import ResourceModalNewPage from '../../pages/ModalPages/ResModalPage'
function ResourceModal() {
  return (
    <>
    {/* <ResourceModalPage /> */}
    <ResourceModalNewPage />

    </>
  )
}

export default ResourceModal