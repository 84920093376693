import { combineReducers } from "redux"
import authSlice from "./slice/auth.slice"
import DetailSlice from './slice/detail.slice'

const rootReducer = combineReducers({
    user: authSlice,
    detailSlice: DetailSlice,
})

export default rootReducer
