import React from "react";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {useLogOutUserHooks} from '../../pages/hooks/useCreateHooks'

const Header = () => {
	 const [age, setAge] = React.useState('');
	 const hooks = useLogOutUserHooks()

	const handleChange = (event) => {
		hooks.formik.handleSubmit()
	};
	return (
		<nav className="inner_dasSide_nav">
			<div className="inner_userBox">

				{/*<div className="hamburger-icon " id="icon" >
					<i className="fa-solid fa-bars"></i>
	</div>*/}

				<div className="hamburger" id="icon">
					<span className="line"></span>
					<span className="line"></span>
					<span className="line"></span>
				</div>

				<div className="r_sidePrf_nav">
					<div className="notify_pr_usr">
						<img src={require('../../images/notify.png')} />

					</div>

					<div className="inner_userImg_Box">
					
						<div>
						<img id="drop_downImg" src={require('../../images/usr_img.png')} />
							<FormControl variant="standard" sx={{ m: 0, minWidth: 36 }}>
								<Select
									labelId="demo-simple-select-standard-label"
									id="demo-simple-select-standard"
									value={age}
									onChange={handleChange}
								>
									<MenuItem value={'Update Profile'}>Update Profile</MenuItem>
									<MenuItem value={"Sign Out"}>Sign Out</MenuItem>
								</Select>
							</FormControl>
						</div>
					</div>
				</div>

			</div>
		</nav>
	);
}

export default Header;