import React from 'react';
import VerifyOrgPage from '../../pages/LoginPages/VerufyOrgPage';



function VerifyOrg() {
  return (
  <>
  <VerifyOrgPage />
  </>
  )
}

export default VerifyOrg