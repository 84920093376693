import React from 'react'
import VreifyResourcePage from '../../pages/LoginPages/VreifyResourcePage'

function VerifyResource() {
  return (
    <>
      <VreifyResourcePage />
    </>
  )
}

export default VerifyResource