import React from 'react'
import VerifyAgnPage from '../../pages/LoginPages/VerifyAgnPage'

function VerifyAgn() {
  return (
   <>
   <VerifyAgnPage />
   </>
  )
}

export default VerifyAgn