import React from 'react';
import Hero from './component/Login/Hero';
import { BrowserRouter } from "react-router-dom";
import MainRoutes from "./routes/Routes"


function App() {
  return (
    <BrowserRouter>
      <div className="App">
        {/* <Hero /> */}
        <MainRoutes />
      </div>
    </BrowserRouter>
  );
}

export default App;
