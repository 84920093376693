import React from 'react';
import { Routes, Route } from "react-router-dom";
import Hero from '../component/Login/Hero';
import SignUp from '../component/Login/SignUp';
import Login from '../component/Login/Login';
import RegisterAsAnOrg from '../component/Login/RegisterAsAnOrg';
import RegisterAsAnAgency from '../component/Login/RegisterAsAnAgency';
import RegisterAsAnResource from '../component/Login/RegisterAsAnResource';
import VerifyAgn from '../component/Login/VerifyAgn';
import VerifyOrg from '../component/Login/VerifyOrg';
import VerifyResource from '../component/Login/VerifyResource';
import OrgModal from '../component/Modal/OrgModal';
import AgencyModal from '../component/Modal/AgencyModal';
import ResourceModal from '../component/Modal/ResourceModal';
import InnerDashboard from '../component/dashboard/InnerDashboard';

export default function MainRoutes() {
  return (
    <div>
     
        <Routes>
          <Route path="/" element={<Hero />} />
          <Route path="/signUp" element={<SignUp />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signUp/organisation" element={<RegisterAsAnOrg />} />
          <Route path="/signUp/agency" element={<RegisterAsAnAgency />} />
          <Route path="/signUp/resource" element={<RegisterAsAnResource />} />
          <Route path="/verifyAgn" element={<VerifyAgn />} />
          <Route path="/verifyOrg" element={<VerifyOrg />} />
          <Route path="/verifyresource" element={<VerifyResource />} />
          <Route path="/orgModal" element={<OrgModal />} />
          <Route path="/agencyModal" element={<AgencyModal />} />
          <Route path="/resourceModal" element={<ResourceModal />} />
          <Route path="/innerdashboard" element={<InnerDashboard />} />

        </Routes>
    </div>
  )
}
