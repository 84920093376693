import { createGlobalState } from "react-hooks-global-state"

const { setGlobalState, useGlobalState } = createGlobalState({
    showAddNewAgree: false,
    showAgency: false,
    showResources: false,
    opacity: 0,
    agencyUserDetails: [{}],
    isAgencyUser:false,
    isResourcesUser:false,    
    resourcesUserDetails: [{}]
});

export { useGlobalState, setGlobalState };