import React from 'react';
import Group from "../../images/Group 133.png";
import HeroPage from '../../pages/LoginPages/HeroPage';
function Hero() {
	return (
		<>
			<HeroPage />
		</>
	)
}

export default Hero