import React, { useEffect, useState } from "react";

import axios from "axios";

import RegisterAsAnOrgPage from '../../pages/LoginPages/registerAsAnOrgPage'

function RegisterAsAnOrg() {
//   async function load_data(){
//     try {
//    await axios.post(
//        "http://165.22.218.132:8000/dj-rest-auth/registration/",
//        {
//            "email": "react2@gmail.com",
//            "password1": "admin@123",
//            "password2": "admin@123",
//            "user_type":"ADMIN_USER",
//            "first_name": "AMan",
//            "last_name": "Bothra"
//        }
//    )
//    .then(res => {
//        console.log(res)
//        console.log(res.data)
//    })
// }
// catch (e) {
//     console.log('err api',e);
//   }
//  }

//  useEffect(() => {
//   load_data()
// }, [])
  return (
    <div>
        <RegisterAsAnOrgPage />
    </div>
  )
}

export default RegisterAsAnOrg