import React from 'react';
import './login.css';
import Group133 from "../../images/Group 133.png";
import { useNavigate } from "react-router-dom";

 function HeroPage() {

    const navigate = useNavigate();

    const signUpHandler =()=>{
        navigate("/signUp")
    }
    const loginHandler =()=>{
        navigate("/login")
        console.log('signup click')
    }
    
  return (
  <>
  <nav className="navbar navbar-expand-lg navbar-light mob_conv_nav">
		<div className="container-fluid">
			<a className="navbar-brand fr_logo">
				<img src={Group133} />
			</a>

			<button className="navbar-toggler device_menu_hide" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
				<span className="navbar-toggler-icon"></span>
			</button>

			<div className="mob-dev_menuIcons">
				<a onClick={loginHandler}><i className="fa-solid fa-right-to-bracket"></i></a>
				<a onClick={signUpHandler}><i className="fa-solid fa-user-plus"></i></a>
			</div>

			<div className="mob_dev_authBtns_box">
				<a className="btn dash_btn dash_loginBtn" onClick={loginHandler}>Login</a>
				<a className="btn dash_btn dash_signupBtn" >Sign up</a>
			</div>

			<div className="collapse navbar-collapse " id="navbarSupportedContent">

				<ul className="navbar-nav ms-auto mb-2 mb-lg-0 authentication_btnsBox">
					<li>
						<a className="btn dash_btn dash_loginBtn" onClick={loginHandler}>Login</a>
						<a className="btn dash_btn dash_signupBtn" onClick={signUpHandler}>Sign up</a>
					</li>
				</ul>
				
			</div>
		</div>
	</nav>

	<section className="dash_bannerSec">
		<div className="dash_ban_bg">
			
			<div className="container">
				<div className="row dash_lrow_padd">
					<div className="col-10 col-sm-10 col-md-10 col-lg-8 col-xl-7">
						<div className="dash_headlineBox">
							<div className="headlines">
								<h1>Meet the new standard in Global Payroll</h1>
							</div>
							<div className="another_signupBtn_box">
								<a onClick={signUpHandler} className="dash_btn dash_another_signupBtn">Sign up</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
  </>
  )
}

export default HeroPage;