import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import store from './Redux/store';
import {Provider} from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from './Redux/store';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <React.Fragment>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
          <App />
      </PersistGate>
    </Provider>
  </React.Fragment>,
  </React.StrictMode>
);


reportWebVitals();
