import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
    isLoggedIn: false,
    user: null,
    userType:null,
}

export const authSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        setIsloggedIn: (state, payload) => {
            state.user = payload.payload;
            state.isLoggedIn = true
        },
        setIsloggedOut:(state, payload) =>{
            state.user = payload.payload;
            state.isLoggedIn = false
        },
        setUserType: (state, payload) => {
            state.userType = payload.payload;
        }
    },
})


export const { setIsloggedIn ,setIsloggedOut,setUserType} = authSlice.actions

export default authSlice.reducer