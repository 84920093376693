import React from 'react';
import AgnModalPage from '../../pages/ModalPages/AgnModalPage';


function AgencyModal() {
  return (
    <>
    <AgnModalPage />
    </>
  )
}

export default AgencyModal