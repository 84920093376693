import React from 'react';
import SignUpPage from '../../pages/LoginPages/SignUpPage';

function SignUp() {
    return (
        <>
            <SignUpPage />
        </>
    )
}

export default SignUp