import React from 'react'
import OrgModaPage from '../../pages/ModalPages/OrgModaPage'

function OrgModal() {
  return (
    <>
        <OrgModaPage />    
    </>
  )
}

export default OrgModal