import React from 'react'
import LoginPage from '../../pages/LoginPages/LoginPage'

function Login() {
  return (
   <>
   <LoginPage />
   </>
  )
}

export default Login