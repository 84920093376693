
import React, { useEffect, useState } from "react";
import './login.css';
import TextField from '@mui/material/TextField';
import { Link } from 'react-router-dom';
import Group164 from "../../images/Group 164.png";
import { useLoginUserHooks } from "../hooks/useCreateHooks";
import { useNavigate } from "react-router-dom";

function LoginPage() {

    const hooks = useLoginUserHooks();
    const navigate = useNavigate();
   
    useEffect(() => {
        
    }, [])
    const signUpHandler =()=>{
        navigate("/signUp")
      
    }
    const navigateToBack = (e) => {
        e.preventDefault();
        navigate(-1);
    }
    console.log("hooks",hooks)
    
    return (
        <>
            <header class="floating_header">

                <nav class="navbar navbar-expand-lg navbar-light ">
                    <div class="container-fluid">

                         <Link to={`/`} class="navbar-brand sign_dash_logo">
                                <img src={Group164} />
                        </Link>

                        {/* <Link to={`/signUp`} class="navbar-brand sign_dash_logo"> */}
                        <li class="already_logedBox">
                                <p>Don't have an account? <Link to={`/signUp`} ><span>Sign Up</span></Link> </p>
                            </li>
                        {/* </Link> */}

                     

                    </div>
                </nav>

            </header>

            <section class="signup_tabBox_sec">
                <div class="container-fluid">
                    <div class="row">
                        <div class="dash_bannerSec">
                            <div class="dash_ban_bg">
                                <div class="dash_sign_left_head">
                                    <h1>Meet the new standard in Global Payroll</h1>
                                </div>
                            </div>

                        </div>
                        <div class="signup_agreement_frmBox organised_signIn_frm_outter">

                            <form class="sign_middleWare sign_inFrm_wid"  onSubmit={hooks.formik.handleSubmit} >
                                <div class="frm_welcome_head sign_inFrm_head">
                                    <div class="back_btnHead" onClick={(e) => navigateToBack(e)}>
                                        <span><i class="fa-solid fa-arrow-left-long"></i></span><span><a href="" >Back</a></span>
                                    </div>

                                    <h4>Sign in to Agreement Paper</h4>
                                    <p>Welcome back to Multiplier. Sign-in to start right from where you left off.</p>
                                </div>

                                <div class="sign_optionalBtns" >

                                    <button class="btn optBtn via_google">Signup Via Google</button>
                                    <button class="btn optBtn via_linkedin">Signup Via Linkedin</button>

                                </div>



                                <div class="row fill_up_taskBox" >

                                    <div class="or_divider">
                                        <p>Or</p>
                                    </div>

                                    <div class="col-md-12">
                                        <div class="frm_grp">
                                            {/* <label class="form-label" for="email">Work Email ID</label>
                            <input id="email" type="email" name="email" class="opt_fillInput" > */}
                                            {/* <TextField fullWidth id="outlined-basic" label="Work Email ID" variant="outlined" /> */}
                                            {/* <TextField
                                                fullWidth
                                                label="Work Email ID"
                                                name="email"
                                                id="fullWidth"
                                                value={formobj.email} onChange={handleInputChange} /> */}
                                                <TextField
                                                fullWidth
                                                id="email"
                                                name="email"
                                                label="Work Email ID"
                                                value={hooks.formik.values.email}
                                                onChange={hooks.formik.handleChange}
                                                error={hooks.formik.touched.email && Boolean(hooks.formik.errors.email)}
                                                helperText={hooks.formik.touched.email && hooks.formik.errors.email}
                                            /> 

                                        </div>
                                    </div>

                                    <div class="col-md-12">
                                        <div class="frm_grp">
                                            {/* <label class="form-label" for="password">Password</label>
                            <input type="password" name="password" class="opt_fillInput"> */}
                                            {/* <TextField
                                                fullWidth
                                                id="outlined-password-input"
                                                label="Password"
                                                type="password"
                                                autoComplete="current-password"
                                            /> */}
                                            {/* <TextField
                                                fullWidth
                                                id="outlined-password-input"
                                                label="Password"
                                                name="password"
                                                type="password"
                                                autoComplete="current-password"
                                                value={formobj.password}
                                                onChange={handleInputChange}
                                            /> */}
                                         <TextField
                                                fullWidth
                                                id="password"
                                                name="password"
                                                label="Password"
                                                type="password"
                                                value={hooks.formik.values.password}
                                                onChange={hooks.formik.handleChange}
                                                error={hooks.formik.touched.password && Boolean(hooks.formik.errors.password)}
                                                helperText={hooks.formik.touched.password && hooks.formik.errors.password}
                                            /> 
                                        </div>
                                    </div>

                                    <div>
                                        <button type="submit" class="org_signUp_submitBtn">Login</button>
                                    </div>

                                </div>


                                <div class="already_logedBox _onMob">
                                    <p>Don't have an account? <a href=""  onClick={signUpHandler}>Sign Up</a></p>
                                </div>
                            </form>


                        </div>
                    </div>
                </div>
            </section>
           
           
           
        </>
    )
}

 export default LoginPage;
