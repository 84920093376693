

const AUTH_KEY = 'jwtToken';
export const setToken =(token)=>{
    localStorage.setItem(AUTH_KEY,token);
  }
  
  export const getToken =()=>{
    return localStorage.getItem(AUTH_KEY);
     
  }