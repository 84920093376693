import React, { useState, useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import { useGlobalState, setGlobalState } from '../../globalState';
import "../../css/modal.css";
import dummyImg from "../../images/Rectangle 54.png";
import { SearchResources } from "../../services/apiUrl";
import Paper from "@material-ui/core/Paper";
import SearchBar from "material-ui-search-bar";
import { ResourcesList, AgencyListByFilter } from "../../services/apiUrl";


const SearchResourcesModalPage = () => {
    const [resourcesList, setResourcesList] = useState([]);
    const [showResources] = useGlobalState("showResources");
    const [rows, setRows] = useState(resourcesList);
    const [searched, setSearched] = useState("");
    const [country, setCountry] = useState("");
    const [state, setState] = useState("");
    const [agencyList,setAgencyList] = useState([]);

    useEffect(() => {
        const resultResources = ResourcesList();
        resultResources.then(value => {
            setResourcesList(value.results);
            console.log("value", value);
        }).catch(err => {
            console.log(err);
        });
    }, [resourcesList]);

    const sendUserDetails = (user) => {
        setGlobalState("resourcesUserDetails", user);
        setGlobalState("isResourcesUser", true);
        setGlobalState("isAgencyUser", false);
        setGlobalState("showResources", false);
        setGlobalState("showAddNewAgree", false);
        setGlobalState("showAddNewAgree", true);
    }


    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        switch (name) {
            case 'country':
                {
                    setCountry(value);
                }
                break;
            case 'state':
                {
                    setState(value);
                }
        }


    }
    const filterChange = (e) => {
        if (country != "" && state != "") {
            let obj = {
                country: country,
                state: e.target.value
            }
            //console.log("AgencyListByFilter"+AgencyListByFilter(obj)); 
            const resultAgencyListByFilter = AgencyListByFilter(obj);
            resultAgencyListByFilter.then(value => {
                setAgencyList(value);
                console.log(value);
            }).catch(err => {
                console.log(err);
            });
        }
    }

    const handleClose = () => {
        setGlobalState("showResources", false);

    }

    ///ui search bar
    const requestSearch = (searchedVal) => {
        const filteredRows = resourcesList.filter((item) => {
            return item.user_detail.first_name.toLowerCase().includes(searchedVal.toLowerCase());
        });
        setRows(filteredRows);
    };
    const cancelSearch = () => {
        setSearched("");
        requestSearch(searched);
    };
    return (
        <React.Fragment>

            <Modal show={showResources} onHide={handleClose} dialogClassName="my-modal-searchResources my-modal-width">
                <Modal.Header closeButton>
                    <Modal.Title>Search Resources</Modal.Title>
                </Modal.Header>
                <Modal.Body>


                    {/* <div className="filter_btns_area">
                        <div className="inner_filterBtns_box">
                            <div className="fill_selectBtn">
                                <select type="text" id="techno_sel" name="techno_sel" className=" filter_input_area">

                                    <option selected disabled value="">Technology</option>
                                    <option value="tax_grp1">All Technology</option>
                                    <option value="tax_grp2">Angular</option>
                                    <option value="tax_grp3">React</option>
                                    <option value="tax_grp4">PHP</option>

                                </select>
                            </div>

                            <div className="fill_selectBtn">
                                <select type="text" id="techno_sel" name="techno_sel" className=" filter_input_area">

                                    <option selected disabled value="">Experience</option>
                                    <option value="tax_grp1">4 yrs</option>
                                    <option value="tax_grp2">3 yrs</option>
                                    <option value="tax_grp3">2 yrs</option>
                                    <option value="tax_grp4">Fresher</option>

                                </select>
                            </div>

                            <div className="fill_selectBtn">
                                <select type="text" id="techno_sel" name="techno_sel" className=" filter_input_area">

                                    <option selected disabled value="">Location</option>
                                    <option value="tax_grp1">Indore</option>
                                    <option value="tax_grp2">Ratlam</option>
                                    <option value="tax_grp3">Ujjain</option>
                                    <option value="tax_grp4">Devas</option>

                                </select>
                            </div>

                            <div className="fill_selectBtn">
                                <select type="text" id="techno_sel" name="techno_sel" className=" filter_input_area">

                                    <option selected disabled value="">Ratings</option>
                                    <option value="tax_grp1">5</option>
                                    <option value="tax_grp2">4</option>
                                    <option value="tax_grp3">3</option>
                                    <option value="tax_grp4">2</option>

                                </select>
                            </div>
                        </div>

                    </div> */}
                    <div className="filter_btns_area">
                        <div className="inner_filterBtns_box">
                            <div className="fill_selectBtn">
                                <select type="text" id="country" name="country" className=" filter_input_area" onChange={handleChange}>
                                    <option selected disabled value="">Select Country</option>
                                    <option value="India">India</option>
                                    <option value="Afghanistan">Afghanistan</option>
                                    <option value="China">China</option>
                                    <option value="Bhutan">Bhutan</option>
                                </select>
                            </div>
                            <div className="fill_selectBtn">
                                <select type="text" id="state" name="state" className=" filter_input_area" onChange={filterChange}>
                                    <option selected disabled value="">Select State</option>
                                    <option value="Rajasthan">Rajasthan</option>
                                    <option value="Badakhshan">Badakhshan</option>
                                    <option value="Beijing">Beijing</option>
                                    <option value="Goa">Goa</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="profil_describ_sec">
                        <Paper>
                            <SearchBar
                                value={searched}
                                onChange={(searchVal) => requestSearch(searchVal)}
                                onCancelSearch={() => cancelSearch()}
                            />
                            {
                                rows && rows?.length ? (
                                    rows.map((user, index) => (
                                        <div className="_innerProfile_box" key={`user_${index}`}>
                                            <div className="inner_pro1">
                                                <div className="proImg_box">
                                                    <img className="inner_proImg" src={dummyImg} />
                                                </div>
                                                <div className="pro_com_describ">
                                                    <a href="#" onClick={() => sendUserDetails(user.user_detail)}>
                                                        <h6>{user.user_detail.first_name}</h6>
                                                        <p>{user.company_name} | {user.country_name}</p>
                                                    </a>
                                                </div>
                                                <div className="available_nNot">
                                                    <span></span><p>Available</p>
                                                </div>

                                                <div>
                                                    <p>5</p>
                                                </div>
                                            </div>
                                            <div className="inner_pro2">
                                                <a href="view_profile.html" className="pro_viewBtn">View Profile</a>
                                            </div>
                                        </div>
                                    ))) : (resourcesList.map((user, index) => (
                                        <div className="_innerProfile_box" key={`user_${index}`}>
                                            <div className="inner_pro1">
                                                <div className="proImg_box">
                                                    <img className="inner_proImg" src={dummyImg} />
                                                </div>
                                                <div className="pro_com_describ">
                                                    <a href="#" onClick={() => sendUserDetails(user.user_detail)}>
                                                        <h6>{user.user_detail.first_name}</h6>
                                                        <p>{user.company_name} | {user.country_name}</p>
                                                    </a>
                                                </div>
                                                <div className="available_nNot">
                                                    <span></span><p>Available</p>
                                                </div>

                                                <div>
                                                    <p>5</p>
                                                </div>
                                            </div>
                                            <div className="inner_pro2">
                                                <a href="view_profile.html" className="pro_viewBtn">View Profile</a>
                                            </div>
                                        </div>
                                    )))
                            }
                        </Paper>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="page_inationSec">
                        <div>
                            <p>Showing 1 to 10 of 430 enteries</p>
                        </div>
                        <div className="pagInation_box">
                            <nav aria-label="Page navigation example">
                                <ul className="pagination">
                                    <li className="page-item">
                                        <a className="page-link" href="#" aria-label="Previous">
                                            <span aria-hidden="true">&laquo;</span>
                                        </a>
                                    </li>
                                    <li className="page-item active"><a className="page-link" href="#">1</a></li>
                                    <li className="page-item"><a className="page-link" href="#">2</a></li>
                                    <li className="page-item"><a className="page-link" href="#">3</a></li>
                                    <li className="page-item">
                                        <a className="page-link" href="#" aria-label="Next">
                                            <span aria-hidden="true">&raquo;</span>
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>

        </React.Fragment>
    );
}

export default SearchResourcesModalPage;