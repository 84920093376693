
import React, { useEffect, useState } from "react";
import { State, City, Country } from "country-state-city";
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import upload_vector from '../../images/upload_vector.png';
import { useNavigate } from "react-router-dom";
import TextareaAutosize from '@mui/material/TextareaAutosize';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useOrgProfileHooks } from "../hooks/useCreateHooks";
import Input from '@mui/material/Input';
import CropModal from "./CropModal";
import profile from '../../images/profile.jpg'



const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function ResourceModalPage(props) {
  const hooks = useOrgProfileHooks();

  const [open, setOpen] = React.useState(true);

  const [currentCountry, setCurrentCountry] = useState({});
  const [currentState, setCurrentState] = useState({});


  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [selectedFile, setSelectedFile] = useState(profile);

  const jwtToken = localStorage.getItem('jwtToken');


  const handleChangeInput = (e) => {
    e.preventDefault()
    switch (e.target.name) {
      case 'logo':
        {
          hooks.formik.setFieldValue("logo", e.target.files[0])
          setSelectedFile(URL.createObjectURL(e.target.files[0]));
          setOpen(true)
        }
        break;
      case 'country':
        {
          changeCountryState(e.target.value);
           setCurrentCountry(e.target.value);
          console.log('cout:',e);
          hooks.formik.setFieldValue("country", e.target.value)
        }
        break;
      case "state": {
        console.log('e',e);
        changeCitiesState(e.target.value)
        hooks.formik.setFieldValue("state", e.target.value);
      }
      break;
      case "district": {
        
        hooks.formik.setFieldValue("district", e.target.value)
      }
    }
  };
  const  setStateOfParentModal = async (value) => {
    try {
      console.log("newTitle",value);
      setSelectedFile(value.croppedImage)
       setOpen(value.false)
     }
    catch (e) {
      console.error(e)
    }
    }
 
  const changeCountryState = (isoCode) => {
    console.log("isoCode", isoCode)

    let countryStates = [];
    let allStates = State.getAllStates();

    allStates.map(value => {
      if (isoCode === value?.countryCode) {
        countryStates.push(value);
      }
    });
    setState(countryStates);
  }
  const changeCitiesState = (item) => {
   
    let allCity = City.getCitiesOfState(currentCountry, item.isoCode)
    setCity([...allCity]);
      
  }

  useEffect(() => {
    
    let countruess = Country.getAllCountries();
    Country && setCountry([...countruess]);

  }, []);
  
  return (
    <div>
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={hooks.formik.values.disable}
      >
        <DialogContent>
          <div>
            <div class="" id="onload" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div class=" up_modal_cst_wid">
                <form class="modal-content" onSubmit={hooks.formik.handleSubmit}>
                  <div class="modal-body">
                  <div className="profile-img-contaner">
                    <div className="selectd_img"><img src={selectedFile} alt='crop-img'/></div>
                    <div class="upd_head">
                      <h5 class="modal-title update_Newhead" id="exampleModalLabel">Update your Resource</h5>
                      <div className="crop_imgContainer">
                        <div id="upload-sec" class="image-upload">
                          <label for="file-input" class="in-file">
                            <div class="upLoad_logoBox">
                              Upload Profile
                            </div>
                          </label>
                          <input
                            id="file-input"
                            name="logo"
                            type="file"
                             accept="image/*"
                            onChange={(e) => handleChangeInput(e)}
                          />
                        </div>
                      </div>
                      <div >
                        <CropModal imgsrc={selectedFile} open={open} setStateOfParentModal = {setStateOfParentModal}/>
                      </div>
                      <div>
                      </div>
                    </div>
                    </div>
                    
                    <div class="upd_body">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="frm_grp">
                            <TextField
                              fullWidth
                              id="firstName"
                              name="business_name"
                              label="Business Name"
                              value={hooks.formik.values.business_name}
                              onChange={hooks.formik.handleChange}
                            // error={hooks.formik.touched.firstName && Boolean(hooks.formik.errors.firstName)}
                            // helperText={hooks.formik.touched.firstName && hooks.formik.errors.firstName}
                            />
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="frm_grp">
                            <TextField
                              fullWidth
                              id="firstName"
                              name="tax_number"
                              label="Tax Number"
                              value={hooks.formik.values.tax_number}
                              onChange={hooks.formik.handleChange}
                            // error={hooks.formik.touched.firstName && Boolean(hooks.formik.errors.firstName)}
                            // helperText={hooks.formik.touched.firstName && hooks.formik.errors.firstName}
                            />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="frm_grp">
                            <TextField
                              fullWidth
                              id="firstName"
                              name="email"
                              label="Email"
                              value={hooks.formik.values.email}
                              onChange={hooks.formik.handleChange}
                            // error={hooks.formik.touched.firstName && Boolean(hooks.formik.errors.firstName)}
                            // helperText={hooks.formik.touched.firstName && hooks.formik.errors.firstName}
                            />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="frm_grp">
                            <TextField
                              fullWidth
                              id="email"
                              name="contact_number"
                              label="Contact Number"
                              value={hooks.formik.values.contact_number}
                              onChange={hooks.formik.handleChange}
                            // error={hooks.formik.touched.firstName && Boolean(hooks.formik.errors.firstName)}
                            // helperText={hooks.formik.touched.firstName && hooks.formik.errors.firstName}
                            />
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="frm_grp">
                            <TextareaAutosize
                              fullWidth
                              id="address"
                              placeholder="Address Line 1"
                              name="address"
                              label="Address"
                              style={{ width: 788, padding: 6 }}
                              value={hooks.formik.values.address}
                              onChange={hooks.formik.handleChange}
                            // error={hooks.formik.touched.firstName && Boolean(hooks.formik.errors.firstName)}
                            // helperText={hooks.formik.touched.firstName && hooks.formik.errors.firstName}
                            />

                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="frm_grp">
                            {/* <FormControl fullWidth sx={{ m: 1, minWidth: 120 }} size="small">
                              <InputLabel id="demo-select-medium">Country</InputLabel>
                              <Select
                                labelId="demo-select-medium"
                                id="demo-select-medium"
                                name="country"
                                label="country"
                                value={hooks.formik.values.country}
                                onChange={(e) => handleChangeInput(e)}
                              >
                                {
                                  country &&
                                  country.map((item, key) => {
                                    return (
                                      <MenuItem id="viren" key={`countylist_${key}`} value={item.isoCode}>{item.name}</MenuItem>
                                    )
                                  })
                                }
                              </Select>
                            </FormControl> */}
                             <TextField
                              fullWidth
                              id="country"
                              name="country"
                              label="Country Code"
                              value={hooks.formik.values.country}
                              onChange={hooks.formik.handleChange}
                            // error={hooks.formik.touched.firstName && Boolean(hooks.formik.errors.firstName)}
                            // helperText={hooks.formik.touched.firstName && hooks.formik.errors.firstName}
                            />
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="frm_grp">
                            {/* <FormControl fullWidth sx={{ m: 1, minWidth: 120 }} size="small">
                              <InputLabel id="demo-select-medium">state</InputLabel>
                              <Select
                                labelId="demo-select-medium"
                                id="demo-select-medium"
                                name="state"
                                label="state"
                                value={hooks.formik.values.state}
                                onChange={(e) => handleChangeInput(e)}

                              >
                                {
                                  state &&
                                  state.map((item, key) => {
                                    return (
                                      <MenuItem id="viren" key={`menuitem_${key}`} value={item}>{item.name}</MenuItem>
                                    )
                                  })
                                }
                              </Select>
                            </FormControl> */}
                             <TextField
                              fullWidth
                              id="state"
                              name="state"
                              label="state Code"
                              value={hooks.formik.values.state}
                              onChange={hooks.formik.handleChange} />
                          </div>
                        </div>

                        <div class="col-md-6">
                          {/* <FormControl fullWidth sx={{ m: 1, minWidth: 120 }} size="small">
                            <InputLabel id="demo-select-medium">District</InputLabel>
                            <Select
                              labelId="demo-select-medium"
                              id="demo-select-medium"
                              name="district"
                              label="District"
                              value={hooks.formik.values.district}
                              onChange={(e) => handleChangeInput(e)}

                            >
                              {
                                city &&
                                city.map((item, key) => {
                                  return (
                                    <MenuItem id="viren" key={key} value={item}>{item.name}</MenuItem>
                                  )
                                })
                              }
                            </Select>
                          </FormControl> */}
                           <TextField
                              fullWidth
                              id="district"
                              name="district"
                              label="District Code"
                              value={hooks.formik.values.district}
                              onChange={hooks.formik.handleChange} />
                        </div>

                        <div class="col-md-6">
                          <div class="frm_grp">

                            <TextField
                              fullWidth
                              id="outlined-basi"
                              label="Pincode"
                              name="pin_code"
                              variant="outlined"
                              value={hooks.formik.values.pin_code}
                              onChange={hooks.formik.handleChange}
                            />
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="frm_grp">
                            <TextField
                              fullWidth
                              id="outlined-basi"
                              label="Currency"
                              name="currency"
                              variant="outlined"
                              value={hooks.formik.values.currency}
                              onChange={hooks.formik.handleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div class="modal-footer">
                    <button type="submit" class="btn upd_get_started" data-bs-dismiss="modal"
                    //  disabled={!selectedFile 
                    //   || !hooks.formik.values.business_name 
                    //   || !hooks.formik.values.contact_number 
                    //   || !hooks.formik.values.address 
                    //   || !hooks.formik.values.country 
                    //   || !hooks.formik.values.tax_number 
                    //   || !hooks.formik.values.state 
                    //   || !hooks.formik.values.district 
                    //   || !hooks.formik.values.pin_code 
                    //   || !hooks.formik.values.currency}
                    >Get Started</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}

