import React from 'react'
import AddMasterAgreementModalPage from '../../pages/ModalPages/AddMasterAgreementModalPage'

function AddMasterAgreementModal(props) {
  return (
    <div>
        <AddMasterAgreementModalPage show={props.show}/>
    </div>
  )
}

export default AddMasterAgreementModal