import React, {useState, useEffect} from "react";
import Modal from 'react-bootstrap/Modal';
import { useGlobalState,setGlobalState } from '../../globalState';
import "../../css/modal.css";
import dummyImg from "../../images/Rectangle 54.png";
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
/*import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';*/
import { SearchAgency } from "../../services/apiUrl";
import { AgencyList, MSATypeList, AgencyListByFilter } from "../../services/apiUrl";

const SearchAgencyModalPage=()=>{   
    const [agencyList,setAgencyList] = useState([]);   
    const [country,setCountry] = useState("");   
    const [state,setState] = useState("");  
    //const [agencyListByFilter,setAgencyListByFilter] = useState([]); 
    
    useEffect(() => {
        const resultAgency = AgencyList();        
        resultAgency.then(value => {         
            setAgencyList(value);               
          }).catch(err => {
            console.log(err);
          });                            
    },[]);

    const sendUserDetails=(user)=>{           
      setGlobalState("agencyUserDetails",user);
      setGlobalState("isResourcesUser",false);
      setGlobalState("isAgencyUser",true);
      setGlobalState("showAgency",false);
      setGlobalState("showAddNewAgree",false);
      setGlobalState("showAddNewAgree",true);
    }

    const handleChange=(e)=>{
       const name = e.target.name;
       const value = e.target.value;
       switch (name) {
        case 'country':
          {            
            setCountry(value);                                
          }
          break;
        case 'state':
          {          
            setState(value);                                
          }  
       }   
       
        
    }

    const filterChange=(e)=>{
      if(country != "" && state != ""){
        let obj = {
          country:country,
          state:e.target.value
        }
        //console.log("AgencyListByFilter"+AgencyListByFilter(obj)); 
        const resultAgencyListByFilter = AgencyListByFilter(obj);
        resultAgencyListByFilter.then(value => {         
          setAgencyList(value); 
           console.log(value);              
          }).catch(err => {
            console.log(err);
          }); 
      }  
    }
      
     

      //Bootstrap Modal start
        const [showAgency] = useGlobalState("showAgency");
        const handleClose = () => {
          setGlobalState("showAgency",false);                 
          
        }
      //Bootstrap Modal stop

      //for autocomplete start
      const items = [
        {
          id: 0,
          name: 'Cobol'
        },
        {
          id: 1,
          name: 'JavaScript'
        },
        {
          id: 2,
          name: 'Basic'
        },
        {
          id: 3,
          name: 'PHP'
        },
        {
          id: 4,
          name: 'Java'
        }
      ]; 
       
       const [searchText,setSearchText] = useState("");
       const [searchResult,setSearchResult] = useState([]);
      //for autocomplete stop

     /* const handleChangeInput = (e) => {         
        let name = e.target.name;             
        let value = e.target.value;         
        setSearchText((prevState) => {
            return {
            ...prevState,
            [name]: value,
            };
        });  
      }; */ 
      

      const handleInput = (e) => {
        const { name, value } = e.target;
            alert(value);
        setSearchText(value);
        
      };
    

      const handleOnSearch=()=>{
        const items1 = [
            {
              id: 0,
              name: 'Cobol'
            },
            {
              id: 1,
              name: 'JavaScript'
            },
            {
              id: 2,
              name: 'Basic'
            },
            {
              id: 3,
              name: 'PHP'
            },
            {
              id: 4,
              name: 'Java'
            }
          ];          
          const result = SearchAgency(searchText);  
                  
          result.then(value => {                          
              setSearchResult(value);        
          }).catch(err => {
            console.log(err);
          });      
         
          console.log("SearchResult "+searchResult);
      }

      const handleOnSearch1=(string, results)=>{
        console.log(string, results);
      }

      const handleOnSelect=(item)=>{           
            console.log(item);
      }

      const formatResult = (item) => {
        return (
          <>        
            <span style={{ display: 'block', textAlign: 'left' }}>{item}</span>
          </>
        )
      } 
   
    return (
        <React.Fragment>  

               <Modal show={showAgency} onHide={handleClose} dialogClassName="my-modal-searchResources my-modal-width">
                    <Modal.Header closeButton>
                        <Modal.Title>Search Agency</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                       

                                <div className="agenc_hvBox">
                                    <div className="container-fluid">
                                        <div className="serch_contaibBox">                                                                                
                                        {/*<ReactSearchAutocomplete
                                            items={items}                                                    
                                            onSelect={handleOnSelect}                          
                                            onSearch={handleOnSearch}
                                            onChange={handleInput}
                                            autoFocus
                                            placeholder="Search by user name"
                                            className="form-group"
                                            name="search"
                                            formatResult={formatResult}                                            
                                            />*/}

                                         <input type="text" name="search" placeholder="Search Agency"/>
                                            
                                        </div>
                                    </div>
                                </div>

                                <div className="filter_btns_area">
                                    <div className="inner_filterBtns_box">
                                        <div className="fill_selectBtn">
                                            <select type="text" id="country" name="country" className=" filter_input_area" onChange={handleChange}>

                                                <option selected disabled value="">Select Country</option>
                                                <option value="India">India</option>
                                                <option value="USA">USA</option>
                                                <option value="China">China</option>
                                                <option value="Bhutan">Bhutan</option>

                                            </select>
                                        </div>

                                        <div className="fill_selectBtn">
                                            <select type="text" id="state" name="state" className=" filter_input_area" onChange={filterChange}>

                                                <option selected disabled value="">Select State</option>
                                                <option value="Rajasthan">Rajasthan</option>
                                                <option value="New York">New York</option>
                                                <option value="Beijing">Beijing</option>
                                                <option value="Goa">Goa</option>

                                            </select>
                                        </div>

                                        
                                    </div>
                                    
                                                      </div>

                                <div className="profil_describ_sec">
                                
                                  
                                {agencyList && agencyList.length ? (
                                     agencyList.map((user, index) => (   
                                    <div className="_innerProfile_box" key={index}>

                                        <div className="inner_pro1">

                                            <div className="proImg_box">
                                                <img className="inner_proImg" src={dummyImg}/>
                                            </div>
                                            <div className="pro_com_describ">
                                              <a href="#" onClick={()=>sendUserDetails(user.user_detail)}>
                                                <h6>{user.user_detail.first_name}</h6>
                                                <p>{user.business_name} | {user.country_name}</p>
                                              </a>  
                                            </div>
                                            <div className="available_nNot">
                                                <span></span><p>Available</p>
                                            </div>

                                            <div>
                                                <p>5</p>
                                            </div>

                                        </div>

                                        <div className="inner_pro2">
                                            <a href="view_profile.html" className="pro_viewBtn">View Profile</a>
                                        </div>
                                    </div>  
                                        ))
                                   ) : (                           
                                        <div >No data available in table</div>                                      
                                    )}  

                                    

                                    

                                    

                                </div>

                          
                    </Modal.Body>                   
                    <Modal.Footer>
                    <div className="page_inationSec">
                                        <div>
                                            <p>Showing 1 to 10 of 430 enteries</p>
                                        </div>
                                        <div className="pagInation_box">
                                            <nav aria-label="Page navigation example">
                                                <ul className="pagination">
                                                    <li className="page-item">
                                                        <a className="page-link" href="#" aria-label="Previous">
                                                            <span aria-hidden="true">&laquo;</span>
                                                        </a>
                                                    </li>
                                                    <li className="page-item active"><a className="page-link" href="#">1</a></li>
                                                    <li className="page-item"><a className="page-link" href="#">2</a></li>
                                                    <li className="page-item"><a className="page-link" href="#">3</a></li>
                                                    <li className="page-item">
                                                        <a className="page-link" href="#" aria-label="Next">
                                                            <span aria-hidden="true">&raquo;</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                    </Modal.Footer>
               </Modal>    

        </React.Fragment>                  
    );
}

export default SearchAgencyModalPage;