
import React, { useEffect, useState } from "react";
import { State, City, Country } from "country-state-city";
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { useResourceProfileHooks } from "../hooks/useCreateHooks";
import CropModal from "./CropModal";
import profile from '../../images/profile.jpg';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { countryStateCity } from '../../services/apiUrl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';



const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function ResourceModalNewPage(props) {
    const hooks = useResourceProfileHooks();

    const [open, setOpen] = useState(false);
    const [currentCountry, setCurrentCountry] = useState({});
    const [countryArray, setCountryArray] = useState([]);
    const [stateArray, setStateArray] = useState([]);
    const [currency, setCurrency] = useState('');
    const [state, setState] = useState([]);
    const [city, setCity] = useState([]);
    const [selectedFile, setSelectedFile] = useState(profile);
    const [selectedValue, setSelectedValue] = useState(false);
    const [selectedValue2, setSelectedValue2] = useState(false);

    const handleChange = (event) => {
        event.preventDefault()
        if (event.target.value === 'abn' && event.target.checked == true) {
            setSelectedValue(event.target.checked);
            setSelectedValue2(false)
            hooks.formik.setFieldValue("resources_type", "ABN")
            console.log("selectedValue", selectedValue)
            console.log("selectedValue2", selectedValue2)
        }
        else if (event.target.value === 'tfn' && event.target.checked == true) {
            setSelectedValue2(event.target.checked);
            setSelectedValue(false)
            hooks.formik.setFieldValue("resources_type", "TFN")
            console.log("selectedValue", selectedValue)
            console.log("selectedValue2", selectedValue2)
        }

    };
    console.log("hooks.formik.values.resources_type", hooks.formik.values.resources_type)
    const handleChangeInput = (e) => {
        e.preventDefault()
        switch (e.target.name) {
            case 'profile_pic':
                {
                    hooks.formik.setFieldValue("profile_pic", e.target.files[0])
                    setSelectedFile(URL.createObjectURL(e.target.files[0]));
                    setOpen(true)
                }
                break;
            case 'pii':
                {
                    hooks.formik.setFieldValue("insurances_type", "PII")
                    console.log('insurances_type:', e.target.name, hooks.formik.values.insurances_type);

                }
                break;
            case 'pli':
                {
                    hooks.formik.setFieldValue("insurances_type", "PLI")
                    console.log('insurances_type:', e.target.name, hooks.formik.values.insurances_type);
                }
                break;
            case 'wci':
                {
                    hooks.formik.setFieldValue("insurances_type", "WCI")
                    console.log('insurances_type:', e.target.name, hooks.formik.values.insurances_type);
                }
                break;
            case 'country':
                {
                    changeCountryState(e.target.value.isoCode);
                    setCurrentCountry(e.target.value.isoCode);
                    setStateArray(e.target.value.states)
                    setCurrency(e.target.value.currency.currency)
                    hooks.formik.setFieldValue("country", e.target.value)
                    hooks.formik.setFieldValue("country_id", e.target.value.id)
                    hooks.formik.setFieldValue("currency", e.target.value.currency.id)
                }
                break;
            case "state": {
                console.log('e', e);
                changeCitiesState(e.target.value)
                hooks.formik.setFieldValue("state", e.target.value);
                hooks.formik.setFieldValue("state_id", e.target.value.id);
                console.log("state", e.target.value.name)

            }
                break;
        }
    };


    const setStateOfParentModal = async (value) => {
        try {
            // console.log("newTitle", value);
            setSelectedFile(value.croppedImage)
            setOpen(value.false)
        }
        catch (e) {
            console.error(e)
        }
    }

    const changeCountryState = (isoCode) => {
        // console.log("isoCode", isoCode)

        let countryStates = [];
        let allStates = State.getAllStates();

        allStates.map(value => {
            if (isoCode === value?.countryCode) {
                countryStates.push(value);
            }
        });
        setState(countryStates);
    }
    const changeCitiesState = (item) => {

        let allCity = City.getCitiesOfState(currentCountry, item.isoCode)
        setCity([...allCity]);

    }
    //countryStateCity//
    const countryStateCityFunction = async () => {

        const result = await countryStateCity();
        if (result) {
            setCountryArray(result)
        }
    };

    useEffect(() => {
        countryStateCityFunction()

        // let countruess = Country.getAllCountries();
        // Country && setCountry([...countruess]);

    }, []);
    const handleClose = () => {
        setOpen(false);
      };

    return (
        <div>
            <BootstrapDialog
                aria-labelledby="customized-dialog-title"
                open={open}
                >
                   <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                  
                  </BootstrapDialogTitle>
                <DialogContent>
                    <div>
                        <div class="" id="onload" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div class=" up_modal_cst_wid">
                                <form class="modal-content" onSubmit={hooks.formik.handleSubmit}>
                                    <div class="modal-body">
                                        <div className="profile-img-contaner">
                                            <div className="selectd_img"><img src={selectedFile} alt='crop-img' /></div>
                                            <div class="upd_head">
                                                <h5 class="modal-title update_Newhead" id="exampleModalLabel">Update your New Resource</h5>
                                                <div className="crop_imgContainer">
                                                    <div id="upload-sec" class="image-upload">
                                                        <label for="file-input" class="in-file">
                                                            <div class="upLoad_logoBox">
                                                                Upload Profile
                                                            </div>
                                                        </label>
                                                        <input
                                                            id="file-input"
                                                            name="profile_pic"
                                                            type="file"
                                                            accept="image/*"
                                                            onChange={(e) => handleChangeInput(e)}
                                                        />
                                                    </div>
                                                </div>
                                                <div >
                                                    <CropModal imgsrc={selectedFile} open={open} setStateOfParentModal={setStateOfParentModal} />
                                                </div>
                                                <div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="tax_ded_checkBox_opt">
                                            <ul class="nav nav-tabs" >

                                                <li class="nav-item" name="abn-tab" id="abn-tab"  >
                                                    <button class="nav-link active" id="abn-tab" data-bs-toggle="tab" data-bs-target="#abn-tab-pane" type="button" role="tab" aria-controls="abn-tab-pane" aria-selected="true">

                                                        <FormControlLabel
                                                            label="I have ABN & I would submit my own Tax"
                                                            control={<Checkbox checked={selectedValue}
                                                                onChange={handleChange}
                                                                value='abn'
                                                                sx={{
                                                                    color: 'black',
                                                                    '&.Mui-checked': {
                                                                        color: 'red',
                                                                    },
                                                                }}
                                                            />}
                                                        />
                                                        {/* <FormControlLabel value=" abn" control={<Radio />} label=" I have ABN & I would submit my own Tax" /> */}
                                                        {/* <div>
                                                        <span class="checkd_btn">
                                                            <span class="chk_innerSpan" ></span></span>
                                                        <span class="check_opt"  >I have ABN & I would submit my own Tax  </span>
                                                        </div> */}
                                                        {/* <input type="checkbox" id="checkbox2" name="pi" /> */}
                                                        {/* I have ABN & I would submit my own Tax */}
                                                        {/* <label for="checkbox2">I have ABN & I would submit my own Tax</label> */}
                                                    </button>
                                                </li>

                                                <li class="nav-item" role="presentation" name="tfn-tab" id="abn-tab">
                                                    <button class="nav-link" id="tfn-tab" data-bs-toggle="tab" data-bs-target="#tfn-tab-pane" type="button" role="tab" aria-controls="tfn-tab-pane" aria-selected="false">
                                                        {/* <FormControlLabel value=" tfn" control={<Radio />} label="  I  have TFN & Required Tax Assistance " /> {/* <div> */}
                                                        <FormControlLabel
                                                            label="I  have TFN & Required Tax Assistance "
                                                            control={<Checkbox checked={selectedValue2} onChange={handleChange} value='tfn' />}
                                                        />
                                                        {/* <div>
                                                        <span class="checkd_btn"><span class="chk_innerSpan" ></span></span>
                                                        <span class="check_opt" > I  have TFN & Required Tax Assistance </span>
                                                        </div> */}
                                                        {/* <input type="checkbox" id="checkbox2" name="pii" />
                                                                I  have TFN & Required Tax Assistance */}
                                                        {/* <label for="checkbox2"></label> */}
                                                    </button>
                                                </li>

                                            </ul>
                                        </div>
                                        <div class="">

                                            <div class="tab-content" id="myTabContent">
                                                <div class="tab-pane fade show active" id="abn-tab-pane" role="tabpanel" aria-labelledby="abn-tab" tabindex="0">

                                                    <div class="upd_body">
                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                <div class="frm_grp">

                                                                    <TextField
                                                                        fullWidth
                                                                        id="abn_number"
                                                                        name="abn_number"
                                                                        label="ABN Name"
                                                                        value={hooks.formik.values.abn_number}
                                                                        onChange={hooks.formik.handleChange}
                                                                    // error={hooks.formik.touched.firstName && Boolean(hooks.formik.errors.firstName)}
                                                                    // helperText={hooks.formik.touched.firstName && hooks.formik.errors.firstName}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div class="col-md-6">
                                                                <div class="frm_grp">

                                                                    <TextField
                                                                        fullWidth
                                                                        id="company_name"
                                                                        name="company_name"
                                                                        label="Company Name"
                                                                        value={hooks.formik.values.company_name}
                                                                        onChange={hooks.formik.handleChange}
                                                                    // error={hooks.formik.touched.firstName && Boolean(hooks.formik.errors.firstName)}
                                                                    // helperText={hooks.formik.touched.firstName && hooks.formik.errors.firstName}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div class="col-md-12">
                                                                <div class="insur_headBox">
                                                                    <h4>I have Below Insurances</h4>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="checkbox">
                                                                        <input type="checkbox" id="checkbox" name="pli" value={hooks.formik.values.pli} onChange={(e) => handleChangeInput(e)} />
                                                                        <label for="checkbox"><span>Public Liability Insurance</span></label>
                                                                    </div>

                                                                    <div class="checkbox">
                                                                        <input type="checkbox" id="checkbox2" name="pii" value={hooks.formik.values.pii} onChange={(e) => handleChangeInput(e)} />
                                                                        <label for="checkbox2"><span>Professional Indemnity Insurance</span></label>
                                                                    </div>

                                                                    <div class="checkbox">
                                                                        <input type="checkbox" id="checkbox3" name="wci" value={hooks.formik.values.pii} onChange={(e) => handleChangeInput(e)} />
                                                                        <label for="checkbox3"><span>Workers' compensation insurance</span></label>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                            <div class="col-md-12">
                                                                <div class="frm_grp">

                                                                    <TextareaAutosize
                                                                        fullWidth
                                                                        id="skill_set"
                                                                        placeholder="Select skill_set"
                                                                        name="skill_set"
                                                                        label="skill_set"
                                                                        style={{ width: 788, padding: 6 }}
                                                                        value={hooks.formik.values.skill_set}
                                                                        onChange={hooks.formik.handleChange}
                                                                    // error={hooks.formik.touched.firstName && Boolean(hooks.formik.errors.firstName)}
                                                                    // helperText={hooks.formik.touched.firstName && hooks.formik.errors.firstName}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div class="col-md-12">
                                                                <div class="frm_grp">

                                                                    <TextareaAutosize
                                                                        fullWidth
                                                                        id="address"
                                                                        placeholder="Address Line 1"
                                                                        name="address"
                                                                        label="Address"
                                                                        style={{ width: 788, padding: 6 }}
                                                                        value={hooks.formik.values.address}
                                                                        onChange={hooks.formik.handleChange}
                                                                    // error={hooks.formik.touched.firstName && Boolean(hooks.formik.errors.firstName)}
                                                                    // helperText={hooks.formik.touched.firstName && hooks.formik.errors.firstName}
                                                                    />
                                                                </div>
                                                            </div>


                                                            <div class="col-md-6">
                                                                <FormControl fullWidth sx={{ m: 1, minWidth: 120 }} size="small">
                                                                    <InputLabel id="demo-select-medium">Country</InputLabel>
                                                                    <Select
                                                                        labelId="demo-select-medium"
                                                                        id="demo-select-medium"
                                                                        name="country"
                                                                        label="country"
                                                                        value={hooks.formik.values.country}
                                                                        onChange={(e) => handleChangeInput(e)}
                                                                    >
                                                                        {
                                                                            countryArray &&
                                                                            countryArray.map((item, key) => {
                                                                                return (
                                                                                    <MenuItem id="viren" key={`countylist_${key}`} value={item}>{item.name}</MenuItem>
                                                                                )
                                                                            })
                                                                        }
                                                                    </Select>
                                                                </FormControl>

                                                            </div>

                                                            <div class="col-md-6">
                                                                <div class="frm_grp">
                                                                    <FormControl fullWidth sx={{ m: 1, minWidth: 120 }} size="small">
                                                                        <InputLabel id="demo-select-medium">state</InputLabel>
                                                                        <Select
                                                                            labelId="demo-select-medium"
                                                                            id="demo-select-medium"
                                                                            name="state"
                                                                            label="state"
                                                                            value={hooks.formik.values.state}
                                                                            onChange={(e) => handleChangeInput(e)}

                                                                        >
                                                                            {
                                                                                stateArray &&
                                                                                stateArray.map((item, key) => {
                                                                                    return (
                                                                                        <MenuItem id="viren" key={`menuitem_${key}`} value={item}>{item.name}</MenuItem>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </Select>
                                                                    </FormControl>
                                                                </div>
                                                            </div>

                                                            <div class="col-md-6">
                                                                <div class="frm_grp">
                                                                    <TextField
                                                                        fullWidth
                                                                        id="district"
                                                                        name="district"
                                                                        label="District Code"
                                                                        value={hooks.formik.values.district}
                                                                        onChange={hooks.formik.handleChange} />
                                                                </div>
                                                            </div>

                                                            <div class="col-md-6">
                                                                <div class="frm_grp">

                                                                    <TextField
                                                                        fullWidth
                                                                        id="outlined-basi"
                                                                        label="Pincode"
                                                                        name="pin_code"
                                                                        variant="outlined"
                                                                        value={hooks.formik.values.pin_code}
                                                                        onChange={hooks.formik.handleChange}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div class="col-md-6">
                                                                <div class="frm_grp">

                                                                    <TextField
                                                                        fullWidth
                                                                        id="outlined-basi"
                                                                        label="Currency"
                                                                        name="currency"
                                                                        disabled
                                                                        value={currency}
                                                                        onChange={hooks.formik.handleChange}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div class="col-md-6">
                                                                <div class="frm_grp">

                                                                    <TextField
                                                                        fullWidth
                                                                        id="outlined-basi"
                                                                        name="contact_number"
                                                                        label="Contact Number"
                                                                        value={hooks.formik.values.contact_number}
                                                                        onChange={hooks.formik.handleChange}
                                                                    // error={hooks.formik.touched.firstName && Boolean(hooks.formik.errors.firstName)}
                                                                    // helperText={hooks.formik.touched.firstName && hooks.formik.errors.firstName}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div class="col-md-12">
                                                                <div class="checkbox">
                                                                    <input type="checkbox" id="checkbox4" name="need_assistance" value={hooks.formik.values.need_assistance} onChange={hooks.formik.handleChange} />
                                                                    <label for="checkbox4"><span>I Need Assistance for Insurance</span></label>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>

                                                <div class="tab-pane fade" id="tfn-tab-pane" role="tabpanel" aria-labelledby="tfn-tab" tabindex="0">

                                                    <div class="upd_body">
                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                <div class="frm_grp">

                                                                    <TextField
                                                                        fullWidth
                                                                        id="tfn_number"
                                                                        name="tfn_number"
                                                                        label="TFN Name"
                                                                        value={hooks.formik.values.tfn_number}
                                                                        onChange={hooks.formik.handleChange}
                                                                    // error={hooks.formik.touched.firstName && Boolean(hooks.formik.errors.firstName)}
                                                                    // helperText={hooks.formik.touched.firstName && hooks.formik.errors.firstName}
                                                                    />
                                                                </div>
                                                            </div>


                                                            <div class="col-md-12">
                                                                <div class="frm_grp">

                                                                    <TextareaAutosize
                                                                        fullWidth
                                                                        id="skill_set"
                                                                        placeholder="Select skill_set"
                                                                        name="skill_set"
                                                                        label="skill_set"
                                                                        style={{ width: 788, padding: 6 }}
                                                                        value={hooks.formik.values.skill_set}
                                                                        onChange={hooks.formik.handleChange}
                                                                    // error={hooks.formik.touched.firstName && Boolean(hooks.formik.errors.firstName)}
                                                                    // helperText={hooks.formik.touched.firstName && hooks.formik.errors.firstName}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div class="col-md-12">
                                                                <div class="frm_grp">

                                                                    <TextareaAutosize
                                                                        fullWidth
                                                                        id="address"
                                                                        placeholder="Address Line 1"
                                                                        name="address"
                                                                        label="Address"
                                                                        style={{ width: 788, padding: 6 }}
                                                                        value={hooks.formik.values.address}
                                                                        onChange={hooks.formik.handleChange}
                                                                    // error={hooks.formik.touched.firstName && Boolean(hooks.formik.errors.firstName)}
                                                                    // helperText={hooks.formik.touched.firstName && hooks.formik.errors.firstName}
                                                                    />
                                                                </div>
                                                            </div>


                                                            <div class="col-md-6">
                                                                <TextField
                                                                    fullWidth
                                                                    id="country"
                                                                    name="country"
                                                                    label="Country Code"
                                                                    value={hooks.formik.values.country}
                                                                    onChange={hooks.formik.handleChange}
                                                                // error={hooks.formik.touched.firstName && Boolean(hooks.formik.errors.firstName)}
                                                                // helperText={hooks.formik.touched.firstName && hooks.formik.errors.firstName}
                                                                />

                                                            </div>

                                                            <div class="col-md-6">
                                                                <div class="frm_grp">
                                                                    <TextField
                                                                        fullWidth
                                                                        id="state"
                                                                        name="state"
                                                                        label="state Code"
                                                                        value={hooks.formik.values.state}
                                                                        onChange={hooks.formik.handleChange} />
                                                                </div>
                                                            </div>

                                                            <div class="col-md-6">
                                                                <div class="frm_grp">
                                                                    <TextField
                                                                        fullWidth
                                                                        id="district"
                                                                        name="district"
                                                                        label="District Code"
                                                                        value={hooks.formik.values.district}
                                                                        onChange={hooks.formik.handleChange} />
                                                                </div>
                                                            </div>

                                                            <div class="col-md-6">
                                                                <div class="frm_grp">

                                                                    <TextField
                                                                        fullWidth
                                                                        id="outlined-basi"
                                                                        label="Pincode"
                                                                        name="pin_code"
                                                                        variant="outlined"
                                                                        value={hooks.formik.values.pin_code}
                                                                        onChange={hooks.formik.handleChange}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div class="col-md-6">
                                                                <div class="frm_grp">

                                                                    <TextField
                                                                        fullWidth
                                                                        id="outlined-basi"
                                                                        label="Currency"
                                                                        name="currency"
                                                                        variant="outlined"
                                                                        value={hooks.formik.values.currency}
                                                                        onChange={hooks.formik.handleChange}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div class="col-md-6">
                                                                <div class="frm_grp">
                                                                    {/* <label class="form-label" for="phone">Contact Number</label>
                                                                    <input type="tel" id="phone" name="phone" data-name="phone" maxlength="10" class="upd_input_area" /> */}
                                                                    <TextField
                                                                        fullWidth
                                                                        id="email"
                                                                        name="contact_number"
                                                                        label="Contact Number"
                                                                        value={hooks.formik.values.contact_number}
                                                                        onChange={hooks.formik.handleChange}
                                                                    // error={hooks.formik.touched.firstName && Boolean(hooks.formik.errors.firstName)}
                                                                    // helperText={hooks.formik.touched.firstName && hooks.formik.errors.firstName}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div class="col-md-12">
                                                                <div class="checkbox">
                                                                    <input type="checkbox" id="checkbox5" name="need_assistance" value={hooks.formik.values.need_assistance} onChange={hooks.formik.handleChange} />
                                                                    <label for="checkbox5"><span>I Need Assistance for Insurance</span></label>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>


                                    </div>
                                    <div class="modal-footer">
                                        <button type="submit" class="btn upd_get_started" data-bs-dismiss="modal"
                                        >Get Started</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </BootstrapDialog>
        </div>
    );
}

