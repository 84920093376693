import React, { useEffect, useState } from "react";
import axios from "axios";
import './login.css';

import Group133 from "../../images/Group 133.png";
import image6 from "../../images/image 6.png";
import TextField from '@mui/material/TextField';
import { useNavigate } from "react-router-dom";
import { useRegisterAsAnAgncyPageHooks } from "../hooks/useCreateHooks";
import { Link } from 'react-router-dom';

function RegisterAsAnAgncyPage() {

    const hooks = useRegisterAsAnAgncyPageHooks();
    const navigate = useNavigate();

    const navigateToBack = (e) => {
        e.preventDefault();
        navigate(-1);
    } 
    const data = hooks.formik.values;

    return (
        <>
            <header className="floating_header">

                <nav className="navbar navbar-expand-lg navbar-light ">
                    <div className="container-fluid">

                        
                       <Link to={`/`} class="navbar-brand sign_dash_logo">
                            <img src={Group133} />
                        </Link>

                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                            <li className="already_logedBox">
                                <p>Already have an account?
                                    <Link to={`/login`}>
                                        Log in
                                    </Link>
                                </p>
                            </li>
                        </ul>

                    </div>
                </nav>

            </header>

            <section className="signup_tabBox_sec">
                <div className="container-fluid">
                    <div className="row">
                        <div class="dash_bannerSec">
                            <div class="dash_ban_bg dark_bg_changed">

                                <div style={{ visibility: "hidden", height: "1px" }}>
                                    <img src={Group133} />
                                </div>
                                <div class="dash_sign_left_head w-100">

                                    <div class="dashAgn_innerSign">
                                        <h4>Search Clients Around the world and Deploy your Resources</h4>
                                        <p>Work compliantly from 150 countries, automate your invoicing, and get paid in any currency, including Crypto.</p>
                                    </div>

                                </div>

                                <div class="agn_signImg_box">
                                    <img src={image6} />
                                </div>
                            </div>

                        </div>
                        <div className="signup_agreement_frmBox organised_signUp_frm_outter">
                        {/* onSubmit={hooks.formik.handleSubmit} */}
                            <form className="sign_middleWare signFrm_wid" onSubmit={hooks.formik.handleSubmit}>

                                <div className="frm_welcome_head orgFrm_head">

                                    <div className="back_btnHead" onClick={(e) => navigateToBack(e)}>
                                        <span><i className="fa-solid fa-arrow-left-long"></i></span><span> Back</span>
                                    </div>
                                    <h4>Register As Agency</h4>
                                </div>

                                <div className="sign_optionalBtns">
                                    <button className="btn optBtn via_google">Signup Via Google</button>
                                    <button className="btn optBtn via_linkedin">Signup Via Linkedin</button>
                                </div>

                                <div className="row fill_up_taskBox" >
                                    <div className="or_divider">
                                        <p>Or</p>
                                    </div>
                                </div>

                                <div className="row fill_up_taskBox" >


                                    <div className="col-md-6 org_sign_paddInc_right ">
                                        <div className="frm_grp">
                                            
                                        {/* <TextField
                                                id="outlined-basi"
                                                label="First Name"
                                                name="first_name"
                                                variant="outlined"
                                                value={formobj.first_name}
                                                onChange={handleInputChange} /> */}

                                            <TextField
                                                fullWidth
                                                id="firstName"
                                                name="firstName"
                                                label="First Name"
                                                value={hooks.formik.values.firstName}
                                                onChange={hooks.formik.handleChange}
                                                error={hooks.formik.touched.firstName && Boolean(hooks.formik.errors.firstName)}
                                                helperText={hooks.formik.touched.firstName && hooks.formik.errors.firstName}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6 org_sign_paddInc_left ">
                                        <div className="frm_grp">
                                        {/* <TextField
                                                id="outlined-basic"
                                                label="Last Name"
                                                name="last_name"
                                                variant="outlined"
                                                value={formobj.last_name}
                                                onChange={handleInputChange} /> */}
                                            <TextField
                                                fullWidth
                                                id="lastName"
                                                name="lastName"
                                                label="Lastst Name"
                                                value={hooks.formik.values.lastName}
                                                onChange={hooks.formik.handleChange}
                                                error={hooks.formik.touched.lastName && Boolean(hooks.formik.errors.lastName)}
                                                helperText={hooks.formik.touched.lastName && hooks.formik.errors.lastName}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-12 ">
                                        <div className="frm_grp">
                                        {/* <TextField
                                                fullWidth
                                                label="Work Email ID"
                                                name="email"
                                                id="fullWidth"
                                                value={formobj.email} onChange={handleInputChange} /> */}
                                            <TextField
                                                fullWidth
                                                id="email"
                                                name="email"
                                                label="Work Email ID"
                                                value={hooks.formik.values.email}
                                                onChange={hooks.formik.handleChange}
                                                error={hooks.formik.touched.email && Boolean(hooks.formik.errors.email)}
                                                helperText={hooks.formik.touched.email && hooks.formik.errors.email}
                                            />

                                        </div>
                                    </div>

                                    <div className="col-md-12 ">
                                        <div className="frm_grp">
                                        {/* <TextField
                                                fullWidth
                                                id="outlined-password-input"
                                                label="Password"
                                                name="password1"
                                                type="password"
                                                autoComplete="current-password"
                                                value={formobj.password1}
                                                onChange={handleInputChange}
                                            /> */}
                                        <TextField
                                                fullWidth
                                                id="password1"
                                                name="password1"
                                                label="Password"
                                                type="password"
                                                value={hooks.formik.values.password1}
                                                onChange={hooks.formik.handleChange}
                                                error={hooks.formik.touched.password1 && Boolean(hooks.formik.errors.password1)}
                                                helperText={hooks.formik.touched.password1 && hooks.formik.errors.password1}
                                            />

                                        </div>
                                    </div>
                                    <div className="col-md-12 ">
                                        <div className="frm_grp">
                                        {/* <TextField
                                                fullWidth
                                                id="outlined-password-input"
                                                label="Confirm Password"
                                                name="password2"
                                                type="password"
                                                autoComplete="current-password"
                                                value={formobj.password2}
                                                onChange={handleInputChange}
                                            /> */}
                                           <TextField
                                                fullWidth
                                                id="password2"
                                                name="password2"
                                                label="Confirm Password"
                                                type="password"
                                                value={hooks.formik.values.password2}
                                                onChange={hooks.formik.handleChange}
                                                error={hooks.formik.touched.password2 && Boolean(hooks.formik.errors.password2)}
                                                helperText={hooks.formik.touched.password2 && hooks.formik.errors.password2}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <button type="submit"  className="org_signUp_submitBtn">Register as Agency</button>
                                </div>

                                <div className="already_logedBox _onMob">
                                    <p>Already have an account? <a href="sign_in.html">Log in</a></p>
                                </div>

                            </form>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default RegisterAsAnAgncyPage;
// disabled={!formobj.first_name || !formobj.last_name || !formobj.email || !formobj.password1 || !formobj.password2}