import React, {useState, useEffect} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import SearchAgencyModal from '../../component/Modal/SearchAgencyModal';
import SearchResourcesModal from '../../component/Modal/SearchResourcesModal';
import "../../css/modal.css";
import { useGlobalState,setGlobalState } from '../../globalState';
import { MSATypeList } from "../../services/apiUrl";
import { useAddMasterAgreementHooks } from "../hooks/useCreateHooks";
import TextField from '@mui/material/TextField';
import TextareaAutosize from '@mui/material/TextareaAutosize';


const AddMasterAgreementModalPage=(props)=>{
    const hooks = useAddMasterAgreementHooks();
    const [isActive,setIsActive] = useState(false);   
    const [showAgency] = useGlobalState("showAgency");   
    const [showResources] = useGlobalState("showResources");
    const [showAddNewAgree] = useGlobalState("showAddNewAgree"); 
    const [isResourcesUser] = useGlobalState("isResourcesUser"); 
    const [isAgencyUser] = useGlobalState("isAgencyUser"); 
    const [showAgencyLocal, setShowAgencyLocal]= useState(false);
    const [showResourcesLocal, setShowResourcesLocal]= useState(false);
    const [opacity] = useGlobalState("opacity"); 
    const [agencyUserDetails] = useGlobalState("agencyUserDetails");
    const [msaTypeList,setMsaTypeList] = useState([]);  
    const [msaType, setMsaType] = useState(""); 
    const [resourcesUserDetails] = useGlobalState("resourcesUserDetails");

    const getAfter30days=()=>{
        let d = new Date();
        d.setDate(d.getDate() + 30);
        return d;
    }

            const [msaNumber, setMsaNumber] = useState("");
            const [name, setName] = useState("");
            const [unpseCode, setUnpseCode] = useState("");
            const [businessUnit, setBusinessUnit] = useState("");
            const [glAccount, setGlAccount] = useState("");
            const [budget, setBudget] = useState("");
            const [supplier, setSupplier] = useState([]);    
            const [startDate, setStartDate ] = useState(new Date());
            const [endDate, setEndDate] = useState(getAfter30days()); 
            const [deliver, setDeliver] = useState("");
            const [msaResources, setMsaResources] = useState("");
            const [msaAgency, setMsaAgency] = useState("");
            const [taxService, setTaxService] = useState("");
            const [taxGrp, setTaxGrp] = useState("");
            const [savingPercentage, setSavingPercentage] = useState("");
            const [comments, setComments] = useState("");  
                                          
    
    /*const [formObj,setFormObj] = useState({      
        msaType:"",  
        msaNumber:"",
        name:"",
        unpseCode:"",
        businessUnit:"",
        glAccount:"",
        budget:"",
        supplier:agencyUserDetails.user,
        startDate:new Date(),
        endDate: getAfter30days(),
        deliver:"",
        msaResources:"",
        msaAgency: "",
        taxService: "",
        taxGrp:"",
        savingPercentage: "",
        comments:""        
    });*/
    

    useEffect(()=>{       
        console.log("showAddNewAgree "+showAddNewAgree);
        const resultMSA = MSATypeList();  
        //console.log("resultMSA1"+resultMSA);
        if(isResourcesUser)
          setSupplier(resourcesUserDetails.user);
        else if(isAgencyUser)
          setSupplier(agencyUserDetails.user);
        else
          setSupplier([]); 
        
        resultMSA.then(value => {         
            setMsaTypeList(value);  
            
             console.log("resultMSA"+value);             
          }).catch(err => {
            console.log(err);
          });   
          console.log("msaTypeList"+msaTypeList);                                            
    },[]);
    

    const handleChange = (e) => {       
        let name = e.target.name;             
        let value = e.target.value;        
        if(value === 'msaAgency'){              
           setGlobalState("showAgency",true); 
           setShowAgencyLocal(true);       
        } else if(value === 'msaResources'){
           setGlobalState("showResources",true);
           setShowResourcesLocal(true);                                                           
        }

       /* if(name == "startDate"){
            setFormObj((prevState) => {
                return {
                ...prevState,
                [name]: new Date(),
                };
            });  
        } else {
            setFormObj((prevState) => {
                return {
                ...prevState,
                [name]: value,
                };
            });
        }*/  
                                                              
        switch (name) {
            case 'msaType':
              {
                hooks.formik.setFieldValue("msaType", value);
                setMsaType(e.target.value);                                
              }
              break;
            case 'msaNumber':
              {                
                hooks.formik.setFieldValue("msaNumber", value);
                setMsaNumber(e.target.value); 
              }
              break;
            case "unpseCode": {              
              hooks.formik.setFieldValue("unpseCode", value);
              setUnpseCode(e.target.value); 
            }
              break;
            case "businessUnit": {      
              hooks.formik.setFieldValue("businessUnit", value);
              setBusinessUnit(e.target.value); 
            } 
            break;
            case "glAccount": {      
              hooks.formik.setFieldValue("glAccount", value);
              setGlAccount(e.target.value); 
            }
            break;
            case "budget": {      
              hooks.formik.setFieldValue("budget", value);
              setBudget(e.target.value); 
            }
            break;
            case "supplier": {      
              hooks.formik.setFieldValue("supplier", value);
              setSupplier(e.target.value); 
            }
            break;
            case "startDate": {      
              hooks.formik.setFieldValue("startDate", value)
              setStartDate(e.target.value); 
            }
            break;
            case "endDate": {      
              hooks.formik.setFieldValue("endDate", value);
              setEndDate(e.target.value); 
            }
            break;
            case "deliver": {      
              hooks.formik.setFieldValue("deliver", value);
              setDeliver(e.target.value); 
            }
            break;            
            case "taxService": {      
              hooks.formik.setFieldValue("taxService", value);
              setTaxService(e.target.value); 
            }
            break;
            case "taxGrp": {      
              hooks.formik.setFieldValue("taxGrp", value);
              setTaxGrp(e.target.value); 
            }
            break;
            case "savingPercentage": {      
              hooks.formik.setFieldValue("savingPercentage", value);
              setSavingPercentage(e.target.value); 
            }
            break;
            case "comments": {      
              hooks.formik.setFieldValue("comments", value);
              setComments(e.target.value); 
            }
          }
    }

    // const submitform =()=>{
        
    // }

    const toggleClass=(e)=>{
        setIsActive(true);
    }

    //Bootstrap Modal start
        const handleClose = () => {            
            setGlobalState("showAddNewAgree",false);                              
        }    
        
        const modalStyle = {           
           float:"right"
        }
    //Bootstrap Modal stop
    
    
    return (
          <React.Fragment>  

               <Modal show={showAddNewAgree} onHide={handleClose} dialogClassName="my-modal">
                  <form  onSubmit={hooks.formik.handleSubmit}> 
                    <Modal.Header closeButton>
                        <Modal.Title>Add Master Agreements</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                         <div className="row">
                                                <div className="col-12"></div>

                                                <div className="col-md-6 col_marg_bt">
                                                    <div className={`frm_grp ${isActive ? "focused" : null}`} onClick={toggleClass}>
                                                    <label className="form-label" htmlFor='msa_type'>MSA Type</label>
                                                    
                                                       <select id="msa_type" name="msaType"  className="msa_input_area" value={hooks.formik.values.msaType}
                              onChange={hooks.formik.handleChange}>
                                                           <option value="">Select MSA Type</option>
                                                        {msaTypeList.payload && msaTypeList.payload.msa_type.length ? (
                                                              msaTypeList.payload.msa_type.map((user, index) => (   
                                                                
                                                                 <option value={user.id} key={index}>{user.name}</option>
                                                        ))
                                                        ) : (                           
                                                        <option value="null">No data found</option>                                      
                                                        )}     
                                                       </select>
                                                    </div>
                                                </div>

                                                <div className="col-md-6 col_marg_bt">
                               
                                                    <div className={`frm_grp ${isActive ? "focused" : null}`} onClick={toggleClass}>
                                                    <label className="form-label" htmlFor="tax_number">MSA Number</label>
                                                    <input type="text"  id="tax_number" name="msaNumber" className="msa_input_area" value={hooks.formik.values.msaNumber}
                              onChange={hooks.formik.handleChange}/>
                                                    </div>
                                                </div>

                                                <div className="col-md-6 col_marg_bt">
                                                    <div className={`frm_grp ${isActive ? "focused" : null}`} onClick={toggleClass}>
                                                    <label className="form-label" htmlFor="name">Name</label>
                                                    {/*<input type="text"  id="name"  name="name"   className="msa_input_area" value={hooks.formik.values.name}
                              onChange={hooks.formik.handleChange}/>*/}
                                                       <TextField
                                                        fullWidth
                                                        id="name"
                                                        name="name"
                                                        label="Name"
                                                        className="msa_input_area"
                                                        value={hooks.formik.values.name}
                                                        onChange={hooks.formik.handleChange}
                                                        error={hooks.formik.touched.name && Boolean(hooks.formik.errors.name)}
                                                        helperText={hooks.formik.touched.name && hooks.formik.errors.name}
                                                      />
                                                    </div>
                                                </div>

                                                <div className="col-md-6 col_marg_bt">
                                                    <div className={`frm_grp ${isActive ? "focused" : null}`} onClick={toggleClass}>
                                                    <label className="form-label" htmlFor="unpscCode">UNPSC Code</label>
                                                    {/*<input type="number"  id="unpscCode" name="unpscCode" className="msa_input_area" maxLength="6" value={hooks.formik.values.unpscCode}
                              onChange={hooks.formik.handleChange}/>*/}
                                                     <TextField
                                                        fullWidth
                                                        id="unpscCode"
                                                        name="unpscCode"
                                                        label="UNPSC Code"
                                                        className="msa_input_area"
                                                        value={hooks.formik.values.unpscCode}
                                                        onChange={hooks.formik.handleChange}
                                                        error={hooks.formik.touched.unpscCode && Boolean(hooks.formik.errors.unpscCode)}
                                                        helperText={hooks.formik.touched.unpscCode && hooks.formik.errors.unpscCode}
                                                      />
                                                    </div>
                                                </div>

                                                <div className="col-md-6 col_marg_bt">
                                                    <div className={`frm_grp ${isActive ? "focused" : null}`} onClick={toggleClass}>
                                                    <label className="form-label" htmlFor="businessUnit">Business Unit</label>
                                                    {/*<input type="text"  id="businessUnit" name="businessUnit" className="msa_input_area" value={hooks.formik.values.businessUnit}
                              onChange={hooks.formik.handleChange}/>*/}
                                                       <TextField
                                                        fullWidth
                                                        id="businessUnit"
                                                        name="businessUnit"
                                                        label="Business Unit"
                                                        className="msa_input_area"
                                                        value={hooks.formik.values.businessUnit}
                                                        onChange={hooks.formik.handleChange}
                                                        error={hooks.formik.touched.businessUnit && Boolean(hooks.formik.errors.businessUnit)}
                                                        helperText={hooks.formik.touched.businessUnit && hooks.formik.errors.businessUnit}
                                                      />
                                                    </div>
                                                </div>

                                                <div className="col-md-6 col_marg_bt">
                                                    <div className={`frm_grp ${isActive ? "focused" : null}`} onClick={toggleClass}>
                                                    <label className="form-label" htmlFor="glAccount">GL Account</label>
                                                    {/*<input type="text"  id="glAccount" name="glAccount" className="msa_input_area" value={hooks.formik.values.glAccount}
                              onChange={hooks.formik.handleChange}/>*/}
                                                     <TextField
                                                        fullWidth
                                                        id="glAccount"
                                                        name="glAccount"
                                                        label="GL Account"
                                                        className="msa_input_area"
                                                        value={hooks.formik.values.glAccount}
                                                        onChange={hooks.formik.handleChange}
                                                        error={hooks.formik.touched.glAccount && Boolean(hooks.formik.errors.glAccount)}
                                                        helperText={hooks.formik.touched.glAccount && hooks.formik.errors.glAccount}
                                                      />
                                                    </div>
                                                </div>

                                                <div className="col-md-12">
                                                    <div className="agrr_detal">
                                                    <h6>Agreement details</h6>

                                                    <div className="radio_chekBox">

                                                        <input type="radio" id="radio1"  name="msaAgencyResources" value="msaAgency"
                              onChange={handleChange}/>
                                                        <label htmlFor="radio1">MSA for Agency</label>
                                                        <input type="radio" id="radio2"  name="msaAgencyResources" value="msaResources" onChange={handleChange}/>
                                                        <label htmlFor="radio2">MSA for Resources</label>

                                                    </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-6 col_marg_bt">
                                                    <div className={`frm_grp ${isActive ? "focused" : null}`} onClick={toggleClass}>
                                                    <label className="form-label" htmlFor="budget">Budget<span>*</span></label>
                                                    {/*<input type="number"  id="budget"  name="budget" className="msa_input_area" maxLength="6" value={hooks.formik.values.budget}
                              onChange={hooks.formik.handleChange}/>*/}
                                                      <TextField
                                                        fullWidth
                                                        id="budget"
                                                        name="budget"
                                                        label="Budget"
                                                        className="msa_input_area"
                                                        value={hooks.formik.values.budget}
                                                        onChange={hooks.formik.handleChange}
                                                        error={hooks.formik.touched.budget && Boolean(hooks.formik.errors.budget)}
                                                        helperText={hooks.formik.touched.budget && hooks.formik.errors.budget}
                                                      />
                                                    </div>
                                                </div>

                                                <div className="col-md-6 col_marg_bt">
                                                    <div className={`frm_grp ${isActive || supplier ? "focused" : null}`} onClick={toggleClass}> 
                                                    <label className="form-label" htmlFor="supplier">Supplier/ Agency<span>*</span></label>
                                                    {/*<input type="text"  id="supplier" value={agencyUserDetails.first_name != undefined ? `${agencyUserDetails.first_name} ${agencyUserDetails.last_name}`: ""}  name="supplier" className="msa_input_area" value={hooks.formik.values.supplier}
                              onChange={hooks.formik.handleChange}/>*/}
                                                       <TextField
                                                        fullWidth
                                                        id="supplier"
                                                        name="supplier"
                                                        label="Supplier/ Agency"
                                                        className="msa_input_area"
                                                        value={agencyUserDetails.first_name != undefined ? `${agencyUserDetails.first_name} ${agencyUserDetails.last_name}`: ""}
                                                        onChange={hooks.formik.handleChange}
                                                        error={hooks.formik.touched.supplier && Boolean(hooks.formik.errors.supplier)}
                                                        helperText={hooks.formik.touched.supplier && hooks.formik.errors.supplier}
                                                      />
                                                    </div>
                                                </div>

                                                <div className="col-md-6 col_marg_bt">
                                                    <div className="date_selectorBox">
                                                    <div className="row">
                                                        <div className="col-6">
                                                        <div className={`frm_grp ${isActive ? "focused" : null}`} onClick={toggleClass}>
                                                            <label className="form-label" htmlFor="startDate">Start Date<span>*</span></label>
                                                            
                                                            <DatePicker selected={startDate} id="startDate" className="form-control msa_input_area" name="startDate"  
                              onChange={hooks.formik.handleChange}/>
                                                        </div>
                                                        </div>
                                                        <div className="col-6">
                                                        <div className={`frm_grp ${isActive ? "focused" : null}`} onClick={toggleClass}>
                                                            <label className="form-label" htmlFor="endDate">End Date<span>*</span></label>                                                        
                                                            <DatePicker selected={endDate} id="endDate" className="form-control msa_input_area" name="endDate"
                              onChange={hooks.formik.handleChange}/>
                                                        </div>
                                                        </div>
                                                    </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-12">
                                                    <div className="agrr_detal">
                                                    <h6>Agreement details</h6>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 col_marg_bt">
                                                    <div className={`frm_grp ${isActive ? "focused" : null}`} onClick={toggleClass}>
                                                    <label className="form-label" htmlFor="deliver">Deliverable</label>
                                                    {/*<textarea  type="text" id="deliver" name="deliver" className="msa_input_area" value={hooks.formik.values.deliver}
                              onChange={hooks.formik.handleChange}></textarea>*/}
                                                       <TextareaAutosize
                                                          fullWidth
                                                          id="deliver"
                                                          placeholder="Deliver"
                                                          name="deliver"
                                                          label="Deliver"
                                                          style={{ width: 788, padding: 6 }}
                                                          value={hooks.formik.values.deliver}
                                                          onChange={hooks.formik.handleChange}
                                                          error={hooks.formik.touched.deliver && Boolean(hooks.formik.errors.deliver)}
                                                          helperText={hooks.formik.touched.deliver && hooks.formik.errors.deliver}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-6 col_marg_bt">
                                                    <div className="row">

                                                    <div className="col-6">
                                                        <div className={`frm_grp ${isActive ? "focused" : null}`} onClick={toggleClass}>
                                                        <label className="form-label" htmlFor="taxService" style={{width:"52%"}}>Tax service type</label>
                                                        <select type="text"  id="taxService" name="taxService" className=" msa_input_area" value={hooks.formik.values.taxService}
                              onChange={hooks.formik.handleChange}>

                                                            <option  value="">Tax service</option>
                                                            <option value="1">Tax service1</option>
                                                            <option value="2">Tax service2</option>
                                                            <option value="3">Tax service3</option>
                                                            <option value="4">Tax service4</option>

                                                        </select>
                                                        </div>
                                                    </div>

                                                    <div className="col-6">

                                                        <div className={`frm_grp ${isActive ? "focused" : null}`} onClick={toggleClass}>
                                                        <label className="form-label" htmlFor="tax_grp" style={{width:"40%"}}>Tax Group</label>
                                                        <select type="text"  id="tax_grp" name="taxGrp"  className=" msa_input_area" value={hooks.formik.values.taxGrp}
                              onChange={hooks.formik.handleChange}>

                                                            <option value="">Tax Group</option>
                                                            <option value="1">Tax Group1</option>
                                                            <option value="2">Tax Group2</option>
                                                            <option value="3">Tax Group3</option>
                                                            <option value="4">Tax Group4</option>

                                                        </select>
                                                        </div>

                                                    </div>
                                                    </div>
                                                </div>

                                                <div className="col-md-6 col_marg_bt">
                                                    <div className={`frm_grp ${isActive ? "focused" : null}`} onClick={toggleClass}>
                                                    <label className="form-label" htmlFor="saving">Savings Percentage</label>
                                                    {/*<input type="text"  id="saving" name="savingPercentage" className="msa_input_area" value={hooks.formik.values.savingPercentage}
                              onChange={hooks.formik.handleChange}/>*/}
                                                       <TextField
                                                        fullWidth
                                                        id="saving"
                                                        name="savingPercentage"
                                                        label="Savings Percentage"
                                                        className="msa_input_area"
                                                        value={hooks.formik.values.savingPercentage}
                                                        onChange={hooks.formik.handleChange}
                                                        error={hooks.formik.touched.savingPercentage && Boolean(hooks.formik.errors.savingPercentage)}
                                                        helperText={hooks.formik.touched.savingPercentage && hooks.formik.errors.savingPercentage}
                                                      />
                                                    </div>
                                                </div>

                                                <div className="col-md-12 col_marg_bt">
                                                    <div className={`frm_grp ${isActive ? "focused" : null}`} onClick={toggleClass}>
                                                    <label className="form-label" htmlFor="comments">Comments</label>
                                                    {/*<textarea type="text"  id="comments" name="comments" className="msa_input_area" value={hooks.formik.values.comments}
                              onChange={hooks.formik.handleChange}></textarea>*/}
                                                       <TextareaAutosize
                                                          fullWidth
                                                          id="comments"
                                                          placeholder="Comments"
                                                          name="comments"
                                                          label="Comments"
                                                          style={{ width: 788, padding: 6 }}
                                                          value={hooks.formik.values.comments}
                                                          onChange={hooks.formik.handleChange}
                                                          error={hooks.formik.touched.comments && Boolean(hooks.formik.errors.comments)}
                                                          helperText={hooks.formik.touched.comments && hooks.formik.errors.comments}
                                                        />
                                                    </div>
                                                </div>

                                                </div>
                                                                                                            
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" className="btn ad_contactBtn" type="submit">
                       Add Contract
                    </Button>&nbsp;&nbsp;                   
                    <Button className="btn ad_contactBtn btn btn-secondary" onClick={handleClose}>
                       Cancel
                    </Button>
                    </Modal.Footer>
                  </form>  
               </Modal>                                                                                       
                              {showAgencyLocal ? <SearchAgencyModal/> : null}
                              {showResourcesLocal ? <SearchResourcesModal/> : null}
        </React.Fragment>    
    );
}

export default AddMasterAgreementModalPage;