import React from "react";

const Sidebar =()=>{
   return (
    <aside className="inner_dash_aside" id="">
            <div className="side_innerNav">

                <div className="asideNav_logo">
                    <a href="inner_dashboard.html">
                        <img className="fullL_img" src={require('../../images/Group 164.png')}/> 
                        <img className="miniM_img" src={require('../../images/Group 164_mini.png')}/>
                    </a>
                </div>

                

                <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button className="nav-link active" id="dashboard-tab" data-bs-toggle="tab" data-bs-target="#dashboard" type="button" role="tab" aria-controls="dashboard" aria-selected="true"><span><img src={require('../../images/clarity_dashboard-line.png')}/></span> <span>Dashboard</span></button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link " id="master-tab" data-bs-toggle="tab" data-bs-target="#master" type="button" role="tab" aria-controls="master" aria-selected="false"><span><img src={require('../../images/nimbus_invoice.png')}/></span> <span>Master Agreements</span></button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="statement-tab" data-bs-toggle="tab" data-bs-target="#statement" type="button" role="tab" aria-controls="statement" aria-selected="false"><span><img src={require('../../images/uil_invoice.png')}/></span> <span>Statement of Works</span></button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="settings-tab" data-bs-toggle="tab" data-bs-target="#settings" type="button" role="tab" aria-controls="settings" aria-selected="false"><span><img src={require('../../images/ant-design_setting-outlined.png')}/></span> <span>Settings</span></button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="approval-tab" data-bs-toggle="tab" data-bs-target="#approval" type="button" role="tab" aria-controls="approval" aria-selected="false"><span><img src={require('../../images/bi_check-square.png')}/></span> <span>Approvals</span></button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="agencies-tab" data-bs-toggle="tab" data-bs-target="#agencies" type="button" role="tab" aria-controls="agencies" aria-selected="false"><span><img src={require('../../images/ep_office-building.png')}/></span> <span>Agencies</span></button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="resource-tab" data-bs-toggle="tab" data-bs-target="#resource" type="button" role="tab" aria-controls="resource" aria-selected="false"><span><img src={require('../../images/bx_user.png')}/></span> <span>Resource Profile</span></button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="timesheet-tab" data-bs-toggle="tab" data-bs-target="#timesheet" type="button" role="tab" aria-controls="timesheet" aria-selected="false"><span><img src={require('../../images/ant-design_field-time-outlined.png')}/></span> <span>Timesheet</span></button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="invoice_management-tab" data-bs-toggle="tab" data-bs-target="#invoice_management" type="button" role="tab" aria-controls="invoice_management" aria-selected="false"><span><img src={require('../../images/la_file-invoice-dollar.png')}/></span> <span>Invoice Management</span></button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="payment-tab" data-bs-toggle="tab" data-bs-target="#payment" type="button" role="tab" aria-controls="payment" aria-selected="false"><span><img src={require('../../images/fluent_money-hand-20-regular.png')}/></span> <span>Payment Management</span></button>
                    </li>
                </ul>

                
            </div>

            <div className="inner_userVector_imgBox">
                <img src={require('../../images/Box.png')}/>
            </div>
        </aside>
   );
}

export default Sidebar;