// import { configureStore,getDefaultMiddleware } from "@reduxjs/toolkit";
// import { userReducer, userVerifyReducer, } from "./Reducers/auth.UserReducer";
// import { CreateuserReducer } from "./Reducers/CreateUserReducer";
// import { CreateCompanyReducer } from "./Reducers/CreateCompanyReducer";
// const store = configureStore({
//   reducer: {
//     user: userReducer,
//     userVerify: userVerifyReducer,
//     CreateUser: CreateuserReducer,
//     CreateCompany: CreateCompanyReducer,
//   },
//   middleware: getDefaultMiddleware => getDefaultMiddleware({
//     serializableCheck: {
//         ignoredActions: ['TYPE'],
//         ignoredActionPaths: ['property'],
//         ignoredPaths: ['reducer.property']
//     }
// })
// });

// export default store;
import { createStore, applyMiddleware, compose } from "redux"
import thunk from "redux-thunk"
import { persistStore, persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"

import rootReducer from './reducer';
import { TypedUseSelectorHook, useSelector, useDispatch } from "react-redux"


const composeEnhancers = (window).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistConfig = {
  key: "root",
  storage,
  // whitelist: whitelist,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(thunk))
)


export const persistor = persistStore(store)

// Infer the `RootState` and `AppDispatch` types from the store itself
// export const RootState = typeof store.getState
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
// export const AppDispatch = typeof store.dispatch

// export const useAppSelector: TypedUseSelectorHook(RootState) = useSelector;
// export const useAppDispatch = () => useDispatch < AppDispatch > ();
export const useAppSelector = useSelector;
export const  useAppDispatch = useDispatch;



export default store

