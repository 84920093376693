import React from 'react'
import RegisterAsAnAgncyPage from '../../pages/LoginPages/registerAsAnAgnPage'

function RegisterAsAnAgency() {
  return (
    <>
    <RegisterAsAnAgncyPage />
    </>
  )
}

export default RegisterAsAnAgency