import React, { useEffect, useState } from 'react';
import './login.css';

import Group133 from "../../images/Group 133.png";
import verify_vector from "../../images/verify_vector.png";
import image6 from "../../images/image 6.png";
import OtpInput from "react-otp-input";
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import {useAppSelector} from '../../Redux/store';

import {  useSelector } from "react-redux";

function VerifyAgnPage() {
  const storeData = useAppSelector(state => state.detailSlice.createFormData)

  const [otp, setOtp] = useState([]);
  const [login, setLogin] = useState(false);
  const handleChangeOtpField = (otp) => {
    setOtp(otp);
   
  };
  useEffect(()=>{
    if(otp){
      setLogin(true)
    }
    

  },[])
  return (
    <>
      <header class="floating_header">

        <nav class="navbar navbar-expand-lg navbar-light ">
          <div class="container-fluid">

            <a class="navbar-brand orgSign_dash_logo" href="index.html">
              <img src={Group133} />
            </a>
          </div>
        </nav>

      </header>

      <section class="signup_tabBox_sec">
        <div class="container-fluid">
          <div class="row">
            <div class="dash_bannerSec">
              <div class="dash_ban_bg dark_bg_changed">

                <div style={{ visibility: 'hidden', height: '1px' }}>
                  <img src={Group133} />
                </div>
                <div class="dash_sign_left_head w-100">

                  <div class="dashAgn_innerSign">
                    <h4>Search Clients Around the world and Deploy your Resources</h4>
                    <p>Work compliantly from 150 countries, automate your invoicing, and get paid in any currency, including Crypto.</p>
                  </div>

                </div>

                <div class="agn_signImg_box">
                  <img src={image6} />
                </div>
              </div>

            </div>
            <div class="signup_agreement_frmBox ver_mail_outer">

              <div class="sign_middleWare signFrm_wid text-center">

                <div class="ver_head_container">
                  <img src={verify_vector} class="ver_vector" />
                  <h1>Verify your email</h1>
                </div>

                <div class="ver_mail_idBox">
                  <p>We sent an email to:</p>
                  <h4>{storeData.email}</h4>
                </div>
                <div class="ver_mail_idBox">
                  <label className="lable">Enter Your 5 Digits OTP</label>
                  <OtpInput
                    className="otpContainer"
                    value={otp}
                    onChange={handleChangeOtpField}
                    numInputs={5}
                    separator={<span className="mr-3"></span>}
                  />
                  {/* {errorFieldOtp && (
                  <span className="invalid" style={style}>
                    This is required
                  </span>
                )} */}
                </div>

                <div class="ver_mailFooter">
                  <p>You will have to verify your email to complete the registration.</p>
                  <a href="">Resend Email</a>
                </div>
                <div>
                
                    <Button disabled={!login} variant="outlined"><Link to={`/innerdashboard`}>
                      Log in
                    </Link></Button>
                
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default VerifyAgnPage