import axios from "axios";
import { getToken, setToken } from './service';
const baseUrl = "http://165.22.218.132:8000/";


/**************************************************************************************************
 *                                   JWT TOKEN
*************************************************************************************************/
const jwtToken = getToken()

/**************************************************************************************************
 *                                   LOGIN /dj-rest-auth/logout/
*************************************************************************************************/

export const LoginUser = async (obj) => {

  const body = obj
  try {
    return await axios.post(
      baseUrl + `dj-rest-auth/login/`, body
    )
      .then(res => {
        if (res && res.data) {
          setToken(res.data.key)
          return res.data

        }
      }).catch(error => {
        console.log('error.response', error.response)
      });
  }
  catch (e) {
    console.log('err api', e);
  }
}
/**************************************************************************************************
 *                                   LOGOUT
*************************************************************************************************/
export const LogOutUser = async () => {
 
  try {
    return await axios.post(
      baseUrl + `dj-rest-auth/logout/`
    )
      .then(res => {
        if (res && res.data) {
          setToken("")
          return res.data
        }
      }).catch(error => {
        console.log('error.response', error.response)
      });
  }
  catch (e) {
    console.log('err api', e);
  }
}
/**************************************************************************************************
 *                                  RegisterUser
*************************************************************************************************/

export const registerUser = async (values) => {

  try {
    const body = values
    return await axios.post(
      baseUrl + "dj-rest-auth/registration/", body
    )
      .then(res => {
        if (res && res.data) {
          setToken(res.data.key)
          return res.data
        }
      }).catch(error => {
        console.log('error.catch', error.response)
      });
  }
  catch (e) {
    console.log('err', e);
  }
};

/**************************************************************************************************
 *                                  creat profile ORGANISATION 
*************************************************************************************************/

export const creatOrgProfile = async (values) => {



  try {
    const body = values
    return await axios.post(
      baseUrl + "organisation/profile/", body,
      {
        headers: { "Authorization": `Token ${jwtToken}`, "Content-Type": "multipart/form-data" },
      }
    )
      .then(res => {
        if (res && res.data) {
          return res.data

        }
      }).catch(error => {
        console.log('error.catch', error.response)
      });
  }
  catch (e) {
    console.log('err', e);
  }
};
/**************************************************************************** **********************
 *                                  creat profile AGENCY   resources/resources-profile/
*************************************************************************************************/

export const creatAgencyProfile = async (values) => {
  
  try {
    const body = values
    return await axios.post(
      baseUrl + "agency/agency-profile/", body, {
      headers: { "Authorization": `Token ${jwtToken}`, "Content-Type": "multipart/form-data" },
    }
    )

    .then(res => {
      if (res && res.data) {
        return res.data
      }
    }).catch(error => {
      console.log('error.catch', error.response)
    });

  }
  catch (e) {
    console.log('err', e);
  }
};

/**************************************************************************** **********************
 *                                  creat profile Resources   
*************************************************************************************************/

export const creatResourcesProfile = async (values) => {
  ;;
  try {
    const body = values
    return await axios.post(
      baseUrl + "resources/resources-profile/", body, {
      headers: { "Authorization": `Token ${jwtToken}`, "Content-Type": "multipart/form-data" },
    }
    )
    .then(res => {
      if (res && res.data) {
        return res.data
      }
    }).catch(error => {
      console.log('error.catch', error.response)
    });

  }
  catch (e) {
    console.log('err', e);
  }
};

/**************************************************************************************************
 *                                  Search Agency
*************************************************************************************************/
export const SearchAgency = async (obj) => {
  const body = obj
  try {
      return await axios.get(
          baseUrl + `agency/agency-profile/?search=`+obj,
           {
             headers: { "Authorization": `Token ${jwtToken}`, "Content-Type": "application/json", Accept: "application/json" },
             //headers: {"Access-Control-Allow-Origin": "*"}
           }

      )     
      .then(res => {       
          if (res && res.data ) {
            //localStorage.setItem('jwtToken', res.data.data.key)
             return res.data 
          }
          }) .catch(error => {
              console.log('error.response',error.response)
          });

  }
  catch (e) {
    console.log('err api', e);
  }
}


/**************************************************************************************************
 *                                  Agency List
*************************************************************************************************/
export const AgencyList = async () => {
  try {
      return await axios.get(
          baseUrl + `agency/agency-profile/`,
           {
             headers: { "Authorization": `Token ${jwtToken}`, "Content-Type": "application/json", Accept: "application/json" },
             //headers: {"Access-Control-Allow-Origin": "*"}
           }

      )     
      .then(res => {       
          if (res && res.data ) {
            //setToken(res.data.data.key)
             return res.data 
          }
          }) .catch(error => {
              console.log('error.response',error.response)
          });
  }
  catch (e) {
      console.log('err api', e);
  }
}


/**************************************************************************************************
 *                                  MSA Type List
*************************************************************************************************/
export const MSATypeList = async () => {  
  try {
      return await axios.get(
          baseUrl + `organisation/msa-fields/`,
           {
             headers: { "Authorization": `Token ${jwtToken}`, "Content-Type": "application/json", Accept: "application/json" },
             //headers: {"Access-Control-Allow-Origin": "*"}
           }

      )     
      .then(res => {       
          if (res && res.data ) {
            //localStorage.setItem('jwtToken', res.data.data.key)
             return res.data 
          }
          }) .catch(error => {
              console.log('error.response',error.response)
          });
  }
  catch (e) {
      console.log('err api', e);
  }
}



/**************************************************************************** **********************
 *                                 Add Master Agreement  
*************************************************************************************************/

export const addMasterAgreement = async (values) => {
  const Token  =  localStorage.getItem('jwtToken');;
  try {
    const body = values 
      return await axios.post(
        baseUrl + "organisation/master-agreement/" , body,{
          headers: { "Authorization": `Token ${jwtToken}`, "Content-Type": "multipart/form-data" },
        }
    )
    .then(res => {       
        if (res && res.data ) {
          //setToken(res.data.data.key)
           return res.data
        }
        }) .catch(error => {
            console.log('error.catch',error.response)
        });
}
  catch (e) {
    console.log('err',e);
  }
};


/**************************************************************************************************
 *                                  Resources List
*************************************************************************************************/
export const ResourcesList = async () => {  
  try {
      return await axios.get(
          baseUrl + `resources/resources-profile/`,
           {
             headers: { "Authorization": `Token ${jwtToken}`, "Content-Type": "application/json", Accept: "application/json" },
             //headers: {"Access-Control-Allow-Origin": "*"}
           }

      )     
      .then(res => {       
          if (res && res.data ) {
            //setToken(res.data.data.key)
             return res.data 
          }
          }) .catch(error => {
              console.log('error.response',error.response)
          });
  }
  catch (e) {
    console.log('err api', e);
  }
}


/**************************************************************************************************
 *                                  Search Resources
*************************************************************************************************/
export const SearchResources = async (obj) => {
  const body = obj
  try {
      return await axios.get(
          baseUrl + `resources/resources-profile/?search=`+obj,
           {
             headers: { "Authorization": `Token ${jwtToken}`, "Content-Type": "application/json", Accept: "application/json" },
             //headers: {"Access-Control-Allow-Origin": "*"}
           }

      )     
      .then(res => {       
          if (res && res.data ) {
            //localStorage.setItem('jwtToken', res.data.data.key)
             return res.data
          }
          }) .catch(error => {
              console.log('error.response',error.response)
          });
  }
  catch (e) {
      console.log('err api', e);
  }
}


/**************************************************************************************************
 *                                  Agency List By Filter
*************************************************************************************************/
export const AgencyListByFilter = async (obj) => {  
  try {
      return await axios.get(
          baseUrl + `agency/agency-profile/?country=${obj.country}&state=${obj.state}`,
           {
             headers: { "Authorization": `Token ${jwtToken}`, "Content-Type": "application/json", Accept: "application/json" },
             //headers: {"Access-Control-Allow-Origin": "*"}
           }

      )     
      .then(res => {       
          if (res && res.data) {
             return res.data
          }
          }) .catch(error => {
              console.log('error.response',error.response)
          });
  }
  catch (e) {
      console.log('err api', e);
  }
}

/**************************************************************************************************
 *                                 accounts/country/
*************************************************************************************************/

export const countryStateCity = async (obj) => {

  const body = obj
  try {
    return await axios.get(
      baseUrl + `accounts/country/`, 
    )
      .then(res => {
        if (res && res.data) {
          return res.data.results 
        }
      }).catch(error => {
        console.log('error.response', error.response)
      });
  }
  catch (e) {
    console.log('err api', e);
  }
}
