import React from 'react'
import SearchAgencyModalPage from '../../pages/ModalPages/SearchAgencyModalPage'

function SearchAgencyModal(props) {
  return (
    <div>
        <SearchAgencyModalPage show={props.show}/>
    </div>
  )
}

export default SearchAgencyModal